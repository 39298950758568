import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Row,
    Table
} from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';

import './style.scss';
import WrapperPage from '../../containers/WrapperPage';
import { AppState } from '../../store';
import Preloader from '../../components/Preloader';
import { UsersItem } from '../../store/types/users';
import AddUser from './Add';
import ViewUser from './View';
import EditUser from './Edit';
import {
    creatingUsersAction,
    viewingUsersAction,
    editingUsersAction,
    requestGetUsersAction,
    requestDeleteUsersAction
} from '../../store/actions/users';

const { Column } = Table;

const UsersPage:React.FC = () => {
    const dispatch = useDispatch();
    const users = useSelector((state: AppState) => state.users);
    const auth = useSelector((state: AppState) => state.auth);
    const { data, loading, companies } = users;
    const { account } = auth;

    useEffect(() => {
        dispatch(requestGetUsersAction());
    }, []);

    const onDelete = (id: string) => {
        dispatch(requestDeleteUsersAction(id));
    };

    if (data === null) {
        return <Preloader />;
    }

    let filteredData = [...data];

    if (account === 'manager') {
        filteredData = filteredData.filter((item) => item.type === 'client');
    }

    return (
        <div className="users page">
            <div className="users__actions">
                <Row justify="end">
                    <Button
                        type="primary"
                        size="large"
                        icon={<PlusCircleOutlined />}
                        onClick={() => dispatch(creatingUsersAction())}
                    >
                        Добавить пользователя
                    </Button>
                </Row>
            </div>
            <Table
                dataSource={filteredData}
                pagination={false}
                rowKey={((record) => `users_${record.id}`)}
            >
                <Column
                    title="Компания"
                    dataIndex="company"
                    key="company"
                    width="25%"
                    render={(value, record: UsersItem) => {
                        if (record.company) {
                            const company = companies?.filter((item) => item.id === record.company);
                            if (company?.length) return company[0].shortName;
                            return '- Компания удалена -';
                        }
                        if (record.type !== 'client') {
                            return '- Сотрудник NSS -';
                        }
                        return '';
                    }}
                />
                <Column
                    title="Контактное лицо"
                    dataIndex="fio"
                    key="fio"
                    width="25%"
                    render={(value, record: UsersItem) => (record.fio)}
                />
                <Column
                    title="Email"
                    dataIndex="email"
                    key="email"
                    width="25%"
                    render={(value, record: UsersItem) => (record.email)}
                />
                <Column
                    key="actions"
                    render={(value, record: UsersItem) => (
                        <>
                            <Button
                                icon={<EyeOutlined />}
                                onClick={() => dispatch(viewingUsersAction(record.id, record.company))}
                            />
                            <Button
                                type="primary"
                                icon={<EditOutlined />}
                                onClick={() => dispatch(editingUsersAction(record.id, record.company))}
                            />
                            {account !== 'engineer' ? (
                                <Button
                                    type="primary"
                                    icon={<DeleteOutlined />}
                                    danger
                                    loading={loading}
                                    onClick={() => onDelete(record.id)}
                                />
                            ) : null}
                        </>
                    )}
                />
            </Table>
            <AddUser />
            <EditUser />
            <ViewUser />
        </div>
    );
};

export default WrapperPage(UsersPage);
