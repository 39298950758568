import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Button,
    Form,
    Input,
    Select
} from 'antd';
import firebase from '../../../utils/firebase';

import { AppState } from '../../../store';
import { creatingUsersAction, requestCreateUsersAction } from '../../../store/actions/users';
import { AccountsType } from '../../../store/types/auth';

const { Option } = Select;

const Add:React.FC = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const users = useSelector((state: AppState) => state.users);
    const auth = useSelector((state: AppState) => state.auth);
    const { creating, loading } = users;
    const { account } = auth;
    const [typeAccount, changeTypeAccount] = useState<null | AccountsType>(null);

    const onSend = (values: any) => {
        const time = firebase.firestore.Timestamp.now();
        const creatingData = {
            type: values.type,
            email: values.email,
            password: values.password,
            fio: values.fio,
            phone: values.phone,
            position: values.position,
            createdAt: time.seconds,
            updatedAt: time.seconds
        };

        if (values.type === 'client') {
            dispatch(requestCreateUsersAction({
                ...creatingData,
                inn: values.inn,
                shortName: values.shortName
            }));
        } else {
            dispatch(requestCreateUsersAction(creatingData));
        }

        form.resetFields();
    };

    const onClose = () => {
        form.resetFields();
        dispatch(creatingUsersAction());
    };

    return (
        <Modal
            title="Создание пользователя"
            centered
            visible={creating}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose} loading={loading}>
                    Отменить
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
                    Создать
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onSend}
            >
                <Form.Item label="Тип" name="type" rules={[{ required: true, message: 'Заполните это поле!' }]}>
                    <Select
                        placeholder="Выберите тип аккаунта"
                        onChange={(option: AccountsType) => changeTypeAccount(option)}
                    >
                        <Option value="client">Клиент</Option>
                        {account === 'admin' && <Option value="engineer">Инженер</Option>}
                        {account === 'admin' && <Option value="manager">Менеджер</Option>}
                    </Select>
                </Form.Item>
                <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Заполните это поле!' }]}>
                    <Input placeholder="Email пользователя" />
                </Form.Item>
                <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Заполните это поле!' }]}>
                    <Input placeholder="Пароль пользователя" />
                </Form.Item>
                <Form.Item label="Ф.И.О." name="fio" rules={[{ required: true, message: 'Заполните это поле!' }]}>
                    <Input placeholder="Ф.И.О." />
                </Form.Item>
                <Form.Item label="Должность" name="position" rules={[{ required: true, message: 'Заполните это поле!' }]}>
                    <Input placeholder="Должность" />
                </Form.Item>
                <Form.Item label="Телефон" name="phone" rules={[{ required: true, message: 'Заполните это поле!' }]}>
                    <Input placeholder="Телефон" />
                </Form.Item>
                {typeAccount && typeAccount === 'client' ? (
                    <>
                        <Form.Item label="ИНН" name="inn" rules={[{ required: true, message: 'Заполните это поле!' }]}>
                            <Input placeholder="ИНН компании" />
                        </Form.Item>
                        <Form.Item label="Название компании" name="shortName" rules={[{ required: true, message: 'Заполните это поле!' }]}>
                            <Input placeholder="Краткое название компании" />
                        </Form.Item>
                    </>
                ) : null}
            </Form>
        </Modal>
    );
};

export default Add;
