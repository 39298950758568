/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import moment, { Moment } from 'moment';
import { eventChannel } from 'redux-saga';
import {
    all,
    call,
    CallEffect,
    put,
    takeEvery
} from 'redux-saga/effects';
import store from '../index';

import firebase, { db, storage } from '../../utils/firebase';
import notification from '../../utils/notification';
import {
    errorChatAction,
    responseGetChatsAction,
    responseSendMessageChatAction,
    addMessagesChatAction,
    responseGetChatHistoryAction,
    responseGetPeriodChatAction,
    responseResetPeriodChatAction,
    responseAddEngineerChatAction,
    responseGetHistoryCalendarChatAction
} from '../actions/chat';
import {
    ChatActionsList,
    ChatItem,
    RequestGetChatsActionType,
    RequestSendMessageChatActionType,
    RequestAddEngineerChatActionType,
    RequestGetPeriodChatActionType,
    RequestResetPeriodChatActionType,
    RequestGetChatHistoryActionType,
    RequestGetHistoryCalendarChatActionType,
    MessageItem,
    MessageItemData,
    AttachemntItem,
    MonthMessagesItem,
    MonthHistoryItem
} from '../types/chat';
import { CompaniesItemShort } from '../types/companies';
import { UsersItem } from '../types/users';
import { addNotifyAction } from '../actions/notifications';

const LIMIT = 30;

function subscribeChatMessages(chatRef: firebase.firestore.Query) {
    return eventChannel((emmiter: any) => {
        chatRef.onSnapshot((messages) => {
            if (!messages.empty) {
                let chatMessages: MessageItem[] = [];
                messages.docChanges().forEach((message) => {
                    const dataMessage = message.doc.data();

                    if (message.type === 'added') {
                        chatMessages = [...chatMessages, {
                            id: message.doc.id,
                            chatID: dataMessage.chatID,
                            userID: dataMessage.userID,
                            createdAt: dataMessage.createdAt.seconds,
                            updatedAt: dataMessage.updatedAt.seconds,
                            readed: dataMessage.readed,
                            status: dataMessage.status,
                            text: dataMessage.text,
                            originalDoc: message.doc,
                            attachments: dataMessage.attachments,
                            hasAttachments: !!dataMessage.attachments.length
                        }];
                    }
                });

                store.dispatch(addMessagesChatAction(chatMessages[0].chatID, chatMessages));

                if (chatMessages[chatMessages.length - 1].createdAt !== undefined && (+moment().format('X') - chatMessages[chatMessages.length - 1].createdAt) <= 10) {
                    const chatIDArray = chatMessages[0].chatID.split('-');
                    const companyID = chatIDArray[1];
                    const { auth, chat } = store.getState();
                    const { currentChat } = chat;
                    const currentChatIDArray = currentChat ? currentChat.split('-') : null;
                    const currentChatID = currentChatIDArray ? currentChatIDArray[1] : '';

                    if (auth.account === 'client') {
                        if (window.location.pathname !== '/chat' && companyID === auth.company) {
                            store.dispatch(addNotifyAction({
                                companyID,
                                companyName: '',
                                date: chatMessages[chatMessages.length - 1].createdAt,
                                type: 'chat'
                            }));
                        }
                    } else if (auth.account === 'admin' || auth.account === 'manager') {
                        // const mainCompany = 's2KJx0KMPqyVxQZ5Q0al';
                        if (window.location.pathname !== '/chat' && companyID !== auth.company) {
                            store.dispatch(addNotifyAction({
                                companyID,
                                companyName: '',
                                date: chatMessages[chatMessages.length - 1].createdAt,
                                type: 'chat'
                            }));
                        } else if (window.location.pathname === '/chat' && companyID !== auth.company && currentChatID !== companyID) {
                            store.dispatch(addNotifyAction({
                                companyID,
                                companyName: '',
                                date: chatMessages[chatMessages.length - 1].createdAt,
                                type: 'chat'
                            }));
                        }
                    }
                }

                const historyElement = document.getElementById('history');
                if (historyElement) {
                    historyElement.scrollTop = historyElement.scrollHeight;
                }
                // console.log(chatMessages);
                // emmiter(chatMessages);
            }
            // emmiter(END);
        });

        return () => chatRef;
    });
}

function* getChatsWorker(effect: RequestGetChatsActionType) {
    try {
        const companies: firebase.firestore.QuerySnapshot = yield db.collection('companies').get();
        const users: firebase.firestore.QuerySnapshot = yield db.collection('users').where('uid', '!=', null).get();

        let companiesItems: CompaniesItemShort[] = [];
        let usersItems: UsersItem[] = [];

        if (!users.empty) {
            users.forEach((doc) => {
                const { id } = doc;
                const data = doc.data();

                usersItems = [...usersItems, {
                    id,
                    uid: data.uid,
                    company: data.company,
                    email: data.email,
                    fio: data.fio,
                    phone: data.phone,
                    position: data.position,
                    type: data.type,
                    updatedAt: data.updatedAt.seconds,
                    createdAt: data.createdAt.seconds
                } as UsersItem];
            });
        }

        if (!companies.empty) {
            companies.forEach((doc) => {
                const { id } = doc;
                const data = doc.data();

                companiesItems = [...companiesItems, {
                    id,
                    fullName: data.fullName,
                    shortName: data.shortName
                } as CompaniesItemShort];
            });
        }

        let typeUser = null;
        const user = usersItems.filter((item) => item.id === effect.userID);

        if (user.length) typeUser = user[0].type;
        else return;

        if (companiesItems.length) {
            const chatsRef = db.collection('chats');
            let chatsQuery = chatsRef.where('members', 'array-contains-any', [effect.userID]);

            if (typeUser === 'admin' || typeUser === 'manager') {
                chatsQuery = chatsRef;
            }

            const chats: firebase.firestore.QuerySnapshot = yield chatsRef.get();
            let chatsData: ChatItem[] = [];

            for (const doc of chats.docs) {
                const { id } = doc;
                const data = doc.data();

                let chatData: ChatItem = {
                    id,
                    companyID: data.companyID,
                    companyName: null,
                    createdAt: data.createdAt.seconds,
                    loading: false,
                    members: data.members,
                    messages: []
                };

                const company = companiesItems.filter((item) => item.id === data.companyID);

                if (company.length) {
                    chatData = { ...chatData, companyName: company[0].shortName };
                }

                chatsData = [...chatsData, chatData];
            }

            yield put(responseGetChatsAction(chatsData, usersItems));

            let tasksMessages: any[] = [];
            for (const doc of chats.docs) {
                tasksMessages = [...tasksMessages, call(subscribeChatMessages, doc.ref.collection('messages').orderBy('createdAt', 'desc').limit(LIMIT))];
            }
            yield all(tasksMessages);
        } else {
            yield put(responseGetChatsAction([], usersItems));
        }
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorChatAction('Не удалось загрузить данные'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные');
        console.log(error);
    }
}

function* sendMessageChatWorker(effect: RequestSendMessageChatActionType) {
    try {
        const refChat = db
            .collection('chats')
            .doc(effect.data.chatID)
            .collection('messages')
            .doc(effect.data.id);

        let attachments: AttachemntItem[] = [];

        if (effect.data.hasAttachments) {
            let attachTasks: CallEffect<any>[] = [];

            effect.data.attachments.forEach((attach: any) => {
                const tmpType = attach.name.split('.');
                const type = tmpType[tmpType.length - 1];
                const url = `attachments-${effect.data.chatID}/${attach.uid}.${type}`;
                const storageRef = storage.ref();
                const fileRef = storageRef.child(url);

                attachTasks = [
                    ...attachTasks,
                    call(function* () {
                        yield fileRef.put(attach as File);
                    })
                ];

                attachments = [...attachments, {
                    name: attach.name,
                    size: attach.size,
                    uid: attach.uid,
                    url
                }];
            });

            yield all(attachTasks);
        }

        yield refChat.set({
            chatID: effect.data.chatID,
            userID: effect.data.userID,
            readed: false,
            status: 'sended',
            text: effect.data.text,
            hasAttachments: effect.data.hasAttachments,
            attachments,
            createdAt: firebase.firestore.Timestamp.fromMillis(effect.data.createdAt * 1000),
            updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.createdAt * 1000)
        });

        yield put(responseSendMessageChatAction({ ...effect.data, status: 'sended', attachments }));

        const historyElement = document.getElementById('history');
        if (historyElement) {
            historyElement.scrollTop = historyElement.scrollHeight;
        }
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorChatAction('Не удалось загрузить данные'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные');
        console.log(error);
    }
}

function* getChatHistoryWorker(effect: RequestGetChatHistoryActionType) {
    const chat: firebase.firestore.QuerySnapshot = yield db
        .collection('chats')
        .doc(effect.chatID)
        .collection('messages')
        .orderBy('createdAt', 'desc')
        .startAfter(effect.lastDoc)
        .limit(LIMIT)
        .get();

    let messagesData: MessageItem[] = [];

    if (!chat.empty) {
        chat.forEach((doc) => {
            const { id } = doc;
            const data = doc.data();

            messagesData = [...messagesData, {
                id,
                chatID: data.chatID,
                userID: data.userID,
                readed: data.readed,
                status: data.status,
                text: data.text,
                createdAt: data.createdAt.seconds,
                updatedAt: data.updatedAt.seconds,
                originalDoc: doc,
                attachments: data.attachments,
                hasAttachments: data.hasAttachments
            }];
        });
    }

    const historyElement = document.getElementById('history');
    const prevScrollPoint = historyElement ? historyElement.scrollHeight : 0;

    yield put(responseGetChatHistoryAction(effect.chatID, messagesData));

    if (historyElement) {
        historyElement.scrollTop = historyElement.scrollHeight - prevScrollPoint;
    }
}

function* getPeriodChatWorker(effect: RequestGetPeriodChatActionType) {
    const startDate = firebase.firestore.Timestamp.fromDate(new Date(moment(effect.period[0]).format('YYYY-MM-DD')));
    const endDate = firebase.firestore.Timestamp.fromDate(new Date(moment(effect.period[1]).format('YYYY-MM-DD')));
    const chat: firebase.firestore.QuerySnapshot = yield db
        .collection('chats')
        .doc(effect.chatID)
        .collection('messages')
        .where('createdAt', '>=', startDate)
        .where('createdAt', '<=', endDate)
        .orderBy('createdAt', 'desc')
        .get();

    let messagesData: MessageItem[] = [];

    if (!chat.empty) {
        chat.forEach((doc) => {
            const { id } = doc;
            const data = doc.data();

            messagesData = [...messagesData, {
                id,
                chatID: data.chatID,
                userID: data.userID,
                readed: data.readed,
                status: data.status,
                text: data.text,
                createdAt: data.createdAt.seconds,
                updatedAt: data.updatedAt.seconds,
                originalDoc: doc,
                attachments: data.attachments,
                hasAttachments: data.hasAttachments
            }];
        });
    }

    const historyElement = document.getElementById('history');

    yield put(responseGetPeriodChatAction(effect.chatID, messagesData));

    if (historyElement) {
        historyElement.scrollTop = 0;
    }
}

function* resetPeriodChatWorker(effect: RequestResetPeriodChatActionType) {
    const chat: firebase.firestore.QuerySnapshot = yield db
        .collection('chats')
        .doc(effect.chatID)
        .collection('messages')
        .orderBy('createdAt', 'desc')
        .limit(LIMIT)
        .get();

    let messagesData: MessageItem[] = [];

    if (!chat.empty) {
        chat.forEach((doc) => {
            const { id } = doc;
            const data = doc.data();

            messagesData = [...messagesData, {
                id,
                chatID: data.chatID,
                userID: data.userID,
                readed: data.readed,
                status: data.status,
                text: data.text,
                createdAt: data.createdAt.seconds,
                updatedAt: data.updatedAt.seconds,
                originalDoc: doc,
                attachments: data.attachments,
                hasAttachments: data.hasAttachments
            }];
        });
    }

    const historyElement = document.getElementById('history');

    yield put(responseResetPeriodChatAction(effect.chatID, messagesData));

    if (historyElement) {
        historyElement.scrollTop = historyElement.scrollHeight;
    }
}

function* addEngineerChatWorker(effect: RequestAddEngineerChatActionType) {
    try {
        const refChat = db
            .collection('chats')
            .doc(effect.chatID);

        yield refChat.update({
            members: firebase.firestore.FieldValue.arrayUnion(effect.engineerID)
        });

        yield put(responseAddEngineerChatAction(effect.chatID, effect.engineerID));
        notification('success', 'Успех', 'Инженер успешно добавлен');
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorChatAction('Не удалось добавить инженера'));
        notification('error', 'Ошибка', 'Не удалось добавить инженера');
        console.log(error);
    }
}

function* getHistoryCalendarChatWorker(effect: RequestGetHistoryCalendarChatActionType) {
    const maxDay = +effect.date.format('D');
    const month = +effect.date.format('M');
    const year = +effect.date.format('YYYY');

    if (+moment().format('YYYY') === year && month > +moment().format('M')) return;

    let rangeDates: Moment[] = [];
    for (let i = 1; i <= maxDay; i++) {
        rangeDates = [...rangeDates, moment(`${year}-${month}-${i} 00:00:00`)];
    }

    let tasks: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>[] = [];

    rangeDates.forEach((date) => {
        const startDate = firebase.firestore.Timestamp.fromDate(new Date(date.format('YYYY-MM-DD 00:00:00')));
        const endDate = firebase.firestore.Timestamp.fromDate(new Date(date.format('YYYY-MM-DD 23:59:59')));
        tasks = [
            ...tasks,
            db
                .collection('chats')
                .doc(effect.chatID)
                .collection('messages')
                .where('createdAt', '>=', startDate)
                .where('createdAt', '<=', endDate)
                .orderBy('createdAt', 'desc')
                .limit(1)
                .get()
        ];
    });

    const monthMessagesDocuments: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>[] = yield all(tasks);
    let monthMessages: MonthMessagesItem[] = [];

    monthMessagesDocuments.forEach((doc, i) => {
        monthMessages = [...monthMessages, {
            date: rangeDates[i],
            hasMessages: !!doc.size
        }];
    });

    const monthHistory: MonthHistoryItem = {
        month: rangeDates[0].format('YYYY-MM'),
        data: monthMessages
    };

    yield put(responseGetHistoryCalendarChatAction(monthHistory));
}

export function* chatWatcher() {
    yield takeEvery(ChatActionsList.REQUEST_GET_CHATS, getChatsWorker);
    yield takeEvery(ChatActionsList.REQUEST_SEND_MESSAGE_CHAT, sendMessageChatWorker);
    yield takeEvery(ChatActionsList.REQUEST_GET_CHAT_HISTORY, getChatHistoryWorker);
    yield takeEvery(ChatActionsList.REQUEST_GET_PERIOD_CHAT, getPeriodChatWorker);
    yield takeEvery(ChatActionsList.REQUEST_RESET_PERIOD_CHAT, resetPeriodChatWorker);
    yield takeEvery(ChatActionsList.REQUEST_ADD_ENGINEER_CHAT, addEngineerChatWorker);
    yield takeEvery(ChatActionsList.REQUEST_GET_CALENDAR_HISTORY_CHAT, getHistoryCalendarChatWorker);
}
