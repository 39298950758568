import {
    EquipmentsActionsList,
    EquipmentsActionsType,
    EquipmentItem,
    EquipmentItemData,
    EquipmentsStateType
} from '../types/equipments';
import generateID from '../../utils/generateID';
import firebase from '../../utils/firebase';

export const initialState: EquipmentsStateType = {
    loading: false,
    data: null,
    deleteFromServer: {
        equipmentsIDs: [],
        detailsIDs: []
    },
    createToServer: {
        equipmentsIDs: [],
        detailsIDs: []
    },
    resetForm: false,
    error: null
};

const companiesReducer = (state: EquipmentsStateType = initialState, action: EquipmentsActionsType) : EquipmentsStateType => {
    switch (action.type) {
        case EquipmentsActionsList.ERROR_EQUIPMENTS: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case EquipmentsActionsList.REQUEST_GET_EQUIPMENTS:
        case EquipmentsActionsList.REQUEST_SAVE_EQUIPMENTS: {
            return {
                ...state,
                loading: true
            };
        }
        case EquipmentsActionsList.RESPONSE_GET_EQUIPMENTS: {
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        case EquipmentsActionsList.RESPONSE_SAVE_EQUIPMENTS: {
            return {
                ...state,
                loading: false,
                deleteFromServer: { ...state.deleteFromServer, ...initialState.deleteFromServer },
                createToServer: { ...state.deleteFromServer, ...initialState.createToServer }
            };
        }
        case EquipmentsActionsList.ADD_EQUIPMENTS: {
            const id = generateID();
            const time = firebase.firestore.Timestamp.now();
            const newState = {
                ...state,
                data: state.data && [...state.data, {
                    id,
                    companyID: action.companyID ? action.companyID : '',
                    mark: '',
                    model: '',
                    serial: '',
                    year: '',
                    count: 1,
                    build: '',
                    details: [],
                    createdAt: time.seconds,
                    updatedAt: time.seconds
                }],
                createToServer: {
                    ...state.createToServer,
                    equipmentsIDs: [...state.createToServer.equipmentsIDs, id]
                }
            };
            localStorage.setItem('equipmentsData', JSON.stringify(newState.data));
            return newState;
        }
        case EquipmentsActionsList.ADD_EQUIPMENTS_DETAIL: {
            const id = generateID();
            const time = firebase.firestore.Timestamp.now();
            const newState = {
                ...state,
                data: state.data && state.data.map((equipment) => {
                    if (equipment.id === action.equipmentID) {
                        return {
                            ...equipment,
                            details: [...equipment.details, {
                                id,
                                name: '',
                                serial: '',
                                count: 1,
                                build: '',
                                companyID: equipment.companyID,
                                createdAt: time.seconds,
                                updatedAt: time.seconds
                            }]
                        };
                    }
                    return equipment;
                }),
                createToServer: {
                    ...state.createToServer,
                    detailsIDs: [...state.createToServer.detailsIDs, id],
                    equipmentsIDs: state.createToServer.equipmentsIDs.includes(action.equipmentID)
                        ? state.createToServer.equipmentsIDs
                        : [...state.createToServer.equipmentsIDs, action.equipmentID]
                }
            };
            localStorage.setItem('equipmentsData', JSON.stringify(newState.data));
            return newState;
        }
        case EquipmentsActionsList.UPDATE_EQUIPMENTS: {
            const newState = {
                ...state,
                data: state.data && state.data.map((eq) => {
                    if (eq.id === action.equipmentID) {
                        return {
                            ...eq,
                            ...action.value
                        };
                    }
                    return eq;
                }),
                createToServer: {
                    ...state.createToServer,
                    equipmentsIDs: !state.createToServer.equipmentsIDs.includes(action.equipmentID)
                        ? [...state.createToServer.equipmentsIDs, action.equipmentID]
                        : state.createToServer.equipmentsIDs
                }
            };
            localStorage.setItem('equipmentsData', JSON.stringify(newState.data));
            return newState;
        }
        case EquipmentsActionsList.UPDATE_EQUIPMENTS_DETAIL: {
            const newState = {
                ...state,
                data: state.data && state.data.map((eq) => {
                    const newDetails = eq.details.map((det) => {
                        if (det.id === action.detailID) {
                            return {
                                ...det,
                                ...action.value
                            };
                        }
                        return det;
                    });
                    const newEq: EquipmentItem = { ...eq, details: newDetails };

                    return newEq;
                }),
                createToServer: {
                    ...state.createToServer,
                    detailsIDs: !state.createToServer.detailsIDs.includes(action.detailID)
                        ? [...state.createToServer.detailsIDs, action.detailID]
                        : state.createToServer.detailsIDs
                }
            };
            localStorage.setItem('equipmentsData', JSON.stringify(newState.data));
            return newState;
        }
        case EquipmentsActionsList.DELETE_EQUIPMENTS: {
            if (state.createToServer.equipmentsIDs.includes(action.equipment.id)) {
                const newState = {
                    ...state,
                    data: state.data && state.data.filter((equipment) => equipment.id !== action.equipment.id),
                    createToServer: {
                        ...state.createToServer,
                        equipmentsIDs: state.createToServer.equipmentsIDs.filter((equipmentID) => equipmentID !== action.equipment.id),
                        detailsIDs: state.createToServer.detailsIDs.filter((detailID) => !action.equipment.details.map((detail) => detail.id).includes(detailID))
                    }
                };
                localStorage.setItem('equipmentsData', JSON.stringify(newState.data));
                return newState;
            }
            const newState = {
                ...state,
                data: state.data && state.data.filter((equipment) => equipment.id !== action.equipment.id),
                deleteFromServer: {
                    ...state.deleteFromServer,
                    equipmentsIDs: [...state.deleteFromServer.equipmentsIDs, action.equipment.id],
                    detailsIDs: [...state.deleteFromServer.detailsIDs, ...action.equipment.details.map((detail) => detail.id)]
                }
            };
            localStorage.setItem('equipmentsData', JSON.stringify(newState.data));
            return newState;
        }
        case EquipmentsActionsList.DELETE_EQUIPMENTS_DETAIL: {
            if (state.createToServer.detailsIDs.includes(action.detailID)) {
                const detailsIDs = state.createToServer.detailsIDs.filter((detailID) => detailID !== action.detailID);
                const newState = {
                    ...state,
                    data: state.data && state.data.map((equipment) => {
                        if (equipment.id === action.equipmentID) {
                            return {
                                ...equipment,
                                details: equipment.details.filter((detail) => detail.id !== action.detailID)
                            };
                        }
                        return equipment;
                    }),
                    createToServer: {
                        ...state.createToServer,
                        detailsIDs,
                        equipmentsIDs: state.createToServer.equipmentsIDs.filter((eqID) => {
                            const detailsEq = state.data?.filter((item) => item.id === eqID)[0]?.details.filter((det) => det.id !== action.detailID);
                            if (detailsEq !== undefined) {
                                const changeEquipment = detailsEq?.filter((det) => {
                                    const countDetailsEquipmentNew = detailsIDs.filter((detID) => detID === det.id);

                                    if (countDetailsEquipmentNew.length) return true;
                                    return false;
                                }).length;

                                if (changeEquipment) return true;
                            }
                            return false;
                        })
                    }
                };
                localStorage.setItem('equipmentsData', JSON.stringify(newState.data));
                return newState;
            }

            let equipmentsIDsChanging: string[] = [];
            const detailsIDsDeleting = [...state.deleteFromServer.detailsIDs, action.detailID];
            if (state.createToServer.equipmentsIDs.length) {
                if (state.createToServer.equipmentsIDs.includes(action.equipmentID)) {
                    equipmentsIDsChanging = state.createToServer.equipmentsIDs;
                } else {
                    equipmentsIDsChanging = [...equipmentsIDsChanging, action.equipmentID];
                }
            } else {
                equipmentsIDsChanging = [...equipmentsIDsChanging, action.equipmentID];
            }
            const newState = {
                ...state,
                data: state.data && state.data.map((equipment) => {
                    if (equipment.id === action.equipmentID) {
                        return {
                            ...equipment,
                            details: equipment.details.filter((detail) => detail.id !== action.detailID)
                        };
                    }
                    return equipment;
                }),
                deleteFromServer: {
                    ...state.deleteFromServer,
                    detailsIDs: detailsIDsDeleting
                },
                createToServer: {
                    ...state.createToServer,
                    equipmentsIDs: equipmentsIDsChanging
                }
            };
            localStorage.setItem('equipmentsData', JSON.stringify(newState.data));
            return newState;
        }
        case EquipmentsActionsList.SET_EQUIPMENTS_TEMPLATE: {
            localStorage.removeItem('equipmentsData');
            return {
                ...state,
                ...initialState,
                data: action.template,
                resetForm: true
            };
        }
        case EquipmentsActionsList.RESET_FORM_EQUIPMENTS: {
            localStorage.removeItem('equipmentsData');
            return {
                ...state,
                resetForm: action.reset,
                data: action.reset ? [] : state.data
            };
        }
        case EquipmentsActionsList.RESPONSE_SIGNOUT_AUTH: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default companiesReducer;
