import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Button,
    Row,
    Col,
    Divider,
    Select,
    DatePicker
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { RequiredMark } from 'antd/lib/form/Form';
import moment from 'moment';
import 'moment/locale/ru';
import firebase from '../../utils/firebase';

import { AppState } from '../../store';
import { requestAddDatesAction } from '../../store/actions/dates';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AddDate:React.FC = () => {
    const [formAdd] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>(true);
    const dates = useSelector((state: AppState) => state.dates);
    const dispatch = useDispatch();
    const {
        loading,
        companies,
        users
    } = dates;

    const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    const onAdd = (values: any) => {
        const time = firebase.firestore.Timestamp.now();

        dispatch(requestAddDatesAction({
            companyID: values.companyID,
            userID: values.userID,
            from: +moment(values.fromTo[0]).format('X'),
            to: +moment(values.fromTo[1]).format('X'),
            finished: false,
            createdAt: time.seconds,
            updatedAt: time.seconds
        }));
        formAdd.resetFields();
    };

    return (
        <Form
            form={formAdd}
            layout="vertical"
            initialValues={{
                companyID: undefined,
                finished: false,
                from: '',
                to: '',
                userID: undefined
            }}
            onValuesChange={onRequiredTypeChange}
            requiredMark={requiredMark}
            onFinish={onAdd}
        >
            <Row gutter={15}>
                <Divider>Добавить срок выезда</Divider>
                <Col span={24} lg={8}>
                    <Form.Item label="Компания" name="companyID" tooltip="Компания выезда" required>
                        <Select
                            showSearch
                            placeholder="Выберите компанию"
                            optionFilterProp="children"
                            filterOption={(input, option) => option !== undefined && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            loading={companies === null}
                        >
                            {companies ? companies.map((item) => <Option value={item.id} key={item.id}>{item.shortName}</Option>) : null}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24} lg={8}>
                    <Form.Item label="Инженер" name="userID" tooltip="Инженер, который поедет" required>
                        <Select
                            showSearch
                            placeholder="Выберите инженера"
                            optionFilterProp="children"
                            filterOption={(input, option) => option !== undefined && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            loading={users === null}
                        >
                            {users ? users.map((item) => <Option value={item.id} key={item.id}>{item.fio}</Option>) : null}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24} lg={8}>
                    <Form.Item label="Даты выезда" name="fromTo" required>
                        <RangePicker
                            placeholder={['Начало', 'Конец']}
                            format="DD.MM.YYYY"
                            locale={{
                                lang: {
                                    locale: 'ru_RU',
                                    placeholder: 'Выбрать дату',
                                    rangePlaceholder: ['Начальная дата', 'Конечная дата'],
                                    today: 'Сегодня',
                                    now: 'Сейчас',
                                    backToToday: 'Вернуться назад',
                                    ok: 'ОК',
                                    clear: 'Очистить',
                                    month: 'Месяц',
                                    year: 'Год',
                                    timeSelect: 'Выбрать время',
                                    dateSelect: 'Выбрать день',
                                    monthSelect: 'Выбрать месяц',
                                    yearSelect: 'Выбрать год',
                                    decadeSelect: 'Выбрать десятилетие',
                                    yearFormat: 'YYYY',
                                    dateFormat: 'DD.MM.YYYY',
                                    dayFormat: 'D',
                                    dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
                                    monthFormat: 'MMMM',
                                    monthBeforeYear: true,
                                    previousMonth: 'Предыдущий месяц (PageUp)',
                                    nextMonth: 'Следующий месяц (PageDown)',
                                    previousYear: 'Предыдущий год (Control + left)',
                                    nextYear: 'Следующий год (Control + right)',
                                    previousDecade: 'Предыдущее десятилетие',
                                    nextDecade: 'Следующее лесятилетие',
                                    previousCentury: 'Предыдущий век',
                                    nextCentury: 'Следующий век'
                                },
                                timePickerLocale: {
                                    placeholder: 'Выбрать время'
                                },
                                dateFormat: 'DD.MM.YYYY',
                                dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
                                weekFormat: 'wo YYYY',
                                monthFormat: 'MM YYYY'
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Row justify="center">
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                icon={<PlusCircleOutlined />}
                                loading={loading}
                            >
                                Добавить
                            </Button>
                        </Form.Item>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

export default AddDate;
