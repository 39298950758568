import { put, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import moment from 'moment';

import store from '../index';
import firebase, { db } from '../../utils/firebase';
import generateID from '../../utils/generateID';
import notification from '../../utils/notification';
import { resetFormEquipmentsAction } from '../actions/equipments';
import {
    errorOrderAction,
    setAdditionalDataOrdersAction,
    responseGetOrdersAction,
    responseCreateOrdersAction
} from '../actions/orders';
import { CompaniesItem } from '../types/companies';
import { EquipmentDetailItem, EquipmentItem } from '../types/equipments';
import {
    OrdersActionsList,
    OrdersItem,
    RequestGetOrdersActionType,
    RequestCreateOrdersActionType,
    OrdersItemData
} from '../types/orders';
import { addNotifyAction } from '../actions/notifications';

function subscribeOrders(ref: firebase.firestore.Query) {
    return eventChannel((emmiter: any) => {
        ref.onSnapshot((data) => {
            if (!data.empty) {
                data.docChanges().forEach((order) => {
                    const dataOrder = order.doc.data();
                    if ((+moment().format('X') - dataOrder.createdAt.seconds) <= 10) {
                        store.dispatch(addNotifyAction({
                            companyID: dataOrder.companyID,
                            companyName: '',
                            date: dataOrder.createdAt.seconds,
                            type: 'order'
                        }));
                    }
                });
            }
        });

        return () => ref;
    });
}

function* getOrdersWorker(effect: RequestGetOrdersActionType) {
    try {
        const companiesRef = db.collection('companies');
        const equipmentsRef = db.collection('equipments');
        const detailsRef = db.collection('details');
        const ordersRef = db.collection('orders');

        let companiesSnapshot: firebase.firestore.QuerySnapshot | null = null;
        let equipmentsSnapshot: firebase.firestore.QuerySnapshot | null = null;
        let detailsSnapshot: firebase.firestore.QuerySnapshot | null = null;
        let ordersSnapshot: firebase.firestore.QuerySnapshot | null = null;

        if (effect.companyID) {
            companiesSnapshot = yield companiesRef.where(firebase.firestore.FieldPath.documentId(), '==', effect.companyID).get();
            equipmentsSnapshot = yield equipmentsRef.where('companyID', '==', effect.companyID).get();
            detailsSnapshot = yield detailsRef.where('companyID', '==', effect.companyID).get();
            ordersSnapshot = yield ordersRef.orderBy('createdAt', 'desc').where('companyID', '==', effect.companyID).get();
        } else {
            companiesSnapshot = yield companiesRef.get();
            equipmentsSnapshot = yield equipmentsRef.get();
            detailsSnapshot = yield detailsRef.get();
            ordersSnapshot = yield ordersRef.orderBy('createdAt', 'desc').get();
        }

        let companiesData: CompaniesItem[] = [];
        let equipmentsData: EquipmentItem[] = [];
        let detailsData: EquipmentDetailItem[] = [];
        let ordersData: OrdersItem[] = [];

        if (companiesSnapshot && equipmentsSnapshot && detailsSnapshot && ordersSnapshot) {
            companiesSnapshot.forEach((doc) => {
                const docData = doc.data();
                companiesData = [...companiesData, {
                    ...docData,
                    id: doc.id,
                    createdAt: docData.createdAt.seconds,
                    updatedAt: docData.updatedAt.seconds
                } as CompaniesItem];
            });
            equipmentsSnapshot.forEach((doc) => {
                const docData = doc.data();
                equipmentsData = [...equipmentsData, {
                    ...docData,
                    id: doc.id,
                    createdAt: docData.createdAt.seconds,
                    updatedAt: docData.updatedAt.seconds
                } as EquipmentItem];
            });
            detailsSnapshot.forEach((doc) => {
                const docData = doc.data();
                detailsData = [...detailsData, {
                    ...docData,
                    id: doc.id,
                    createdAt: docData.createdAt.seconds,
                    updatedAt: docData.updatedAt.seconds
                } as EquipmentDetailItem];
            });
            ordersSnapshot.forEach((doc) => {
                const docData = doc.data();
                ordersData = [...ordersData, {
                    ...docData,
                    id: doc.id,
                    createdAt: docData.createdAt.seconds,
                    updatedAt: docData.updatedAt.seconds
                } as OrdersItem];
            });
        }

        yield put(setAdditionalDataOrdersAction(companiesData, equipmentsData, detailsData));
        yield put(responseGetOrdersAction(ordersData));
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorOrderAction('Не удалось загрузить данные'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные');
        console.log(error);
    }
}

function* createOrderWorker(effect: RequestCreateOrdersActionType) {
    const id = generateID();

    try {
        yield db.collection('orders').doc(id).set({
            ...effect.data,
            createdAt: firebase.firestore.Timestamp.fromMillis(effect.data.createdAt * 1000),
            updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.updatedAt * 1000)
        });

        yield put(responseCreateOrdersAction({
            ...effect.data,
            id
        }));
        yield put(resetFormEquipmentsAction(true));

        notification('success', 'Успех', 'Заказ успешно отправлен');
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorOrderAction('Не удалось отправить заказ'));
        notification('error', 'Ошибка', 'Не удалось отправить заказ');
        console.log(error);
    }
}

function* subscribeOrdersWorker() {
    const ordersRef = db.collection('orders');
    yield subscribeOrders(ordersRef);
}

export function* ordersWatcher() {
    yield takeEvery(OrdersActionsList.REQUEST_CREATE_ORDERS, createOrderWorker);
    yield takeEvery(OrdersActionsList.REQUEST_GET_ORDERS, getOrdersWorker);
    yield takeEvery(OrdersActionsList.SUBSCRIBE_ORDERS, subscribeOrdersWorker);
}
