import {
    ApartmentOutlined, AuditOutlined, BarChartOutlined, ContainerOutlined, FieldTimeOutlined, FileExclamationOutlined, MessageOutlined, UserOutlined
} from '@ant-design/icons';
import React from 'react';

import {
    LoginPage,
    RegistrationPage,
    MainPage,
    CompanyPage,
    EquipmentPage,
    DatesPage,
    ChatPage,
    NotAccess,
    NotFound,
    OffersPage,
    UsersPage,
    DashboardPage,
    OrdersPage
} from './pages';
import { AccountsType } from './store/types/auth';

type RouteType = {
    path: string
    component: any,
    private: boolean,
    permissions: AccountsType[] | null,
    showInMenu: boolean,
    nameInMenu?: string,
    nameInPage?: string,
    icon?: React.ReactNode
};

const routes:RouteType[] = [
    {
        path: '/403',
        component: NotAccess,
        private: false,
        permissions: null,
        showInMenu: false
    },
    {
        path: '/404',
        component: NotFound,
        private: false,
        permissions: null,
        showInMenu: false
    },
    {
        path: '/',
        component: MainPage,
        private: true,
        permissions: null,
        showInMenu: false
    },
    {
        path: '/login',
        component: LoginPage,
        private: false,
        permissions: null,
        showInMenu: false
    },
    {
        path: '/register',
        component: RegistrationPage,
        private: false,
        permissions: null,
        showInMenu: false
    },
    {
        path: '/offers',
        component: OffersPage,
        private: true,
        permissions: ['admin', 'manager'],
        showInMenu: true,
        nameInMenu: 'Запросы на КП',
        nameInPage: 'Запросы на КП',
        icon: <FileExclamationOutlined />
    },
    {
        path: '/orders',
        component: OrdersPage,
        private: true,
        permissions: ['admin', 'manager'],
        showInMenu: true,
        nameInMenu: 'Заказы',
        nameInPage: 'Заказы',
        icon: <ContainerOutlined />
    },
    {
        path: '/company',
        component: CompanyPage,
        private: true,
        permissions: ['client'],
        showInMenu: true,
        nameInMenu: 'Данные компании',
        nameInPage: 'Информация о компании',
        icon: <AuditOutlined />
    },
    {
        path: '/equipment',
        component: EquipmentPage,
        private: true,
        permissions: ['client'],
        showInMenu: true,
        nameInMenu: 'Оборудование',
        nameInPage: 'Оборудование',
        icon: <ApartmentOutlined />
    },
    {
        path: '/dates',
        component: DatesPage,
        private: true,
        permissions: ['client', 'manager', 'engineer', 'admin'],
        showInMenu: true,
        nameInMenu: 'Сроки выезда',
        nameInPage: 'Сроки выезда',
        icon: <FieldTimeOutlined />
    },
    {
        path: '/users',
        component: UsersPage,
        private: true,
        permissions: ['manager', 'admin'],
        showInMenu: true,
        nameInMenu: 'Пользователи',
        nameInPage: 'Пользователи',
        icon: <UserOutlined />
    },
    {
        path: '/chat',
        component: ChatPage,
        private: true,
        permissions: ['admin', 'client', 'engineer', 'manager'],
        showInMenu: true,
        nameInMenu: 'Чат',
        nameInPage: 'Чат',
        icon: <MessageOutlined />
    },
    {
        path: '/dashboard',
        component: DashboardPage,
        private: true,
        permissions: ['admin'],
        showInMenu: true,
        nameInMenu: 'Статистика',
        nameInPage: 'Статистика',
        icon: <BarChartOutlined />
    }
];

export default routes;
