/* eslint-disable no-plusplus */
import { put, takeEvery } from 'redux-saga/effects';
import moment from 'moment';

import firebase, { db } from '../../utils/firebase';
import {
    responseCountUsersStatisticAction,
    responseCountSignInStatisticAction,
    responseCountRegistrationStatisticAction,
    responsePopularEquipmentsStatisticAction,
    responsePopularDetailsStatisticAction,
    errorCountUsersStatisticAction
} from '../actions/statistic';
import {
    StatisticActionsList,
    RequestCountUsersStatisticActionType,
    RequestCountSignInStatisticActionType,
    RequestCountRegistrationStatisticActionType,
    CountUsersStatisticData,
    CountSignInStatisticDataItem,
    CountRegistrationStatisticDataItem,
    PopularEquipmentsStatisticDataItem,
    PopularDetailsStatisticDataItem
} from '../types/statistic';
import notification from '../../utils/notification';
import { EquipmentDetailItem, EquipmentItem } from '../types/equipments';
import { OrderDetailItemData, OrderEquipmentItemData } from '../types/orders';

function* countUsersStatisticWorker(effect: RequestCountUsersStatisticActionType) {
    try {
        const usersCountSnapshot: firebase.firestore.QuerySnapshot = yield db.collection('users').get();

        const startDate = firebase.firestore.Timestamp.fromDate(new Date(effect.date[0].format('YYYY-MM-DD 00:00:00')));
        const endDate = firebase.firestore.Timestamp.fromDate(new Date(effect.date[1].format('YYYY-MM-DD 23:59:59')));

        const newUsersCountSnapshot: firebase.firestore.QuerySnapshot = yield db
            .collection('users')
            .where('createdAt', '>=', startDate)
            .where('createdAt', '<=', endDate)
            .get();

        const data: CountUsersStatisticData = {
            new: newUsersCountSnapshot.size,
            all: usersCountSnapshot.size
        };

        yield put(responseCountUsersStatisticAction(data));
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorCountUsersStatisticAction('Не удалось загрузить данные о пользователях'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные о пользователях');
        console.log(error);
    }
}

function* countSignInStatisticWorker(effect: RequestCountSignInStatisticActionType) {
    try {
        const startDate = firebase.firestore.Timestamp.fromDate(new Date(effect.date[0].format('YYYY-MM-DD 00:00:00')));
        const endDate = firebase.firestore.Timestamp.fromDate(new Date(effect.date[1].format('YYYY-MM-DD 23:59:59')));

        const statisticSignInSnapshot: firebase.firestore.QuerySnapshot = yield db
            .collection('statistics')
            .where('action', '==', 'signin')
            .where('createdAt', '>=', startDate)
            .where('createdAt', '<=', endDate)
            .get();

        const startDay = +effect.date[0].format('D');
        const endDay = +effect.date[1].format('DD');
        let data: CountSignInStatisticDataItem[] = [];

        for (let i = startDay; i <= endDay; i++) {
            const date = moment(effect.date[0]).set({ D: i });
            data = [...data, {
                name: date.format('DD.MM'),
                value: 0,
                date
            }];
        }

        statisticSignInSnapshot.forEach((doc) => {
            const rowData = doc.data();

            data.forEach((item, i) => {
                if (moment(rowData.createdAt.seconds * 1000).format('DD.MM') === item.date.format('DD.MM')) {
                    data[i] = { ...data[i], value: data[i].value + 1 };
                }
            });
        });

        yield put(responseCountSignInStatisticAction(data));
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorCountUsersStatisticAction('Не удалось загрузить данные о входах в систему'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные о входах в систему');
        console.log(error);
    }
}

function* countRegistrationStatisticWorker(effect: RequestCountRegistrationStatisticActionType) {
    try {
        const startDate = firebase.firestore.Timestamp.fromDate(new Date(effect.date[0].format('YYYY-MM-DD 00:00:00')));
        const endDate = firebase.firestore.Timestamp.fromDate(new Date(effect.date[1].format('YYYY-MM-DD 23:59:59')));

        const statisticRegistrationsSnapshot: firebase.firestore.QuerySnapshot = yield db
            .collection('users')
            .where('createdAt', '>=', startDate)
            .where('createdAt', '<=', endDate)
            .get();

        const startDay = +effect.date[0].format('D');
        const endDay = +effect.date[1].format('DD');
        let data: CountRegistrationStatisticDataItem[] = [];

        for (let i = startDay; i <= endDay; i++) {
            const date = moment(effect.date[0]).set({ D: i });
            data = [...data, {
                name: date.format('DD.MM'),
                value: 0,
                date
            }];
        }

        statisticRegistrationsSnapshot.forEach((doc) => {
            const rowData = doc.data();

            data.forEach((item, i) => {
                if (moment(rowData.createdAt.seconds * 1000).format('DD.MM') === item.date.format('DD.MM')) {
                    data[i] = { ...data[i], value: data[i].value + 1 };
                }
            });
        });

        yield put(responseCountRegistrationStatisticAction(data));
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorCountUsersStatisticAction('Не удалось загрузить данные о регистрациях в систему'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные о регистрациях в систему');
        console.log(error);
    }
}

function* popularEquipmentsStatisticWorker() {
    try {
        const equipmentsSnapshot: firebase.firestore.QuerySnapshot = yield db.collection('equipments').get();
        const ordersSnapshot: firebase.firestore.QuerySnapshot = yield db.collection('orders').get();

        let data: PopularEquipmentsStatisticDataItem[] = [];

        const equipmentsData: EquipmentItem[] = [];

        equipmentsSnapshot.forEach((eq) => {
            const eqData = eq.data();
            equipmentsData.push({
                id: eq.id,
                companyID: eqData.companyID,
                count: eqData.count,
                details: eqData.details,
                mark: eqData.mark,
                model: eqData.model,
                serial: eqData.serial,
                year: eqData.year,
                build: eqData.build,
                updatedAt: eqData.updatedAt.seconds,
                createdAt: eqData.createdAt.seconds
            });
        });

        ordersSnapshot.forEach((order) => {
            const orderData = order.data();

            orderData.order.forEach((orderItem: OrderEquipmentItemData) => {
                const equipment = equipmentsData.filter((item) => orderItem.equipmentID === item.id)[0];
                const eqName = `${equipment.mark} - ${equipment.model}`;

                const hasEquipment = !!data.filter((item) => item.name.toLowerCase().replace(' ', '') === eqName.toLowerCase().replace(' ', '')).length;

                if (hasEquipment) {
                    data.forEach((item, i) => {
                        if (item.name.toLowerCase().replace(' ', '') === eqName.toLowerCase().replace(' ', '')) {
                            data[i] = { ...data[i], value: data[i].value + orderItem.equipmentCount };
                        }
                    });
                } else {
                    data = [...data, {
                        key: equipment.id,
                        name: eqName,
                        value: orderItem.equipmentCount
                    }];
                }
            });
        });

        data.sort((a, b) => (a.value < b.value ? 1 : -1));

        yield put(responsePopularEquipmentsStatisticAction(data));
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorCountUsersStatisticAction('Не удалось загрузить данные о регистрациях в систему'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные о регистрациях в систему');
        console.log(error);
    }
}

function* popularDetailsStatisticWorker() {
    try {
        const detailsSnapshot: firebase.firestore.QuerySnapshot = yield db.collection('details').get();
        const ordersSnapshot: firebase.firestore.QuerySnapshot = yield db.collection('orders').get();

        let data: PopularDetailsStatisticDataItem[] = [];

        const detailsData: EquipmentDetailItem[] = [];

        detailsSnapshot.forEach((det) => {
            const detData = det.data();
            detailsData.push({
                id: det.id,
                companyID: detData.companyID,
                name: detData.name,
                serial: detData.serial,
                count: detData.count,
                build: detData.build,
                updatedAt: detData.updatedAt.seconds,
                createdAt: detData.createdAt.seconds
            });
        });

        ordersSnapshot.forEach((order) => {
            const orderData = order.data();

            orderData.order.forEach((orderItem: OrderEquipmentItemData) => {
                orderItem.details.forEach((detailOrderItem: OrderDetailItemData) => {
                    const detail = detailsData.filter((item) => detailOrderItem.id === item.id)[0];
                    const detName = detail.name;

                    const hasDetail = !!data.filter((item) => item.name.toLowerCase().replace(' ', '') === detName.toLowerCase().replace(' ', '')).length;

                    if (hasDetail) {
                        data.forEach((item, i) => {
                            if (item.name.toLowerCase().replace(' ', '') === detName.toLowerCase().replace(' ', '')) {
                                data[i] = { ...data[i], value: data[i].value + detailOrderItem.count };
                            }
                        });
                    } else {
                        data = [...data, {
                            key: detail.id,
                            name: detName,
                            value: detailOrderItem.count
                        }];
                    }
                });
            });
        });

        data.sort((a, b) => (a.value < b.value ? 1 : -1));

        yield put(responsePopularDetailsStatisticAction(data));
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorCountUsersStatisticAction('Не удалось загрузить данные о регистрациях в систему'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные о регистрациях в систему');
        console.log(error);
    }
}

export function* statisticWatcher() {
    yield takeEvery(StatisticActionsList.REQUEST_COUNT_USERS_STATISTIC, countUsersStatisticWorker);
    yield takeEvery(StatisticActionsList.REQUEST_COUNT_SIGN_IN_STATISTIC, countSignInStatisticWorker);
    yield takeEvery(StatisticActionsList.REQUEST_COUNT_REGISTRATION_STATISTIC, countRegistrationStatisticWorker);
    yield takeEvery(StatisticActionsList.REQUEST_POPULAR_EQUIPMENTS_STATISTIC, popularEquipmentsStatisticWorker);
    yield takeEvery(StatisticActionsList.REQUEST_POPULAR_DETAILS_STATISTIC, popularDetailsStatisticWorker);
}
