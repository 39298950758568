import { put, takeEvery } from 'redux-saga/effects';

import firebase, { db, secondApp } from '../../utils/firebase';
import {
    errorUsersAction,
    responseCreateUsersAction,
    responseGetUsersAction,
    responseDeleteUsersAction,
    setAdditionalDataUsersAction,
    responseUpdateUsersAction
} from '../actions/users';
import {
    UsersActionsList,
    UsersItem,
    RequestCreateUsersAction,
    RequestDeleteUsersAction,
    RequestUpdateUsersAction
} from '../types/users';
import generateID from '../../utils/generateID';
import notification from '../../utils/notification';
import { CompaniesItem } from '../types/companies';

function* getUsersWorker() {
    try {
        const companiesSnapshot: firebase.firestore.QuerySnapshot = yield db.collection('companies').get();
        const usersSnapshot: firebase.firestore.QuerySnapshot = yield db.collection('users').where('uid', '!=', null).get();

        let companiesData: CompaniesItem[] = [];
        let usersData: UsersItem[] = [];

        companiesSnapshot.forEach((doc) => {
            const docData = doc.data();
            companiesData = [...companiesData, {
                ...docData,
                id: doc.id,
                createdAt: docData.createdAt.seconds,
                updatedAt: docData.updatedAt.seconds
            } as CompaniesItem];
        });
        usersSnapshot.forEach((doc) => {
            const docData = doc.data();
            usersData = [...usersData, {
                ...docData,
                id: doc.id,
                createdAt: docData.createdAt.seconds,
                updatedAt: docData.updatedAt.seconds
            } as UsersItem];
        });

        yield put(setAdditionalDataUsersAction(companiesData));
        yield put(responseGetUsersAction(usersData));
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorUsersAction('Не удалось загрузить данные'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные');
        console.log(error);
    }
}

function* createUsersWorker(effect: RequestCreateUsersAction) {
    const userID = generateID();

    if (effect.data.type === 'client') {
        const companyID = generateID();

        try {
            const newAuth: firebase.auth.UserCredential = yield secondApp.auth().createUserWithEmailAndPassword(effect.data.email, effect.data.password);
            const UID = newAuth.user?.uid;
            yield secondApp.auth().signOut();

            yield db.collection('users').doc(userID).set({
                company: companyID,
                email: effect.data.email,
                fio: effect.data.fio,
                phone: effect.data.phone,
                position: effect.data.position,
                type: effect.data.type,
                uid: UID,
                createdAt: effect.data.createdAt !== undefined && firebase.firestore.Timestamp.fromMillis(effect.data.createdAt * 1000),
                updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.updatedAt * 1000)
            });
            yield db.collection('companies').doc(companyID).set({
                inn: effect.data.inn,
                shortName: effect.data.shortName,
                fullName: '',
                kpp: '',
                ogrn: '',
                bankBIK: '',
                bankName: '',
                paymentNumber: '',
                сorrespondentNumber: '',
                createdAt: effect.data.createdAt !== undefined && firebase.firestore.Timestamp.fromMillis(effect.data.createdAt * 1000),
                updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.updatedAt * 1000)
            });

            yield db.collection('chats').doc(`chat-${companyID}`).set({
                companyID,
                members: [userID],
                createdAt: effect.data.createdAt !== undefined && firebase.firestore.Timestamp.fromMillis(effect.data.createdAt * 1000)
            });

            yield put(responseCreateUsersAction({
                id: userID,
                company: companyID,
                email: effect.data.email,
                fio: effect.data.fio,
                phone: effect.data.phone,
                position: effect.data.position,
                uid: UID as string,
                type: effect.data.type,
                createdAt: effect.data.createdAt,
                updatedAt: effect.data.updatedAt
            }));

            notification('success', 'Успех', 'Пользователь успешно создан');
        } catch (e: any) {
            const error: firebase.FirebaseError = e;
            yield put(errorUsersAction('Не удалось создать пользователя'));
            notification('error', 'Ошибка', 'Не удалось создать пользователя');
            console.log(error);
        }
    } else {
        try {
            const newAuth: firebase.auth.UserCredential = yield secondApp.auth().createUserWithEmailAndPassword(effect.data.email, effect.data.password);
            const UID = newAuth.user?.uid;
            yield secondApp.auth().signOut();

            yield db.collection('users').doc(userID).set({
                company: null,
                email: effect.data.email,
                fio: effect.data.fio,
                phone: effect.data.phone,
                position: effect.data.position,
                type: effect.data.type,
                uid: UID,
                createdAt: effect.data.createdAt !== undefined && firebase.firestore.Timestamp.fromMillis(effect.data.createdAt * 1000),
                updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.updatedAt * 1000)
            });
            yield put(responseCreateUsersAction({
                id: userID,
                company: null,
                email: effect.data.email,
                fio: effect.data.fio,
                phone: effect.data.phone,
                position: effect.data.position,
                uid: UID as string,
                type: effect.data.type,
                createdAt: effect.data.createdAt,
                updatedAt: effect.data.updatedAt
            }));

            notification('success', 'Успех', 'Пользователь успешно создан');
        } catch (e: any) {
            const error: firebase.FirebaseError = e;
            yield put(errorUsersAction('Не удалось создать пользователя'));
            notification('error', 'Ошибка', 'Не удалось создать пользователя');
            console.log(error);
        }
    }
}

function* updateUsersWorker(effect: RequestUpdateUsersAction) {
    try {
        yield db.collection('users').doc(effect.userID).update({
            ...effect.data.user,
            updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.user.updatedAt * 1000)
        });

        if (effect.companyID) {
            yield db.collection('companies').doc(effect.companyID).update({
                ...effect.data.company,
                updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.company ? effect.data.company.updatedAt * 1000 : 0)
            });
        }

        yield put(responseUpdateUsersAction({
            user: effect.data.user,
            company: effect.data.company
        }, effect.userID, effect.companyID));

        notification('success', 'Успех', 'Пользователь успешно обновлён');
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorUsersAction('Не удалось обновить пользователя'));
        notification('error', 'Ошибка', 'Не удалось обновить пользователя');
        console.log(error);
    }
}

function* deleteUsersWorker(effect: RequestDeleteUsersAction) {
    const time = firebase.firestore.Timestamp.now();

    try {
        yield db.collection('users').doc(effect.id).update({
            uid: null,
            updatedAt: time
        });
        yield put(responseDeleteUsersAction(effect.id));
        notification('success', 'Успех', 'Пользователь успешно удалён');
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorUsersAction('Не удалось удалить пользователя'));
        notification('error', 'Ошибка', 'Не удалось удалить пользователя');
        console.log(error);
    }
}

export function* usersWatcher() {
    yield takeEvery(UsersActionsList.USERS_REQUEST_GET, getUsersWorker);
    yield takeEvery(UsersActionsList.USERS_REQUEST_CREATE, createUsersWorker);
    yield takeEvery(UsersActionsList.USERS_REQUEST_UPDATE, updateUsersWorker);
    yield takeEvery(UsersActionsList.USERS_REQUEST_DELETE, deleteUsersWorker);
}
