import {
    EquipmentsActionsList,
    EquipmentsActionsType,
    EquipmentItem,
    EquipmentDetailItem,
    EquipmentFieldValue
} from '../types/equipments';

export const addEquipmentsAction = (companyID: string | null): EquipmentsActionsType => ({
    type: EquipmentsActionsList.ADD_EQUIPMENTS,
    companyID
});

export const addEquipmentsDetailAction = (id: string): EquipmentsActionsType => ({
    type: EquipmentsActionsList.ADD_EQUIPMENTS_DETAIL,
    equipmentID: id
});

export const updateEquipmentsAction = (equipmentID: string, value: EquipmentFieldValue): EquipmentsActionsType => ({
    type: EquipmentsActionsList.UPDATE_EQUIPMENTS,
    equipmentID,
    value
});

export const updateEquipmentsDetailAction = (detailID: string, value: EquipmentFieldValue): EquipmentsActionsType => ({
    type: EquipmentsActionsList.UPDATE_EQUIPMENTS_DETAIL,
    detailID,
    value
});

export const deleteEquipmentsAction = (equipment: EquipmentItem): EquipmentsActionsType => ({
    type: EquipmentsActionsList.DELETE_EQUIPMENTS,
    equipment
});

export const deleteEquipmentsDetailAction = (equipmentID: string, detailID: string): EquipmentsActionsType => ({
    type: EquipmentsActionsList.DELETE_EQUIPMENTS_DETAIL,
    equipmentID,
    detailID
});

export const errorEquipmentsAction = (error: string): EquipmentsActionsType => ({
    type: EquipmentsActionsList.ERROR_EQUIPMENTS,
    error
});

export const requestGetEquipmentsAction = (companyID: string | null, fromCache = false): EquipmentsActionsType => ({
    type: EquipmentsActionsList.REQUEST_GET_EQUIPMENTS,
    companyID,
    fromCache
});

export const responseGetEquipmentsAction = (equipments: EquipmentItem[]): EquipmentsActionsType => ({
    type: EquipmentsActionsList.RESPONSE_GET_EQUIPMENTS,
    data: equipments
});

export const requestSaveEquipmentsAction = (
    creatingEquipments: EquipmentItem[] | undefined = [],
    creadingDetails: EquipmentDetailItem[] | undefined = [],
    deletingEquipments: string[],
    deletingDetails: string[]
): EquipmentsActionsType => ({
    type: EquipmentsActionsList.REQUEST_SAVE_EQUIPMENTS,
    creating: {
        equipments: creatingEquipments,
        details: creadingDetails
    },
    deleting: {
        equipments: deletingEquipments,
        details: deletingDetails
    }
});

export const setEquipmentsTemplateAction = (template: EquipmentItem[]): EquipmentsActionsType => ({
    type: EquipmentsActionsList.SET_EQUIPMENTS_TEMPLATE,
    template
});

export const resetFormEquipmentsAction = (reset: boolean): EquipmentsActionsType => ({
    type: EquipmentsActionsList.RESET_FORM_EQUIPMENTS,
    reset
});

export const responseSaveEquipmentsAction = (): EquipmentsActionsType => ({
    type: EquipmentsActionsList.RESPONSE_SAVE_EQUIPMENTS
});
