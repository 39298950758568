import {
    OffersActionsList,
    OffersActionsType,
    OffersItem,
    OffersItemData,
    OffersStateType
} from '../types/offers';

export const initialState: OffersStateType = {
    loading: false,
    data: null,
    creating: false,
    companies: null,
    editing: null,
    error: null
};

const companiesReducer = (state: OffersStateType = initialState, action: OffersActionsType) : OffersStateType => {
    switch (action.type) {
        case OffersActionsList.ERROR_OFFERS: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case OffersActionsList.CREATING_OFFERS: {
            return {
                ...state,
                creating: !state.creating
            };
        }
        case OffersActionsList.REQUEST_ADD_OFFERS:
        case OffersActionsList.REQUEST_GET_OFFERS:
        case OffersActionsList.REQUEST_DELETE_OFFERS: {
            return {
                ...state,
                loading: true
            };
        }
        case OffersActionsList.REQUEST_UPDATE_OFFERS: {
            return {
                ...state,
                loading: true,
                editing: action.data.id
            };
        }
        case OffersActionsList.RESPONSE_GET_OFFERS: {
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        case OffersActionsList.RESPONSE_UPDATE_OFFERS: {
            return {
                ...state,
                loading: false,
                data: state.data && state.data.map((offer) => {
                    if (offer.id === action.data.id) {
                        return {
                            ...offer,
                            ...action.data
                        };
                    }
                    return offer;
                })
            };
        }
        case OffersActionsList.RESPONSE_DELETE_OFFERS: {
            return {
                ...state,
                loading: false,
                data: state.data && state.data.filter((offer) => offer.id !== action.offerID)
            };
        }
        case OffersActionsList.RESPONSE_ADD_OFFERS: {
            return {
                ...state,
                loading: false,
                creating: false
            };
        }
        case OffersActionsList.SET_ADDITIONAL_DATA_OFFERS: {
            return {
                ...state,
                companies: action.companies
            };
        }
        case OffersActionsList.RESPONSE_SIGNOUT_AUTH: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default companiesReducer;
