export type AccountsType = 'admin' | 'manager' | 'engineer' | 'client';

export interface AuthStateType {
    uid: string | null,
    id: string | null,
    email: string | null,
    isAuth: boolean,
    account: AccountsType | null,
    company: string | null,
    loading: boolean,
    error: string | null
}

export interface RequestSignUpActionData {
    email: string,
    password: string,
    shortName: string,
    inn: string,
    fio: string,
    position: string,
    phone: string,
    createdAt: number,
    updatedAt: number
}

export enum AuthActionsList {
    ERROR_AUTH = 'ERROR_AUTH',
    REQUEST_SIGNIN_AUTH = 'REQUEST_SIGNIN_AUTH',
    RESPONSE_SIGNIN_AUTH = 'RESPONSE_SIGNIN_AUTH',
    REQUEST_SIGNOUT_AUTH = 'REQUEST_SIGNOUT_AUTH',
    RESPONSE_SIGNOUT_AUTH = 'RESPONSE_SIGNOUT_AUTH',
    REQUEST_SIGNUP_AUTH = 'REQUEST_SIGNUP_AUTH',
    RESPONSE_SIGNUP_AUTH = 'RESPONSE_SIGNUP_AUTH',
    SUBSCRIBE_NEW_AUTH = 'SUBSCRIBE_NEW_AUTH'
}

export interface ErrorAuthActionType {
    type: AuthActionsList.ERROR_AUTH,
    error: string
}

export interface RequestSignInActionType {
    type: AuthActionsList.REQUEST_SIGNIN_AUTH,
    login: string | null,
    password: string | null,
    uid: string | null
}

export interface ResponseSignInActionType {
    type: AuthActionsList.RESPONSE_SIGNIN_AUTH,
    id: string | null,
    uid: string | null,
    email: string | null,
    account: AccountsType | null,
    company: string | null
}

export interface RequestSignOutActionType {
    type: AuthActionsList.REQUEST_SIGNOUT_AUTH
}

export interface ResponseSignOutActionType {
    type: AuthActionsList.RESPONSE_SIGNOUT_AUTH
}

export interface RequestSignUpActionType {
    type: AuthActionsList.REQUEST_SIGNUP_AUTH,
    data: RequestSignUpActionData
}

export interface ResponseSignUpActionType {
    type: AuthActionsList.RESPONSE_SIGNUP_AUTH,
    id: string | null,
    uid: string | null,
    email: string | null,
    account: AccountsType | null,
    company: string | null
}

export interface SubscribeNewAuthActionType {
    type: AuthActionsList.SUBSCRIBE_NEW_AUTH
}

export type AuthActionsType =
ErrorAuthActionType
| RequestSignInActionType
| ResponseSignInActionType
| RequestSignOutActionType
| ResponseSignOutActionType
| RequestSignUpActionType
| ResponseSignUpActionType
| SubscribeNewAuthActionType;
