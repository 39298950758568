import { CompaniesItem } from './companies';
import { EquipmentDetailItem, EquipmentItem } from './equipments';

export interface OrderDetailItemData {
    id: string,
    count: number
}

export interface OrderEquipmentItemData {
    equipmentID: string,
    equipmentCount: number,
    details: OrderDetailItemData[]
}

export interface OrdersItemData {
    companyID: string | null,
    order: OrderEquipmentItemData[],
    createdAt: number,
    updatedAt: number
}

export interface OrdersItem extends OrdersItemData {
    id: string
}

export interface OrdersStateType {
    loading: boolean,
    data: OrdersItem[] | null,
    companies: CompaniesItem[] | null,
    equipments: EquipmentItem[] | null,
    details: EquipmentDetailItem[] | null,
    viewing: { orderID: string | null } | null,
    error: string | null
}

export enum OrdersActionsList {
    REQUEST_GET_ORDERS = 'REQUEST_GET_ORDERS',
    RESPONSE_GET_ORDERS = 'RESPONSE_GET_ORDERS',
    REQUEST_CREATE_ORDERS = 'REQUEST_CREATE_ORDERS',
    RESPONSE_CREATE_ORDERS = 'RESPONSE_CREATE_ORDERS',
    ERROR_ORDERS = 'ERROR_ORDERS',
    VIEWING_ORDERS = 'VIEWING_ORDERS',
    SET_ADDITIONAL_DATA_ORDERS = 'SET_ADDITIONAL_DATA_ORDERS',
    SUBSCRIBE_ORDERS = 'SUBSCRIBE_ORDERS',
    RESPONSE_SIGNOUT_AUTH = 'RESPONSE_SIGNOUT_AUTH'
}

export interface RequestGetOrdersActionType {
    type: OrdersActionsList.REQUEST_GET_ORDERS,
    companyID: string | null
}

export interface ResponseGetOrdersActionType {
    type: OrdersActionsList.RESPONSE_GET_ORDERS,
    data: OrdersItem[]
}

export interface RequestCreateOrdersActionType {
    type: OrdersActionsList.REQUEST_CREATE_ORDERS,
    data: OrdersItemData
}

export interface ResponseCreateOrdersActionType {
    type: OrdersActionsList.RESPONSE_CREATE_ORDERS,
    data: OrdersItem
}

export interface ErrorOrdersActionType {
    type: OrdersActionsList.ERROR_ORDERS,
    error: string
}

export interface ViewingOrdersActionType {
    type: OrdersActionsList.VIEWING_ORDERS,
    orderID: string | null
}

export interface SetAdditionalDataOrdersActionType {
    type: OrdersActionsList.SET_ADDITIONAL_DATA_ORDERS,
    companies: CompaniesItem[],
    equipments: EquipmentItem[],
    details: EquipmentDetailItem[]
}

export interface SubscribeOrdersActionType {
    type: OrdersActionsList.SUBSCRIBE_ORDERS
}

export interface ResponseSignOutActionType {
    type: OrdersActionsList.RESPONSE_SIGNOUT_AUTH
}

export type OrdersActionsType =
RequestGetOrdersActionType
| ResponseGetOrdersActionType
| RequestCreateOrdersActionType
| ResponseCreateOrdersActionType
| ErrorOrdersActionType
| ViewingOrdersActionType
| SetAdditionalDataOrdersActionType
| SubscribeOrdersActionType
| ResponseSignOutActionType;
