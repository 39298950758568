import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Row,
    Col,
    Statistic,
    Card
} from 'antd';
import moment from 'moment';

import {
    UserAddOutlined, UserOutlined
} from '@ant-design/icons';

import Preloader from '../../../components/Preloader';
import { AppState } from '../../../store';
import { requestCountUsersStatisticAction } from '../../../store/actions/statistic';

const CountUsers:React.FC = () => {
    const dispatch = useDispatch();
    const statistic = useSelector((state: AppState) => state.statistic);
    const { countUsers } = statistic;
    const { data } = countUsers;

    useEffect(() => {
        const startDate = moment().startOf('month');
        const endDate = moment().endOf('month');

        dispatch(requestCountUsersStatisticAction([startDate, endDate]));
    }, []);

    if (data === null) {
        return <Preloader />;
    }

    return (
        <Row gutter={30} className="dashboard__users">
            <Col span={24} lg={6}>
                <Card>
                    <Statistic
                        title="Новые пользователи"
                        value={data.new}
                        prefix={<UserAddOutlined />}
                    />
                </Card>
            </Col>
            <Col span={24} lg={6}>
                <Card>
                    <Statistic
                        title="Всего пользователей"
                        value={data.all}
                        prefix={<UserOutlined />}
                    />
                </Card>
            </Col>
        </Row>
    );
};
export default CountUsers;
