import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    Row,
    Col,
    Form,
    Input,
    Button
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import firebase from 'firebase';

import logo from '../../assets/logo.svg';

import { requestSignUpAction } from '../../store/actions/auth';
import { AppState } from '../../store';
import './style.scss';

const RegistrationPage:React.FC = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const auth = useSelector((state: AppState) => state.auth);
    const { isAuth, loading } = auth;

    const onFinish = (values: any) => {
        if (values.password !== values['password-retry']) {
            form.setFields([{
                name: 'password-retry',
                value: values['password-retry'],
                errors: ['Пароль не совпадает']
            }]);
        } else {
            const time = firebase.firestore.Timestamp.now();
            dispatch(requestSignUpAction({
                email: values.email,
                password: values.password,
                shortName: values.shortName,
                inn: values.inn,
                fio: values.fio,
                position: values.position,
                phone: values.phone,
                createdAt: time.seconds,
                updatedAt: time.seconds
            }));
        }
    };

    if (isAuth) {
        return <Redirect to="/" />;
    }

    return (
        <section className="auth register page">
            <Row justify="space-around" align="middle">
                <Col span={25} lg={5}>
                    <Form
                        form={form}
                        className="login-form"
                        onFinish={onFinish}
                        layout="vertical"
                    >
                        <Form.Item>
                            <img src={logo} alt="NSS" className="auth__logo" />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Введите email' }]}
                            label="E-mail"
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Введите пароль' }]}
                            label="Пароль"
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Пароль"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password-retry"
                            rules={[{ required: true, message: 'Повторите пароль' }]}
                            label="Повторите пароль"
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Повторите пароль"
                            />
                        </Form.Item>
                        <Form.Item
                            name="shortName"
                            rules={[{ required: true, message: 'Заполните поле' }]}
                            label="Краткое название компании"
                        >
                            <Input placeholder="Краткое название компании" />
                        </Form.Item>
                        <Form.Item
                            name="inn"
                            rules={[{ required: true, message: 'Заполните поле' }]}
                            label="ИНН компании"
                        >
                            <Input placeholder="ИНН компании" />
                        </Form.Item>
                        <Form.Item
                            name="fio"
                            rules={[{ required: true, message: 'Заполните поле' }]}
                            label="Ваше Ф.И.О."
                        >
                            <Input placeholder="Ваше Ф.И.О." />
                        </Form.Item>
                        <Form.Item
                            name="position"
                            rules={[{ required: true, message: 'Заполните поле' }]}
                            label="Ваша должность"
                        >
                            <Input placeholder="Ваша должность" />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                            rules={[{ required: true, message: 'Заполните поле' }]}
                            label="Телефон"
                        >
                            <Input placeholder="Телефон" />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                loading={loading}
                            >
                                Зарегистрироваться
                            </Button>
                        </Form.Item>
                    </Form>
                    <p className="register__footer">
                        Возникли проблемы? Напишите нам&nbsp;
                        <a href="mailto:support@zipnss.ru">support@zipnss.ru</a>
                    </p>
                </Col>
            </Row>
        </section>
    );
};

export default RegistrationPage;
