/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Table,
    Input,
    Button,
    Typography,
    Col,
    Row,
    Empty,
    InputNumber
} from 'antd';
import {
    DeleteOutlined, DeliveredProcedureOutlined, PlusCircleOutlined, SaveOutlined
} from '@ant-design/icons';

import './style.scss';
import firebase from '../../utils/firebase';
import Preloader from '../../components/Preloader';
import WrapperPage from '../../containers/WrapperPage';
import { EquipmentDetailItem, EquipmentItem } from '../../store/types/equipments';
import { AppState } from '../../store';
import {
    addEquipmentsAction,
    addEquipmentsDetailAction,
    deleteEquipmentsAction,
    deleteEquipmentsDetailAction,
    requestGetEquipmentsAction,
    requestSaveEquipmentsAction,
    resetFormEquipmentsAction,
    updateEquipmentsAction,
    updateEquipmentsDetailAction
} from '../../store/actions/equipments';
import { OrdersItemData } from '../../store/types/orders';
import { requestCreateOrderAction, requestGetOrderAction } from '../../store/actions/orders';
import OrdersList from '../Orders/List';
import notification from '../../utils/notification';

type ValuesEquipmentsType = {
    id: string,
    mark: string,
    serial: string,
    model: string,
    year: string
};

type ValuesDetailsType = {
    id: string,
    name: string,
    serial: string
};

const { Column } = Table;
const { Title } = Typography;

const EquipmentPage:React.FC = () => {
    const [expandsRows, changeExpand] = useState<readonly React.Key[]>([]);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const equipments = useSelector((state: AppState) => state.equipments);
    const auth = useSelector((state: AppState) => state.auth);
    const orders = useSelector((state: AppState) => state.orders);
    const { data, loading } = equipments;

    useEffect(() => {
        const fromCache = !!equipments.data;
        dispatch(requestGetEquipmentsAction(auth.company, fromCache));
    }, []);

    useEffect(() => {
        if (equipments.resetForm) {
            form.resetFields();
            dispatch(resetFormEquipmentsAction(false));
        }
    }, [equipments.resetForm]);

    const onSend = () => {
        const time = firebase.firestore.Timestamp.now();

        const sendData: OrdersItemData = {
            companyID: auth.company,
            order: [],
            updatedAt: time.seconds,
            createdAt: time.seconds
        };

        data?.forEach((item) => {
            sendData.order.push({
                equipmentID: item.id,
                equipmentCount: item.count,
                details: item.details.map((detail) => ({ id: detail.id, count: detail.count }))
            });
        });

        if (data === null || data.length === 0) {
            notification('warning', 'Пустой заказ', 'Добавте оборудование для создания заказа');
            return;
        }

        dispatch(requestCreateOrderAction(sendData));
    };

    const onSave = (values: any) => {
        const { createToServer, deleteFromServer } = equipments;
        let creatingEquipments: EquipmentItem[] | undefined = [];
        let creatingDetails: EquipmentDetailItem[] | undefined = [];
        let deletingEquipments: string[] = [];
        let deletingDetails: string[] = [];

        const valuesEquipments: ValuesEquipmentsType[] | undefined = data?.map((eq) => {
            let fieldValues: ValuesEquipmentsType = {
                id: '',
                mark: '',
                model: '',
                serial: '',
                year: ''
            };

            for (const key in values) {
                const fieldMeta = key.split('_');

                if (fieldMeta[0].indexOf('detail') === -1) {
                    const fieldID = fieldMeta[0];
                    const fieldName = fieldMeta[1];

                    if (fieldID === eq.id) {
                        fieldValues = {
                            ...fieldValues,
                            id: fieldID,
                            [fieldName]: values[key]
                        };
                    }
                }
            }

            return fieldValues;
        });
        let valuesDetails: ValuesDetailsType[] | undefined = [];
        data?.forEach((eq) => {
            eq.details.forEach((detail) => {
                let fieldValues: ValuesDetailsType = {
                    id: '',
                    name: '',
                    serial: ''
                };

                for (const key in values) {
                    const fieldMeta = key.split('_');

                    if (fieldMeta[0].indexOf('detail') !== -1) {
                        const fieldID = fieldMeta[0].split('-')[1];
                        const fieldName = fieldMeta[1];

                        if (fieldID === detail.id) {
                            fieldValues = {
                                ...fieldValues,
                                id: fieldID,
                                [fieldName]: values[key]
                            };
                        }
                    }
                }

                if (valuesDetails !== undefined) valuesDetails = [...valuesDetails, fieldValues];
            });
        });

        if (createToServer.equipmentsIDs.length) {
            creatingEquipments = data?.filter((eq) => createToServer.equipmentsIDs.includes(eq.id));
            creatingEquipments = creatingEquipments?.map((eq) => {
                const newValues = valuesEquipments?.filter((eqValues) => eqValues.id === eq.id);
                if (newValues !== undefined && newValues.length) {
                    return {
                        ...eq,
                        ...newValues[0]
                    };
                }
                return eq;
            });
        }
        if (createToServer.detailsIDs.length) {
            data?.forEach((eq) => {
                let details = eq.details.filter((det) => createToServer.detailsIDs.includes(det.id));
                details = details?.map((det) => {
                    const newValues = valuesDetails?.filter((detValues) => detValues.id === det.id);
                    if (newValues !== undefined && newValues.length) {
                        return {
                            ...det,
                            ...newValues[0]
                        };
                    }
                    return det;
                });
                if (creatingDetails !== undefined && details.length) creatingDetails = [...creatingDetails, ...details];
            });
        }
        if (deleteFromServer.equipmentsIDs.length) {
            deletingEquipments = deleteFromServer.equipmentsIDs;
        }
        if (deleteFromServer.detailsIDs.length) {
            deletingDetails = deleteFromServer.detailsIDs;
        }

        dispatch(requestSaveEquipmentsAction(creatingEquipments, creatingDetails, deletingEquipments, deletingDetails));
        onSend();
    };

    const onAddEquipmentsDetail = (recordID: string) => {
        const rowID = `equipment_${recordID}`;
        if (!expandsRows.includes(rowID)) changeExpand([...expandsRows, rowID]);
        dispatch(addEquipmentsDetailAction(recordID));
    };

    if (data === null) {
        return <Preloader />;
    }

    let initialValues = {};
    data.forEach((equip) => {
        const valuesEquipments = {
            [`${equip.id}_mark`]: equip.mark,
            [`${equip.id}_model`]: equip.model,
            [`${equip.id}_serial`]: equip.serial,
            [`${equip.id}_year`]: equip.year,
            [`${equip.id}_build`]: equip.build,
            [`${equip.id}_count`]: equip.count
        };

        // const fieldsDataEq: FieldData[] = [
        //     {
        //         name: [`${equip.id}_mark`],
        //         value: equip.mark
        //     },
        //     {
        //         name: [`${equip.id}_model`],
        //         value: equip.model
        //     },
        //     {
        //         name: [`${equip.id}_serial`],
        //         value: equip.serial
        //     },
        //     {
        //         name: [`${equip.id}_year`],
        //         value: equip.year
        //     },
        //     {
        //         name: [`${equip.id}_count`],
        //         value: equip.count
        //     }
        // ];
        // form.setFields(fieldsDataEq);

        let valuesDetails = {};
        equip.details.forEach((detail) => {
            valuesDetails = {
                ...valuesDetails,
                [`detail-${detail.id}_name`]: detail.name,
                [`detail-${detail.id}_serial`]: detail.serial,
                [`detail-${detail.id}_count`]: detail.count
            };

            // const fieldsDataDet: FieldData[] = [
            //     {
            //         name: [`detail-${detail.id}_name`],
            //         value: detail.name
            //     },
            //     {
            //         name: [`detail-${detail.id}_serial`],
            //         value: detail.serial
            //     },
            //     {
            //         name: [`detail-${detail.id}_count`],
            //         value: detail.count
            //     }
            // ];
            // form.setFields(fieldsDataDet);
        });

        if (equip.details.length) {
            initialValues = { ...initialValues, ...valuesEquipments, ...valuesDetails };
        } else {
            initialValues = { ...initialValues, ...valuesEquipments };
        }
    });

    return (
        <div className="equipments page">
            <Title level={4}>Список оборудования</Title>
            <div className="equipments__actions">
                <Col span={24}>
                    <Row justify="end">
                        <Button
                            type="primary"
                            size="large"
                            icon={<PlusCircleOutlined />}
                            onClick={() => dispatch(addEquipmentsAction(auth.company))}
                        >
                            Добавить оборудование
                        </Button>
                    </Row>
                </Col>
            </div>
            <Form
                form={form}
                initialValues={initialValues}
                onFinish={onSave}
            >
                <Table
                    dataSource={data}
                    pagination={false}
                    rowKey={((record) => `equipment_${record.id}`)}
                    expandable={{
                        expandedRowKeys: expandsRows,
                        onExpandedRowsChange: ((rowKeys) => changeExpand(rowKeys)),
                        expandedRowRender: (equipment: EquipmentItem) => (
                            <Table
                                dataSource={equipment.details}
                                pagination={false}
                                rowKey={((detail) => `detail_${detail.id}`)}
                            >
                                <Column
                                    title="Название детали"
                                    dataIndex="name"
                                    key="name"
                                    width="39.3%"
                                    render={(value, detail: EquipmentDetailItem) => (
                                        <Form.Item name={`detail-${detail.id}_name`}>
                                            <Input
                                                name={`detail-${detail.id}_name`}
                                                placeholder="Название детали"
                                                onChange={(e) => dispatch(updateEquipmentsDetailAction(detail.id, { name: e.currentTarget.value }))}
                                            />
                                        </Form.Item>
                                    )}
                                />
                                <Column
                                    title="Серийный номер"
                                    dataIndex="serial"
                                    key="serial"
                                    width="39.3%"
                                    render={(value, detail: EquipmentDetailItem) => (
                                        <Form.Item name={`detail-${detail.id}_serial`}>
                                            <Input
                                                name={`detail-${detail.id}_serial`}
                                                placeholder="Серийный номер"
                                                onChange={(e) => dispatch(updateEquipmentsDetailAction(detail.id, { serial: e.currentTarget.value }))}
                                            />
                                        </Form.Item>
                                    )}
                                />
                                <Column
                                    title="Учреждение"
                                    dataIndex="build"
                                    key="build"
                                    width="39.3%"
                                    render={(value, detail: EquipmentDetailItem) => (
                                        <Form.Item name={`detail-${detail.id}_build`}>
                                            <Input
                                                name={`detail-${detail.id}_build`}
                                                placeholder="Учреждение"
                                                onChange={(e) => dispatch(updateEquipmentsDetailAction(detail.id, { build: e.currentTarget.value }))}
                                            />
                                        </Form.Item>
                                    )}
                                />
                                <Column
                                    title="Кол-во"
                                    dataIndex="count"
                                    key="count"
                                    width="39.3%"
                                    render={(value, detail: EquipmentDetailItem) => (
                                        <Form.Item name={`detail-${detail.id}_count`}>
                                            <InputNumber
                                                name={`detail-${detail.id}_count`}
                                                placeholder="Кол-во"
                                                min={1}
                                                onChange={(val) => dispatch(updateEquipmentsDetailAction(detail.id, { count: +val }))}
                                            />
                                        </Form.Item>
                                    )}
                                />
                                <Column
                                    key="actions"
                                    render={(value, detail: EquipmentDetailItem) => (
                                        <Button
                                            type="primary"
                                            icon={<DeleteOutlined />}
                                            danger
                                            onClick={() => dispatch(deleteEquipmentsDetailAction(equipment.id, detail.id))}
                                        />
                                    )}
                                />
                            </Table>
                        ),
                        rowExpandable: (record: EquipmentItem) => !!record.details.length
                    }}
                    locale={{
                        emptyText: <Empty description="Нет данных" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }}
                >
                    <Column
                        title="Марка"
                        dataIndex="mark"
                        key="mark"
                        width="21%"
                        render={(value, record: EquipmentItem) => (
                            <Form.Item name={`${record.id}_mark`}>
                                <Input
                                    name={`${record.id}_mark`}
                                    placeholder="Марка"
                                    onChange={(e) => dispatch(updateEquipmentsAction(record.id, { mark: e.currentTarget.value }))}
                                />
                            </Form.Item>
                        )}
                    />
                    <Column
                        title="Модель"
                        dataIndex="model"
                        key="model"
                        width="21%"
                        render={(value, record: EquipmentItem) => (
                            <Form.Item name={`${record.id}_model`}>
                                <Input
                                    name={`${record.id}_model`}
                                    placeholder="Модель"
                                    onChange={(e) => dispatch(updateEquipmentsAction(record.id, { model: e.currentTarget.value }))}
                                />
                            </Form.Item>
                        )}
                    />
                    <Column
                        title="Серийный номер"
                        dataIndex="serial"
                        key="serial"
                        width="21%"
                        render={(value, record: EquipmentItem) => (
                            <Form.Item name={`${record.id}_serial`}>
                                <Input
                                    name={`${record.id}_serial`}
                                    placeholder="Серийный номер"
                                    onChange={(e) => dispatch(updateEquipmentsAction(record.id, { serial: e.currentTarget.value }))}
                                />
                            </Form.Item>
                        )}
                    />
                    <Column
                        title="Год"
                        dataIndex="year"
                        key="year"
                        width="10%"
                        render={(value, record: EquipmentItem) => (
                            <Form.Item name={`${record.id}_year`}>
                                <Input
                                    name={`${record.id}_year`}
                                    placeholder="Год"
                                    onChange={(e) => dispatch(updateEquipmentsAction(record.id, { year: e.currentTarget.value }))}
                                />
                            </Form.Item>
                        )}
                    />
                    <Column
                        title="Учреждение"
                        dataIndex="build"
                        key="build"
                        width="21%"
                        render={(value, record: EquipmentItem) => (
                            <Form.Item name={`${record.id}_build`}>
                                <Input
                                    name={`${record.id}_build`}
                                    placeholder="Учреждение"
                                    onChange={(e) => dispatch(updateEquipmentsAction(record.id, { build: e.currentTarget.value }))}
                                />
                            </Form.Item>
                        )}
                    />
                    <Column
                        title="Кол-во"
                        dataIndex="count"
                        key="count"
                        width="10%"
                        render={(value, record: EquipmentItem) => (
                            <Form.Item name={`${record.id}_count`}>
                                <InputNumber
                                    name={`${record.id}_count`}
                                    placeholder="Кол-во"
                                    onChange={(val) => dispatch(updateEquipmentsAction(record.id, { count: +val }))}
                                />
                            </Form.Item>
                        )}
                    />
                    <Column
                        key="actions"
                        render={(value, record: EquipmentItem) => (
                            <>
                                <Button
                                    type="primary"
                                    icon={<PlusCircleOutlined />}
                                    onClick={() => onAddEquipmentsDetail(record.id)}
                                />
                                <Button
                                    type="primary"
                                    icon={<DeleteOutlined />}
                                    danger
                                    onClick={() => dispatch(deleteEquipmentsAction(record))}
                                />
                            </>
                        )}
                    />
                </Table>
                <Col span={24}>
                    <Row justify="end">
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            icon={<DeliveredProcedureOutlined />}
                            loading={loading || orders.loading}
                        >
                            Отправить заказ
                        </Button>
                        {/* <Button
                            type="primary"
                            size="large"
                            icon={<DeliveredProcedureOutlined />}
                            loading={loading || orders.loading}
                            onClick={() => onSend()}
                            style={{ marginLeft: '1rem' }}
                        >
                            Отправить заказ
                        </Button> */}
                    </Row>
                </Col>
            </Form>
            <Title level={4}>История заказов</Title>
            <OrdersList companyID={auth.company as string} />
        </div>
    );
};

export default WrapperPage(EquipmentPage);
