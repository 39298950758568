/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Table
} from 'antd';
import {
    EyeOutlined, RedoOutlined
} from '@ant-design/icons';
import moment from 'moment';

import '../style.scss';
import { AppState } from '../../../store';
import Preloader from '../../../components/Preloader';
import ViewOrder from '../View';
import {
    requestGetOrderAction,
    viewingOrdersAction
} from '../../../store/actions/orders';
import { OrdersItem } from '../../../store/types/orders';
import { EquipmentItem } from '../../../store/types/equipments';
import { setEquipmentsTemplateAction } from '../../../store/actions/equipments';

interface ListOrdersProps {
    companyID?: string
}

const { Column } = Table;

const ListOrders:React.FC<ListOrdersProps> = (props: ListOrdersProps) => {
    const dispatch = useDispatch();
    const orders = useSelector((state: AppState) => state.orders);
    const {
        data, companies, equipments, details
    } = orders;

    useEffect(() => {
        if (props.companyID !== undefined) {
            dispatch(requestGetOrderAction(props.companyID));
        } else {
            dispatch(requestGetOrderAction());
        }
    }, []);

    const repeatOrder = (orderID: string) => {
        let repeatOrderData: EquipmentItem[] = [];
        if (orders.data) {
            const order = orders.data?.filter((item) => item.id === orderID)[0];

            repeatOrderData = order.order.map((eq) => {
                const newEq: EquipmentItem | undefined = equipments?.filter((item) => item.id === eq.equipmentID)[0];

                if (newEq !== undefined) {
                    newEq.count = eq.equipmentCount;

                    if (details) {
                        newEq.details = eq.details.map((det) => {
                            const newDet = details.filter((item) => det.id === item.id)[0];
                            newDet.count = det.count;
                            return newDet;
                        });
                    }
                }

                const emptyEq: EquipmentItem = {
                    companyID: '',
                    count: 1,
                    details: [],
                    id: '',
                    mark: '',
                    model: '',
                    serial: '',
                    updatedAt: 0,
                    year: '',
                    build: '',
                    createdAt: 0
                };
                return newEq !== undefined ? newEq : emptyEq;
            });
        }

        dispatch(setEquipmentsTemplateAction(repeatOrderData));
    };

    if (data === null) {
        return <Preloader />;
    }

    return (
        <>
            <Table
                dataSource={data}
                pagination={false}
                rowKey={((record) => `orders_${record.id}`)}
            >
                {props.companyID === undefined ? (
                    <Column
                        title="Компания"
                        dataIndex="company"
                        key="company"
                        width="68%"
                        render={(value, record: OrdersItem) => {
                            if (record.companyID) {
                                const company = companies?.filter((item) => item.id === record.companyID);
                                if (company?.length) return company[0].shortName;
                                return '- Компания удалена -';
                            }
                            return '';
                        }}
                    />
                ) : null}
                <Column
                    title="Дата заказа"
                    dataIndex="fio"
                    key="fio"
                    width={props.companyID === undefined ? '20%' : '85%'}
                    render={(value, record: OrdersItem) => (moment(record.createdAt * 1000).format('DD.MM.YYYY HH:mm'))}
                />
                <Column
                    key="actions"
                    render={(value, record: OrdersItem) => (
                        <>
                            {props.companyID !== undefined ? (
                                <Button
                                    icon={<RedoOutlined />}
                                    onClick={() => repeatOrder(record.id)}
                                />
                            ) : null}
                            <Button
                                icon={<EyeOutlined />}
                                onClick={() => dispatch(viewingOrdersAction(record.id))}
                            />
                        </>
                    )}
                />
            </Table>
            <ViewOrder />
        </>
    );
};

export default ListOrders;
