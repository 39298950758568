import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Button,
    Form,
    Input
} from 'antd';
import { RequiredMark } from 'antd/lib/form/Form';
import firebase from '../../../utils/firebase';

import { AppState } from '../../../store';
import { creatingOffersAction, requestAddOffersAction } from '../../../store/actions/offers';

const { TextArea } = Input;

const Add:React.FC = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [requiredMark] = useState<RequiredMark>(true);
    const offers = useSelector((state: AppState) => state.offers);
    const auth = useSelector((state: AppState) => state.auth);
    const { creating, loading } = offers;
    const { company } = auth;

    const onSave = (values: any) => {
        const time = firebase.firestore.Timestamp.now();

        dispatch(requestAddOffersAction({
            companyID: company || '',
            text: values.text,
            createdAt: time.seconds,
            updatedAt: time.seconds
        }));
        form.resetFields();
    };

    const onClose = () => {
        dispatch(creatingOffersAction());
    };

    return (
        <Modal
            title="Запрос КП"
            centered
            visible={creating}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose} loading={loading}>
                    Отменить
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
                    Отправить
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                requiredMark={requiredMark}
                onFinish={onSave}
            >
                <Form.Item label="Данные КП" name="text" tooltip="Кратко опишите, что вы хотите увидеть в КП" required>
                    <TextArea placeholder="Что хотите в КП?" autoSize={{ minRows: 5, maxRows: 5 }} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Add;
