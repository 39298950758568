import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Row,
    Col
} from 'antd';

import './style.scss';
import WrapperPage from '../../containers/WrapperPage';
import { requestGetChatsAction } from '../../store/actions/chat';
import { AppState } from '../../store';
import Preloader from '../../components/Preloader';
import ListChats from './ListChats';
import OneChat from './OneChat';

const isMobile = window.matchMedia('(max-width: 991px)').matches;

const ChatPage:React.FC = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state: AppState) => state.auth);
    const chats = useSelector((state: AppState) => state.chat);
    const { data, currentChat } = chats;

    if (data === null) {
        return <Preloader />;
    }

    if (isMobile) {
        return (
            <div className="page chat">
                <Row gutter={15}>
                    {auth.account !== 'client' && currentChat === null && (
                        <Col className="chat__left" span={24} lg={8}>
                            <ListChats />
                        </Col>
                    )}
                    {auth.account === 'client' ? (
                        <Col className="chat__right" span={24}>
                            <OneChat />
                        </Col>
                    ) : null}
                    {auth.account !== 'client' && currentChat ? (
                        <Col className="chat__right" span={24}>
                            <OneChat />
                        </Col>
                    ) : null}
                </Row>
            </div>
        );
    }

    return (
        <div className="page chat">
            <Row gutter={15}>
                {auth.account !== 'client' && (
                    <Col className="chat__left" span={24} lg={8}>
                        <ListChats />
                    </Col>
                )}
                <Col className="chat__right" span={24} lg={auth.account !== 'client' ? 16 : 24}>
                    <OneChat />
                </Col>
            </Row>
        </div>
    );
};

export default WrapperPage(ChatPage);
