import { call, put, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import moment from 'moment';

import firebase, { db, auth } from '../../utils/firebase';
import generateID from '../../utils/generateID';
import notification from '../../utils/notification';
import {
    errorAuthAction,
    responseSignInAction,
    responseSignOutAction,
    responseSignUpAction,
    subscribeNewAuthAction
} from '../actions/auth';
import { subscribeOrdersAction } from '../actions/orders';
import { subscribeOffersAction } from '../actions/offers';
import { addNotifyAction, getNotifyAction } from '../actions/notifications';
import { RequestSignInActionType, AuthActionsList, RequestSignUpActionType } from '../types/auth';
import store from '..';

function subscribeNewAuth(ref: firebase.firestore.Query) {
    return eventChannel((emmiter: any) => {
        ref.onSnapshot((data) => {
            if (!data.empty) {
                data.docChanges().forEach((user) => {
                    const dataUser = user.doc.data();
                    if (dataUser.createdAt !== undefined && (+moment().format('X') - dataUser.createdAt.seconds) <= 10) {
                        store.dispatch(addNotifyAction({
                            companyID: dataUser.company,
                            companyName: '',
                            date: dataUser.createdAt.seconds,
                            type: 'registration'
                        }));
                    }
                });
            }
        });

        return () => ref;
    });
}

function* signInWorker(effect: RequestSignInActionType) {
    let isAuth = false;

    if (effect.login && effect.password) {
        try {
            const loginOnlyNumbers = effect.login.match('^\\d+$');
            if (loginOnlyNumbers && (loginOnlyNumbers[0].length === 10 || loginOnlyNumbers[0].length === 12)) {
                const inn = effect.login;
                const company: firebase.firestore.QuerySnapshot = yield db.collection('companies').where('inn', '==', inn).get();
                if (!company.empty) {
                    const companyID = company.docs[0].id;
                    const user: firebase.firestore.QuerySnapshot = yield db.collection('users').where('company', '==', companyID).get();
                    if (!user.empty) {
                        const userData = user.docs[0].data();

                        if (userData.uid) {
                            yield call(
                                [auth, auth.signInWithEmailAndPassword],
                                userData.email,
                                effect.password
                            );

                            isAuth = true;
                            yield put(responseSignInAction(user.docs[0].id, userData.uid, userData.email, userData.type, companyID));

                            if (userData.type === 'admin' || userData.type === 'manager') {
                                yield put(getNotifyAction());
                                yield put(subscribeOrdersAction());
                                yield put(subscribeOffersAction());
                                yield put(subscribeNewAuthAction());
                            }
                        } else {
                            notification('error', 'Ошибка', 'Пользователь не существует или был удалён.');
                            yield put(errorAuthAction('Пользователь не существует или был удалён.'));
                        }
                    } else {
                        notification('error', 'Ошибка', 'Пользователь не существует или был удалён.');
                        yield put(errorAuthAction('Пользователь не существует или был удалён.'));
                    }
                } else {
                    notification('error', 'Ошибка', 'Компании не существует.');
                    yield put(errorAuthAction('Компании не существует.'));
                }
            } else {
                const resultAuth: firebase.auth.UserCredential = yield call(
                    [auth, auth.signInWithEmailAndPassword],
                    effect.login,
                    effect.password
                );

                const uid = resultAuth.user ? resultAuth.user.uid : null;

                const users = db.collection('users');
                const userSnapshot: firebase.firestore.QuerySnapshot = yield users.where('uid', '==', uid).get();

                if (!userSnapshot.empty) {
                    const user = userSnapshot.docs[0].data();
                    const companyID = user.type === 'client' ? user.company : null;

                    if (user.uid) {
                        isAuth = true;
                        yield put(responseSignInAction(userSnapshot.docs[0].id, uid, user.email, user.type, companyID));

                        if (user.type === 'admin' || user.type === 'manager') {
                            yield put(getNotifyAction());
                            yield put(subscribeOrdersAction());
                            yield put(subscribeOffersAction());
                            yield put(subscribeNewAuthAction());
                        }
                    } else {
                        notification('error', 'Ошибка', 'Пользователь не существует или был удалён.');
                        yield auth.signOut();
                        yield put(errorAuthAction('Пользователь не существует или был удалён.'));
                    }
                } else {
                    notification('error', 'Ошибка', 'Пользователь не существует или был удалён.');
                    yield auth.signOut();
                    yield put(errorAuthAction('Пользователь не существует или был удалён.'));
                }
            }
        } catch (e: any) {
            const error: firebase.FirebaseError = e;

            switch (error.code) {
                case 'auth/wrong-password':
                    notification('error', 'Ошибка', 'Неверное имя пользователя или пароль.');
                    yield put(errorAuthAction('Неверное имя пользователя или пароль.'));
                    break;
                case 'auth/too-many-requests':
                    notification('error', 'Ошибка', 'Слишком много неверных попыток входа, попробуйте позже.');
                    yield put(errorAuthAction('Слишком много неверных попыток входа, попробуйте позже.'));
                    break;
                default:
                    notification('error', 'Ошибка', 'Произошла неизвестная ошибка.');
                    yield put(errorAuthAction('Произошла неизвестная ошибка.'));
            }

            console.log(error);
        }
    } else if (effect.uid) {
        const users = db.collection('users');
        const userSnapshot: firebase.firestore.QuerySnapshot = yield users.where('uid', '==', effect.uid).get();

        if (!userSnapshot.empty) {
            const user = userSnapshot.docs[0].data();
            const companyID = user.type === 'client' ? user.company : null;

            yield put(responseSignInAction(userSnapshot.docs[0].id, effect.uid, user.email, user.type, companyID));

            if (user.type === 'admin' || user.type === 'manager') {
                yield put(getNotifyAction());
                yield put(subscribeOrdersAction());
                yield put(subscribeOffersAction());
                yield put(subscribeNewAuthAction());
            }
        } else {
            notification('error', 'Ошибка', 'Пользователь не существует или был удалён.');
            yield auth.signOut();
            yield put(errorAuthAction('Пользователь не существует или был удалён.'));
        }
    }

    if (isAuth) {
        yield db.collection('statistics').doc(generateID()).set({
            action: 'signin',
            createdAt: firebase.firestore.Timestamp.fromDate(new Date())
        });
    }
}

function* signOutWorker() {
    yield call([auth, auth.signOut]);
    yield put(responseSignOutAction());
}

function* signUpWorker(effect: RequestSignUpActionType) {
    const userID = generateID();
    const companyID = generateID();

    try {
        const newAuth: firebase.auth.UserCredential = yield auth.createUserWithEmailAndPassword(effect.data.email, effect.data.password);
        const UID = newAuth.user?.uid;

        if (UID !== undefined) {
            yield db.collection('users').doc(userID).set({
                company: companyID,
                email: effect.data.email,
                fio: effect.data.fio,
                phone: effect.data.phone,
                position: effect.data.position,
                type: 'client',
                uid: UID,
                createdAt: firebase.firestore.Timestamp.fromMillis(effect.data.createdAt * 1000),
                updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.updatedAt * 1000)
            });
            yield db.collection('companies').doc(companyID).set({
                inn: effect.data.inn,
                shortName: effect.data.shortName,
                fullName: '',
                kpp: '',
                ogrn: '',
                bankBIK: '',
                bankName: '',
                paymentNumber: '',
                сorrespondentNumber: '',
                createdAt: firebase.firestore.Timestamp.fromMillis(effect.data.createdAt * 1000),
                updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.updatedAt * 1000)
            });
            yield db.collection('chats').doc(`chat-${companyID}`).set({
                companyID,
                members: [userID],
                createdAt: firebase.firestore.Timestamp.fromMillis(effect.data.createdAt * 1000)
            });
            yield put(responseSignUpAction(userID, UID, effect.data.email, 'client', companyID));
        }
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorAuthAction('Не удалось завершить регистрацию'));
        notification('error', 'Ошибка', 'Не удалось завершить регистрацию');
        console.log(error);
    }
}

function* subscribeNewAuthWorker() {
    const usersRef = db.collection('users');
    yield subscribeNewAuth(usersRef);
}

export function* authWatcher() {
    yield takeEvery(AuthActionsList.REQUEST_SIGNIN_AUTH, signInWorker);
    yield takeEvery(AuthActionsList.REQUEST_SIGNOUT_AUTH, signOutWorker);
    yield takeEvery(AuthActionsList.REQUEST_SIGNUP_AUTH, signUpWorker);
    yield takeEvery(AuthActionsList.SUBSCRIBE_NEW_AUTH, subscribeNewAuthWorker);
}
