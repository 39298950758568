export interface CompaniesItemData {
    bankBIK: string,
    bankName: string,
    fullName: string,
    inn: string,
    kpp: string,
    ogrn: string,
    paymentNumber: string,
    shortName: string,
    сorrespondentNumber: string,
    createdAt?: number,
    updatedAt: number
}

export interface CompaniesItem extends CompaniesItemData {
    id: string
}

export interface CompaniesItemShort {
    id: string,
    fullName: string,
    shortName: string
}

export interface CompaniesStateType {
    loading: boolean,
    data: CompaniesItem[] | null,
    error: string | null
}

export enum CompaniesActionsList {
    REQUEST_GET_COMPANIES = 'REQUEST_GET_COMPANIES',
    RESPONSE_GET_COMPANIES = 'RESPONSE_GET_COMPANIES',
    REQUEST_UPDATE_COMPANIES = 'REQUEST_UPDATE_COMPANIES',
    RESPONSE_UPDATE_COMPANIES = 'RESPONSE_UPDATE_COMPANIES',
    ERROR_COMPANIES = 'ERROR_COMPANIES',
    RESPONSE_SIGNOUT_AUTH = 'RESPONSE_SIGNOUT_AUTH'
}

export interface ErrorCompaniesActionType {
    type: CompaniesActionsList.ERROR_COMPANIES,
    error: string
}

export interface RequestGetCompaniesActionType {
    type: CompaniesActionsList.REQUEST_GET_COMPANIES,
    companyID: string | null
}

export interface ResponseGetCompaniesActionType {
    type: CompaniesActionsList.RESPONSE_GET_COMPANIES,
    data: CompaniesItem[]
}

export interface RequestUpdateCompaniesActionType {
    type: CompaniesActionsList.REQUEST_UPDATE_COMPANIES,
    companyID: string,
    data: CompaniesItemData
}

export interface ResponseUpdateCompaniesActionType {
    type: CompaniesActionsList.RESPONSE_UPDATE_COMPANIES,
    companyID: string,
    data: CompaniesItemData
}

export interface ResponseSignOutActionType {
    type: CompaniesActionsList.RESPONSE_SIGNOUT_AUTH
}

export type CompaniesActionsType =
ErrorCompaniesActionType
| RequestGetCompaniesActionType
| ResponseGetCompaniesActionType
| RequestUpdateCompaniesActionType
| ResponseUpdateCompaniesActionType
| ResponseSignOutActionType;
