import generateNotification from '../../utils/generateNotification';
import notification from '../../utils/notification';
import {
    NotificationsActionsList,
    NotificationsActionsType,
    NotificationItem,
    NotificationsStateType
} from '../types/notifications';

export const initialState: NotificationsStateType = {
    companies: null,
    data: [],
    loading: false,
    error: null
};

function playSoundNotification() {
    const snd = new Audio('/assets/notify_sound.mp3');
    snd.oncanplaythrough = () => {
        const playedPromise = snd.play();
        if (playedPromise) {
            playedPromise.catch((e) => {
                console.log(e);
                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                    console.log(e.name);
                }
            });
        }
    };
}

const companiesReducer = (state: NotificationsStateType = initialState, action: NotificationsActionsType) : NotificationsStateType => {
    switch (action.type) {
        case NotificationsActionsList.REQUEST_ADDITIONAL_DATA_NOTIFY: {
            return {
                ...state,
                loading: true
            };
        }
        case NotificationsActionsList.RESPONSE_ADDITIONAL_DATA_NOTIFY: {
            return {
                ...state,
                loading: false,
                companies: action.data
            };
        }
        case NotificationsActionsList.ERROR_NOTIFY: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case NotificationsActionsList.GET_NOTIFY: {
            let localData: NotificationItem[] = [];
            if (localStorage.getItem('notifications')) {
                localData = JSON.parse(localStorage.getItem('notifications') as string);
            } else {
                localStorage.setItem('notifications', JSON.stringify(localData));
            }

            return {
                ...state,
                data: localData
            };
        }
        case NotificationsActionsList.ADD_NOTIFY: {
            const company = state.companies?.find((item) => item.id === action.data.companyID);
            const newNotify: NotificationItem = {
                ...action.data,
                companyName: company !== undefined ? company.shortName : ''
            };
            const data: NotificationItem[] = [...state.data, newNotify];
            localStorage.setItem('notifications', JSON.stringify(data));
            notification('info', 'Оповещение', generateNotification(newNotify));
            playSoundNotification();
            return {
                ...state,
                data
            };
        }
        case NotificationsActionsList.REMOVE_NOTIFY: {
            localStorage.setItem('notifications', JSON.stringify([]));
            return {
                ...state,
                data: []
            };
        }
        default:
            return state;
    }
};

export default companiesReducer;
