import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import { auth as authFirebase } from './utils/firebase';
import PrivateRoute from './components/PrivateRoute';
import routes from './router';
import { requestSignInAction } from './store/actions/auth';
import { AppState } from './store';
import Preloader from './components/Preloader';
import { requestGetChatsAction } from './store/actions/chat';

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const App:React.FC = () => {
    const auth = useSelector((state: AppState) => state.auth);
    const { uid, isAuth, id } = auth;
    const dispatch = useDispatch();

    useEffect(() => {
        authFirebase.onAuthStateChanged((user) => {
            if (user && uid) {
                dispatch(requestSignInAction(null, null, user.uid));
            }
        });

        if (!localStorage.getItem('manualReaded')) {
            localStorage.setItem('manualReaded', 'false');
        }
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(requestGetChatsAction(id));
        }
    }, [id]);

    if (uid && !isAuth) {
        return (<Preloader fullscreen />);
    }

    const path = window.location.pathname.split('/')[1];

    return (
        <div className={`app ${path}`}>
            <BrowserRouter>
                <Switch>
                    {routes.map((route) => (
                        route.private ? (
                            <PrivateRoute
                                key={route.path}
                                path={route.path}
                                component={route.component}
                                permisions={route.permissions}
                                exact
                            />
                        ) : (
                            <Route
                                key={route.path}
                                path={route.path}
                                component={route.component}
                                exact
                            />
                        )
                    ))}
                    <Redirect to="/404" />
                </Switch>
            </BrowserRouter>
        </div>
    );
};

export default App;
