import { put, takeEvery } from 'redux-saga/effects';

import firebase, { db } from '../../utils/firebase';
import notification from '../../utils/notification';
import {
    errorCompaniesAction,
    responseGetCompaniesAction,
    responseUpdateCompaniesAction
} from '../actions/companies';
import {
    CompaniesActionsList,
    CompaniesItem,
    RequestGetCompaniesActionType,
    RequestUpdateCompaniesActionType
} from '../types/companies';

function* getCompaniesWorker(effect: RequestGetCompaniesActionType) {
    if (effect.companyID) {
        try {
            const companies = db.collection('companies').doc(effect.companyID);
            const companiesSnapshot: firebase.firestore.DocumentSnapshot = yield companies.get();

            if (companiesSnapshot.exists) {
                const data = { ...companiesSnapshot.data(), id: effect.companyID } as CompaniesItem;
                yield put(responseGetCompaniesAction([data]));
            }
        } catch (e: any) {
            const error: firebase.FirebaseError = e;
            yield put(errorCompaniesAction('Не удалось загрузить данные'));
            notification('error', 'Ошибка', 'Не удалось загрузить данные');
            console.log(error);
        }
    }
}

function* updateCompaniesWorker(effect: RequestUpdateCompaniesActionType) {
    const time = firebase.firestore.Timestamp.now();

    try {
        const company = db.collection('companies').doc(effect.companyID);
        yield company.update({
            ...effect.data,
            updatedAt: time
        });

        yield put(responseUpdateCompaniesAction(effect.companyID, {
            ...effect.data,
            updatedAt: time.seconds
        }));
        notification('success', 'Успех', 'Данные успешно обновлены');
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorCompaniesAction('Не удалось обновить данные'));
        notification('error', 'Ошибка', 'Не удалось обновить данные');
        console.log(error);
    }
}

export function* companiesWatcher() {
    yield takeEvery(CompaniesActionsList.REQUEST_GET_COMPANIES, getCompaniesWorker);
    yield takeEvery(CompaniesActionsList.REQUEST_UPDATE_COMPANIES, updateCompaniesWorker);
}
