import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';
import { rootWatcher } from './sagas';

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = createStore(
    rootReducer,
    initialState,
    window.location.hostname === 'localhost' ? composeWithDevTools(applyMiddleware(...middleware)) : compose(applyMiddleware(...middleware))
);

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;

export default store;

sagaMiddleware.run(rootWatcher);
