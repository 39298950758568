import React from 'react';
import WrapperPage from '../../containers/WrapperPage';

const MainPage:React.FC = () => {
    return (
        <div>
            <p>main page</p>
        </div>
    );
};

export default WrapperPage(MainPage);
