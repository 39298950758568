import {
    OrdersActionsList,
    OrdersActionsType,
    OrdersStateType
} from '../types/orders';

export const initialState: OrdersStateType = {
    loading: false,
    data: null,
    companies: null,
    equipments: null,
    details: null,
    viewing: null,
    error: null
};

const ordersReducer = (state: OrdersStateType = initialState, action: OrdersActionsType) : OrdersStateType => {
    switch (action.type) {
        case OrdersActionsList.ERROR_ORDERS: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case OrdersActionsList.VIEWING_ORDERS: {
            return {
                ...state,
                viewing: action.orderID !== null ? {
                    orderID: action.orderID
                } : null
            };
        }
        case OrdersActionsList.REQUEST_CREATE_ORDERS:
        case OrdersActionsList.REQUEST_GET_ORDERS: {
            return {
                ...state,
                loading: true
            };
        }
        case OrdersActionsList.RESPONSE_GET_ORDERS: {
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        case OrdersActionsList.RESPONSE_CREATE_ORDERS: {
            return {
                ...state,
                loading: false,
                data: state.data && [action.data, ...state.data]
            };
        }
        case OrdersActionsList.SET_ADDITIONAL_DATA_ORDERS: {
            return {
                ...state,
                companies: action.companies,
                equipments: action.equipments,
                details: action.details
            };
        }
        case OrdersActionsList.RESPONSE_SIGNOUT_AUTH: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default ordersReducer;
