import React from 'react';
import {
    Row,
    Col,
    Typography,
    Statistic,
    Card,
    Space,
    Table,
    Tag,
    DatePicker,
    Dropdown,
    Button,
    Menu
} from 'antd';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import './style.scss';
import {
    ArrowUpOutlined, DownloadOutlined, FileExcelOutlined, FilePdfOutlined, UserAddOutlined, UserOutlined
} from '@ant-design/icons';
import firebase from '../../utils/firebase';

import WrapperPage from '../../containers/WrapperPage';
import Preloader from '../../components/Preloader';
import CountUsers from './CountUsers';
import CountSignIn from './CountSignIn';
import CountRegistration from './CountRegistration';
import PopularEquipments from './PopularEquipments';
import PopularDetails from './PopularDetails';

const { Title } = Typography;
const { Column, ColumnGroup } = Table;
const { RangePicker } = DatePicker;

const DashboardPage:React.FC = () => {
// if (data === null) {
//     return <Preloader />;
// }

    const actions = (
        <Menu>
            <Menu.Item>
                <FilePdfOutlined />
                &nbsp;
                PDF
            </Menu.Item>
            <Menu.Item>
                <FileExcelOutlined />
                &nbsp;
                EXCEL
            </Menu.Item>
        </Menu>
    );

    const dataPopular = [
        {
            key: '1',
            firstName: 'John',
            lastName: 'Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
            tags: ['nice', 'developer']
        },
        {
            key: '2',
            firstName: 'Jim',
            lastName: 'Green',
            age: 42,
            address: 'London No. 1 Lake Park',
            tags: ['loser']
        },
        {
            key: '3',
            firstName: 'Joe',
            lastName: 'Black',
            age: 32,
            address: 'Sidney No. 1 Lake Park',
            tags: ['cool', 'teacher']
        }
    ];

    return (
        <div className="dashboard page">
            <CountUsers />
            <Row gutter={30} className="dashboard__auth">
                <Col lg={12} id="CountSignIn">
                    <CountSignIn />
                </Col>
                <Col lg={12} id="CountRegistration">
                    <CountRegistration />
                </Col>
            </Row>
            <Row gutter={30} className="dashboard__popular">
                <Col lg={12} id="PopularEquipments">
                    <PopularEquipments />
                </Col>
                <Col lg={12} id="PopularDetails">
                    <PopularDetails />
                </Col>
            </Row>
        </div>
    );
};
export default WrapperPage(DashboardPage);
