/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Button,
    Typography,
    Input,
    Tabs,
    Form,
    Select
} from 'antd';
import firebase from '../../../utils/firebase';

import { AppState } from '../../../store';
import { editingUsersAction, requestUpdateUsersAction, viewingUsersAction } from '../../../store/actions/users';
import { AccountsType } from '../../../store/types/auth';
import { CompaniesItem } from '../../../store/types/companies';
import { UsersItem } from '../../../store/types/users';

const { Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const Edit:React.FC = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const users = useSelector((state: AppState) => state.users);
    const auth = useSelector((state: AppState) => state.auth);
    const {
        data,
        companies,
        editing,
        loading
    } = users;
    const [user, changeUser] = useState<UsersItem | null>(null);
    const [company, changeCompany] = useState<CompaniesItem | null>(null);

    useEffect(() => {
        if (editing) {
            if (editing.userID) {
                if (data) {
                    const dataUser = data.filter((item) => item.id === editing.userID);
                    if (dataUser.length) {
                        changeUser(dataUser[0]);
                        form.setFields([
                            { name: 'type', value: dataUser[0].type },
                            { name: 'email', value: dataUser[0].email },
                            { name: 'fio', value: dataUser[0].fio },
                            { name: 'position', value: dataUser[0].position },
                            { name: 'phone', value: dataUser[0].phone }
                        ]);
                    } else {
                        changeUser(null);
                        form.resetFields();
                    }
                } else {
                    changeUser(null);
                    form.resetFields();
                }
            }
            if (editing.companyID) {
                if (companies) {
                    const dataCompany = companies.filter((item) => item.id === editing.companyID);
                    if (dataCompany.length) {
                        changeCompany(dataCompany[0]);
                        form.setFields([
                            { name: 'shortName', value: dataCompany[0].shortName },
                            { name: 'fullName', value: dataCompany[0].fullName },
                            { name: 'inn', value: dataCompany[0].inn },
                            { name: 'kpp', value: dataCompany[0].kpp },
                            { name: 'ogrn', value: dataCompany[0].ogrn },
                            { name: 'bankName', value: dataCompany[0].bankName },
                            { name: 'bankBIK', value: dataCompany[0].bankBIK },
                            { name: 'сorrespondentNumber', value: dataCompany[0].сorrespondentNumber },
                            { name: 'paymentNumber', value: dataCompany[0].paymentNumber }
                        ]);
                    } else {
                        changeCompany(null);
                        form.resetFields();
                    }
                } else {
                    changeCompany(null);
                    form.resetFields();
                }
            }
        } else {
            changeUser(null);
            changeCompany(null);
        }
    }, [editing]);

    const onClose = () => {
        dispatch(editingUsersAction());
    };

    const onSave = (values: any) => {
        const time = firebase.firestore.Timestamp.now();
        const userID = user?.id as string;
        const companyID = values.hasOwnProperty('inn') ? company?.id as string : null;

        dispatch(requestUpdateUsersAction({
            user: {
                fio: values.fio,
                phone: values.phone,
                position: values.position,
                updatedAt: time.seconds
            },
            company: values.hasOwnProperty('inn') ? {
                bankBIK: values.bankBIK,
                bankName: values.bankName,
                updatedAt: time.seconds,
                fullName: values.fullName,
                inn: values.inn,
                сorrespondentNumber: values.сorrespondentNumber,
                paymentNumber: values.paymentNumber,
                kpp: values.kpp,
                ogrn: values.ogrn,
                shortName: values.shortName
            } : null
        }, userID, companyID));
    };

    return (
        <Modal
            title="Просмотр профиля"
            centered
            visible={editing !== null}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>
                    Закрыть
                </Button>,
                <Button key="save" type="primary" loading={loading} onClick={() => form.submit()}>
                    Сохранить
                </Button>
            ]}
        >
            <Form
                form={form}
                onFinish={onSave}
                layout="vertical"
            >
                <Tabs type="card">
                    {user && (
                        <TabPane tab="Пользователь" key="1">
                            <Form.Item name="type" label="Тип аккаунта">
                                <Select
                                    placeholder="Выберите тип аккаунта"
                                    disabled
                                >
                                    <Option value="client">Клиент</Option>
                                    <Option value="engineer">Инженер</Option>
                                    <Option value="manager">Менеджер</Option>
                                    <Option value="admin">Администратор</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="email" label="Email">
                                <Input placeholder="Email пользователя" disabled />
                            </Form.Item>
                            <Form.Item name="fio" label="Ф.И.О." rules={[{ required: true, message: 'Заполните это поле!' }]}>
                                <Input placeholder="Ф.И.О." />
                            </Form.Item>
                            <Form.Item name="position" label="Должность" rules={[{ required: true, message: 'Заполните это поле!' }]}>
                                <Input placeholder="Должность" />
                            </Form.Item>
                            <Form.Item name="phone" label="Телефон" rules={[{ required: true, message: 'Заполните это поле!' }]}>
                                <Input placeholder="Телефон" />
                            </Form.Item>
                        </TabPane>
                    )}
                    {company && (
                        <TabPane tab="Компания" key="2">
                            <Form.Item name="shortName" label="Краткое название" rules={[{ required: true, message: 'Заполните это поле!' }]}>
                                <Input placeholder="Краткое название" />
                            </Form.Item>
                            <Form.Item name="fullName" label="Полное название">
                                <Input placeholder="Полное название" />
                            </Form.Item>
                            <Form.Item name="inn" label="ИНН" rules={[{ required: true, message: 'Заполните это поле!' }]}>
                                <Input placeholder="ИНН" />
                            </Form.Item>
                            <Form.Item name="kpp" label="КПП">
                                <Input placeholder="КПП" />
                            </Form.Item>
                            <Form.Item name="ogrn" label="ОГРН">
                                <Input placeholder="ОГРН" />
                            </Form.Item>
                            <Form.Item name="bankName" label="Банк">
                                <Input placeholder="Банк" />
                            </Form.Item>
                            <Form.Item name="bankBIK" label="БИК">
                                <Input placeholder="БИК" />
                            </Form.Item>
                            <Form.Item name="сorrespondentNumber" label="К/C">
                                <Input placeholder="К/C" />
                            </Form.Item>
                            <Form.Item name="paymentNumber" label="Р/C">
                                <Input placeholder="Р/C" />
                            </Form.Item>
                        </TabPane>
                    )}
                </Tabs>
            </Form>
        </Modal>
    );
};

export default Edit;
