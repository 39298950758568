import {
    AuthActionsList,
    AuthActionsType,
    AuthStateType
} from '../types/auth';

export const initialState: AuthStateType = {
    uid: localStorage.getItem('uid'),
    id: null,
    email: null,
    isAuth: false,
    account: null,
    company: null,
    loading: false,
    error: null
};

const authReducer = (state: AuthStateType = initialState, action: AuthActionsType) : AuthStateType => {
    switch (action.type) {
        case AuthActionsList.ERROR_AUTH: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case AuthActionsList.REQUEST_SIGNIN_AUTH:
        case AuthActionsList.REQUEST_SIGNOUT_AUTH:
        case AuthActionsList.REQUEST_SIGNUP_AUTH: {
            return {
                ...state,
                loading: true
            };
        }
        case AuthActionsList.RESPONSE_SIGNIN_AUTH:
        case AuthActionsList.RESPONSE_SIGNUP_AUTH: {
            if (action.id) {
                localStorage.setItem('uid', action.uid as string);
            }

            return {
                ...state,
                id: action.id,
                uid: action.uid,
                email: action.email,
                account: action.account,
                company: action.company,
                error: null,
                isAuth: true,
                loading: false
            };
        }
        case AuthActionsList.RESPONSE_SIGNOUT_AUTH: {
            localStorage.removeItem('uid');
            return {
                ...state,
                id: null,
                uid: null,
                email: null,
                account: null,
                error: null,
                isAuth: false,
                loading: false
            };
        }
        default:
            return state;
    }
};

export default authReducer;
