import firebase from 'firebase';
// import 'firebase/firestore';
// import 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyCHqCUa1VNig5Oe7Z_7naHBXqKq9mPUN9o',
    authDomain: 'nss-crm.firebaseapp.com',
    projectId: 'nss-crm',
    storageBucket: 'nss-crm.appspot.com',
    messagingSenderId: '242214344141',
    appId: '1:242214344141:web:aa987e6b06bfbf44bae084',
    measurementId: 'G-X1LXX4WJRZ'
};

firebase.initializeApp(firebaseConfig);

const dbInit = firebase.firestore();

if (window.location.hostname === 'localhost') {
    dbInit.useEmulator('localhost', 8080);
}

export const db = dbInit;
export const auth = firebase.auth();
export const storage = firebase.storage();
export const secondApp = firebase.initializeApp(firebaseConfig, 'Second');

export default firebase;
