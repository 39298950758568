/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Button,
    Table,
    Empty
} from 'antd';

import { AppState } from '../../../store';
import { viewingOrdersAction } from '../../../store/actions/orders';
import { EquipmentDetailItem, EquipmentItem } from '../../../store/types/equipments';

const { Column } = Table;

const View:React.FC = () => {
    const dispatch = useDispatch();
    const [expandsRows, changeExpand] = useState<readonly React.Key[]>([]);
    const [dataOrder, changeDataOrder] = useState<EquipmentItem[]>([]);
    const orders = useSelector((state: AppState) => state.orders);

    const {
        data,
        companies,
        equipments,
        details,
        viewing
    } = orders;

    useEffect(() => {
        if (viewing) {
            const buildDataOrder: EquipmentItem[] = [];
            const order = orders.data?.filter((item) => item.id === viewing.orderID)[0];

            if (equipments) {
                order?.order.forEach((item) => {
                    const equipmentData: EquipmentItem = equipments.filter((eq) => eq.id === item.equipmentID)[0];
                    const equipmentDetailsData: EquipmentDetailItem[] = [];

                    if (details) {
                        item.details.forEach((det) => {
                            const detailData = details.filter((d) => d.id === det.id)[0];
                            equipmentDetailsData.push({ ...detailData, count: det.count });
                        });
                    }

                    equipmentData.count = item.equipmentCount;
                    equipmentData.details = equipmentDetailsData;

                    buildDataOrder.push(equipmentData);
                });
            }

            changeDataOrder(buildDataOrder);
        }
    }, [viewing]);

    const onClose = () => {
        dispatch(viewingOrdersAction());
    };

    if (data === null) return null;

    return (
        <Modal
            title="Состав заказа"
            centered
            visible={viewing !== null}
            onCancel={onClose}
            width={1000}
            footer={[
                <Button key="back" type="primary" onClick={onClose}>
                    Закрыть
                </Button>
            ]}
        >
            <Table
                dataSource={dataOrder}
                pagination={false}
                rowKey={((record) => `equipment_${record.id}`)}
                expandable={{
                    expandedRowKeys: expandsRows,
                    onExpandedRowsChange: ((rowKeys) => changeExpand(rowKeys)),
                    expandedRowRender: (equipment: EquipmentItem) => (
                        <Table
                            dataSource={equipment.details}
                            pagination={false}
                            rowKey={((detail) => `detail_${detail.id}`)}
                        >
                            <Column
                                title="Название детали"
                                dataIndex="name"
                                key="name"
                                width="45%"
                            />
                            <Column
                                title="Серийный номер"
                                dataIndex="serial"
                                key="serial"
                                width="45%"
                            />
                            <Column
                                title="Кол-во"
                                dataIndex="count"
                                key="count"
                                width="10%"
                            />
                        </Table>
                    ),
                    rowExpandable: (record: EquipmentItem) => !!record.details.length
                }}
                locale={{
                    emptyText: <Empty description="Нет данных" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }}
            >
                <Column
                    title="Марка"
                    dataIndex="mark"
                    key="mark"
                    width="26.666%"
                />
                <Column
                    title="Модель"
                    dataIndex="model"
                    key="model"
                    width="26.666%"
                />
                <Column
                    title="Серийный номер"
                    dataIndex="serial"
                    key="serial"
                    width="26.666%"
                />
                <Column
                    title="Учреждение"
                    dataIndex="build"
                    key="build"
                    width="26.666%"
                />
                <Column
                    title="Год"
                    dataIndex="year"
                    key="year"
                    width="10%"
                />
                <Column
                    title="Кол-во"
                    dataIndex="count"
                    key="count"
                    width="10%"
                />
            </Table>
        </Modal>
    );
};

export default View;
