import React from 'react';

import './style.scss';
import WrapperPage from '../../containers/WrapperPage';
import ListOrder from './List';

const OrdersPage:React.FC = () => (
    <div className="orders page">
        <ListOrder />
    </div>
);

export default WrapperPage(OrdersPage);
