import { all } from 'redux-saga/effects';
import { authWatcher } from './auth';
import { companiesWatcher } from './companies';
import { equipmentsWatcher } from './equipments';
import { datesWatcher } from './dates';
import { offersWatcher } from './offers';
import { usersWatcher } from './users';
import { chatWatcher } from './chat';
import { statisticWatcher } from './statistic';
import { ordersWatcher } from './orders';
import { notificationsWatcher } from './notifications';

export function* rootWatcher() {
    yield all([
        authWatcher(),
        companiesWatcher(),
        equipmentsWatcher(),
        datesWatcher(),
        offersWatcher(),
        usersWatcher(),
        chatWatcher(),
        statisticWatcher(),
        ordersWatcher(),
        notificationsWatcher()
    ]);
}
