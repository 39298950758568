import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'swiper/swiper.scss';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.less';

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
