import { Moment } from 'moment';

export interface StatisticStateType {
    countUsers: {
        data: CountUsersStatisticData | null,
        loading: boolean
    },
    countSignIn: {
        data: CountSignInStatisticDataItem[] | null,
        loading: boolean
    },
    countRegistration: {
        data: CountRegistrationStatisticDataItem[] | null,
        loading: boolean
    },
    popularEquipments: {
        data: PopularEquipmentsStatisticDataItem[] | null,
        loading: boolean
    },
    popularDetails: {
        data: PopularDetailsStatisticDataItem[] | null,
        loading: boolean
    }
    error: string | null
}

export enum StatisticActionsList {
    REQUEST_COUNT_USERS_STATISTIC = 'REQUEST_COUNT_USERS_STATISTIC',
    RESPONSE_COUNT_USERS_STATISTIC = 'RESPONSE_COUNT_USERS_STATISTIC',
    ERROR_COUNT_USERS_STATISTIC = 'ERROR_COUNT_USERS_STATISTIC',
    REQUEST_COUNT_SIGN_IN_STATISTIC = 'REQUEST_COUNT_SIGN_IN_STATISTIC',
    RESPONSE_COUNT_SIGN_IN_STATISTIC = 'RESPONSE_COUNT_SIGN_IN_STATISTIC',
    ERROR_COUNT_SIGN_IN_STATISTIC = 'ERROR_COUNT_SIGN_IN_STATISTIC',
    REQUEST_COUNT_REGISTRATION_STATISTIC = 'REQUEST_COUNT_REGISTRATION_STATISTIC',
    RESPONSE_COUNT_REGISTRATION_STATISTIC = 'RESPONSE_COUNT_REGISTRATION_STATISTIC',
    ERROR_COUNT_REGISTRATION_STATISTIC = 'ERROR_COUNT_REGISTRATION_STATISTIC',
    REQUEST_POPULAR_EQUIPMENTS_STATISTIC = 'REQUEST_POPULAR_EQUIPMENTS_STATISTIC',
    RESPONSE_POPULAR_EQUIPMENTS_STATISTIC = 'RESPONSE_POPULAR_EQUIPMENTS_STATISTIC',
    ERROR_POPULAR_EQUIPMENTS_STATISTIC = 'ERROR_POPULAR_EQUIPMENTS_STATISTIC',
    REQUEST_POPULAR_DETAILS_STATISTIC = 'REQUEST_POPULAR_DETAILS_STATISTIC',
    RESPONSE_POPULAR_DETAILS_STATISTIC = 'RESPONSE_POPULAR_DETAILS_STATISTIC',
    ERROR_POPULAR_DETAILS_STATISTIC = 'ERROR_POPULAR_DETAILS_STATISTIC',
    RESPONSE_SIGNOUT_AUTH = 'RESPONSE_SIGNOUT_AUTH'
}

export interface CountUsersStatisticData {
    new: number,
    all: number
}

export interface CountSignInStatisticDataItem {
    name: string,
    value: number,
    date: Moment
}

export interface CountRegistrationStatisticDataItem {
    name: string,
    value: number,
    date: Moment
}

export interface PopularEquipmentsStatisticDataItem {
    key: string,
    name: string,
    value: number
}

export interface PopularDetailsStatisticDataItem {
    key: string,
    name: string,
    value: number
}

export interface RequestCountUsersStatisticActionType {
    type: StatisticActionsList.REQUEST_COUNT_USERS_STATISTIC,
    date: [Moment, Moment]
}

export interface ResponseCountUsersStatisticActionType {
    type: StatisticActionsList.RESPONSE_COUNT_USERS_STATISTIC,
    data: CountUsersStatisticData
}

export interface ErrorCountUsersStatisticActionType {
    type: StatisticActionsList.ERROR_COUNT_USERS_STATISTIC,
    error: string
}

export interface RequestCountSignInStatisticActionType {
    type: StatisticActionsList.REQUEST_COUNT_SIGN_IN_STATISTIC,
    date: [Moment, Moment]
}

export interface ResponseCountSignInStatisticActionType {
    type: StatisticActionsList.RESPONSE_COUNT_SIGN_IN_STATISTIC,
    data: CountSignInStatisticDataItem[]
}

export interface ErrorCountSignInStatisticActionType {
    type: StatisticActionsList.ERROR_COUNT_SIGN_IN_STATISTIC,
    error: string
}

export interface RequestCountRegistrationStatisticActionType {
    type: StatisticActionsList.REQUEST_COUNT_REGISTRATION_STATISTIC,
    date: [Moment, Moment]
}

export interface ResponseCountRegistrationStatisticActionType {
    type: StatisticActionsList.RESPONSE_COUNT_REGISTRATION_STATISTIC,
    data: CountRegistrationStatisticDataItem[]
}

export interface ErrorCountRegistrationStatisticActionType {
    type: StatisticActionsList.ERROR_COUNT_REGISTRATION_STATISTIC,
    error: string
}

export interface RequestPopularEquipmentsStatisticActionType {
    type: StatisticActionsList.REQUEST_POPULAR_EQUIPMENTS_STATISTIC
}

export interface ResponsePopularEquipmentsStatisticActionType {
    type: StatisticActionsList.RESPONSE_POPULAR_EQUIPMENTS_STATISTIC,
    data: PopularEquipmentsStatisticDataItem[]
}

export interface ErrorPopularEquipmentsStatisticActionType {
    type: StatisticActionsList.ERROR_POPULAR_EQUIPMENTS_STATISTIC,
    error: string
}

export interface RequestPopularDetailsStatisticActionType {
    type: StatisticActionsList.REQUEST_POPULAR_DETAILS_STATISTIC
}

export interface ResponsePopularDetailsStatisticActionType {
    type: StatisticActionsList.RESPONSE_POPULAR_DETAILS_STATISTIC,
    data: PopularEquipmentsStatisticDataItem[]
}

export interface ErrorPopularDetailsStatisticActionType {
    type: StatisticActionsList.ERROR_POPULAR_DETAILS_STATISTIC,
    error: string
}

export interface ResponseSignOutActionType {
    type: StatisticActionsList.RESPONSE_SIGNOUT_AUTH
}

export type StatisticActionsType =
RequestCountUsersStatisticActionType
| ResponseCountUsersStatisticActionType
| ErrorCountUsersStatisticActionType
| RequestCountSignInStatisticActionType
| ResponseCountSignInStatisticActionType
| ErrorCountSignInStatisticActionType
| RequestCountRegistrationStatisticActionType
| ResponseCountRegistrationStatisticActionType
| ErrorCountRegistrationStatisticActionType
| RequestPopularEquipmentsStatisticActionType
| ResponsePopularEquipmentsStatisticActionType
| ErrorPopularEquipmentsStatisticActionType
| RequestPopularDetailsStatisticActionType
| ResponsePopularDetailsStatisticActionType
| ErrorPopularDetailsStatisticActionType
| ResponseSignOutActionType;
