import { CompaniesItem, CompaniesItemData } from '../types/companies';
import {
    UsersActionsList,
    UsersActionsType,
    UsersCreateData,
    UsersItem,
    UsersItemData,
    UsersUpdateData
} from '../types/users';

export const errorUsersAction = (error: string): UsersActionsType => ({
    type: UsersActionsList.USERS_ERROR,
    error
});

export const requestGetUsersAction = (userID: string | null = null, userType: string | null = null): UsersActionsType => ({
    type: UsersActionsList.USERS_REQUEST_GET,
    userID,
    userType
});

export const responseGetUsersAction = (users: UsersItem[]): UsersActionsType => ({
    type: UsersActionsList.USERS_RESPONSE_GET,
    data: users
});

export const creatingUsersAction = (): UsersActionsType => ({
    type: UsersActionsList.USERS_CREATING
});

export const viewingUsersAction = (userID: string | null = null, companyID: string | null = null): UsersActionsType => ({
    type: UsersActionsList.USERS_VIEWING,
    userID,
    companyID
});

export const editingUsersAction = (userID: string | null = null, companyID: string | null = null): UsersActionsType => ({
    type: UsersActionsList.USERS_EDITING,
    userID,
    companyID
});

export const requestCreateUsersAction = (data: UsersCreateData): UsersActionsType => ({
    type: UsersActionsList.USERS_REQUEST_CREATE,
    data
});

export const responseCreateUsersAction = (data: UsersItem): UsersActionsType => ({
    type: UsersActionsList.USERS_RESPONSE_CREATE,
    data
});

export const requestUpdateUsersAction = (
    data: { user: UsersUpdateData, company: CompaniesItemData | null },
    userID: string,
    companyID: string | null = null
): UsersActionsType => ({
    type: UsersActionsList.USERS_REQUEST_UPDATE,
    userID,
    companyID,
    data
});

export const responseUpdateUsersAction = (
    data: { user: UsersUpdateData, company: CompaniesItemData | null },
    userID: string,
    companyID: string | null = null
): UsersActionsType => ({
    type: UsersActionsList.USERS_RESPONSE_UPDATE,
    userID,
    companyID,
    data
});

export const requestDeleteUsersAction = (id: string): UsersActionsType => ({
    type: UsersActionsList.USERS_REQUEST_DELETE,
    id
});

export const responseDeleteUsersAction = (id: string): UsersActionsType => ({
    type: UsersActionsList.USERS_RESPONSE_DELETE,
    id
});

export const setAdditionalDataUsersAction = (companies: CompaniesItem[]): UsersActionsType => ({
    type: UsersActionsList.USERS_SET_ADDITIONAL_DATA,
    companies
});
