import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Button,
    Form,
    Select,
    Empty,
    ConfigProvider
} from 'antd';
import { RequiredMark } from 'antd/lib/form/Form';
import firebase from '../../../utils/firebase';

import { AppState } from '../../../store';
import { addingEngineerChatAction, requestAddEngineerChatAction } from '../../../store/actions/chat';

const customEmpty = () => (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет данных" />);

const AddEngineer:React.FC = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const chat = useSelector((state: AppState) => state.chat);
    const {
        addingEngineer,
        currentChat,
        data,
        loading
    } = chat;
    const currentChatData = data?.filter((item) => item.id === currentChat);
    if (!currentChatData?.length) return null;

    const engineers = chat.users?.filter((item) => item.type === 'engineer' && !currentChatData[0].members.includes(item.id));

    const onAdd = (values: any) => {
        if (values.engineer !== undefined && values.engineer !== '') {
            dispatch(requestAddEngineerChatAction(currentChat as string, values.engineer));
            form.resetFields();
        }
    };

    const onClose = () => {
        dispatch(addingEngineerChatAction());
    };

    return (
        <Modal
            title="Добавить инженера"
            centered
            visible={addingEngineer}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose} loading={loading}>
                    Отменить
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
                    Добавить
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onAdd}
            >
                <ConfigProvider renderEmpty={customEmpty}>
                    <Form.Item label="Инженер" name="engineer" tooltip="Выберите инженера из списка" required>
                        <Select>
                            {engineers?.map((item) => <Select.Option key={item.id} value={item.id}>{item.fio}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </ConfigProvider>
            </Form>
        </Modal>
    );
};

export default AddEngineer;
