import { CompaniesItemShort } from './companies';
import { UsersItemShort } from './users';

export interface DatesItemData {
    companyID: string,
    finished: boolean,
    from: number,
    to: number,
    userID: string,
    createdAt?: number,
    updatedAt: number
}

export interface DatesItem extends DatesItemData {
    id: string
}

export interface DatesStateType {
    loading: boolean,
    data: DatesItem[] | null,
    users: UsersItemShort[] | null,
    companies: CompaniesItemShort[] | null,
    editing: string | null,
    error: string | null
}

export enum DatesActionsList {
    ERROR_DATES = 'ERROR_DATES',
    REQUEST_GET_DATES = 'REQUEST_GET_DATES',
    RESPONSE_GET_DATES = 'RESPONSE_GET_DATES',
    REQUEST_ADD_DATES = 'REQUEST_ADD_DATES',
    RESPONSE_ADD_DATES = 'RESPONSE_ADD_DATES',
    REQUEST_UPDATE_DATES = 'REQUEST_UPDATE_DATES',
    RESPONSE_UPDATE_DATES = 'RESPONSE_UPDATE_DATES',
    REQUEST_DELETE_DATES = 'REQUEST_DELETE_DATES',
    RESPONSE_DELETE_DATES = 'RESPONSE_DELETE_DATES',
    ADD_DATES = 'ADD_DATES',
    DELETE_DATES = 'DELETE_DATES',
    UPDATE_DATES = 'UPDATE_DATES',
    SET_ADDITIONAL_DATA_DATES = 'SET_ADDITIONAL_DATA_DATES',
    RESPONSE_SIGNOUT_AUTH = 'RESPONSE_SIGNOUT_AUTH'
}

export interface ErrorDatesActionType {
    type: DatesActionsList.ERROR_DATES,
    error: string
}

export interface RequestGetDatesActionType {
    type: DatesActionsList.REQUEST_GET_DATES,
    companyID: string | null
}

export interface ResponseGetDatesActionType {
    type: DatesActionsList.RESPONSE_GET_DATES,
    data: DatesItem[]
}

export interface RequestAddDatesActionType {
    type: DatesActionsList.REQUEST_ADD_DATES,
    data: DatesItemData
}

export interface ResponseAddDatesActionType {
    type: DatesActionsList.RESPONSE_ADD_DATES
}

export interface RequestUpdateDatesActionType {
    type: DatesActionsList.REQUEST_UPDATE_DATES,
    dateID: string,
    data: DatesItemData
}

export interface ResponseUpdateDatesActionType {
    type: DatesActionsList.RESPONSE_UPDATE_DATES
}

export interface RequestDeleteDatesActionType {
    type: DatesActionsList.REQUEST_DELETE_DATES,
    dateID: string
}

export interface ResponseDeleteDatesActionType {
    type: DatesActionsList.RESPONSE_DELETE_DATES
}

export interface SetAdditionalDataDatesActionType {
    type: DatesActionsList.SET_ADDITIONAL_DATA_DATES,
    companies: CompaniesItemShort[],
    users: UsersItemShort[],
}

export interface AddDatesActionType {
    type: DatesActionsList.ADD_DATES,
    data: DatesItem
}

export interface UpdateDatesActionType {
    type: DatesActionsList.UPDATE_DATES,
    data: DatesItem
}

export interface DeleteDatesActionType {
    type: DatesActionsList.DELETE_DATES,
    dateID: string
}

export interface ResponseSignOutActionType {
    type: DatesActionsList.RESPONSE_SIGNOUT_AUTH
}

export type DatesActionsType =
ErrorDatesActionType
| RequestGetDatesActionType
| ResponseGetDatesActionType
| RequestUpdateDatesActionType
| ResponseUpdateDatesActionType
| RequestDeleteDatesActionType
| ResponseDeleteDatesActionType
| SetAdditionalDataDatesActionType
| AddDatesActionType
| UpdateDatesActionType
| DeleteDatesActionType
| RequestAddDatesActionType
| ResponseAddDatesActionType
| ResponseSignOutActionType;
