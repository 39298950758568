import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { AppState } from '../../store';
import routes from '../../router';

const MainMenu: React.FC = () => {
    const auth = useSelector((state: AppState) => state.auth);
    const { account } = auth;
    const location = useLocation();

    return (
        <Menu theme="dark" defaultSelectedKeys={[location.pathname]} mode="inline">
            {routes.map((item) => {
                if (item.showInMenu) {
                    if (item.permissions) {
                        if (account && item.permissions.includes(account)) {
                            return (
                                <Menu.Item key={item.path} icon={item.icon}>
                                    {item.nameInMenu}
                                    <Link to={item.path} />
                                </Menu.Item>
                            );
                        }
                        return null;
                    }
                    return (
                        <Menu.Item key={item.path} icon={item.icon}>
                            {item.nameInMenu}
                            <Link to={item.path} />
                        </Menu.Item>
                    );
                }
                return null;
            })}
        </Menu>
    );
};

export default MainMenu;
