import { Moment } from 'moment';
import {
    StatisticActionsList,
    StatisticActionsType,
    CountUsersStatisticData,
    CountSignInStatisticDataItem,
    CountRegistrationStatisticDataItem,
    PopularEquipmentsStatisticDataItem,
    PopularDetailsStatisticDataItem
} from '../types/statistic';

export const requestCountUsersStatisticAction = (date: [Moment, Moment]): StatisticActionsType => ({
    type: StatisticActionsList.REQUEST_COUNT_USERS_STATISTIC,
    date
});

export const responseCountUsersStatisticAction = (data: CountUsersStatisticData): StatisticActionsType => ({
    type: StatisticActionsList.RESPONSE_COUNT_USERS_STATISTIC,
    data
});

export const errorCountUsersStatisticAction = (error: string): StatisticActionsType => ({
    type: StatisticActionsList.ERROR_COUNT_USERS_STATISTIC,
    error
});

export const requestCountSignInStatisticAction = (date: [Moment, Moment]): StatisticActionsType => ({
    type: StatisticActionsList.REQUEST_COUNT_SIGN_IN_STATISTIC,
    date
});

export const responseCountSignInStatisticAction = (data: CountSignInStatisticDataItem[]): StatisticActionsType => ({
    type: StatisticActionsList.RESPONSE_COUNT_SIGN_IN_STATISTIC,
    data
});

export const errorCountSignInStatisticAction = (error: string): StatisticActionsType => ({
    type: StatisticActionsList.ERROR_COUNT_SIGN_IN_STATISTIC,
    error
});

export const requestCountRegistrationStatisticAction = (date: [Moment, Moment]): StatisticActionsType => ({
    type: StatisticActionsList.REQUEST_COUNT_REGISTRATION_STATISTIC,
    date
});

export const responseCountRegistrationStatisticAction = (data: CountRegistrationStatisticDataItem[]): StatisticActionsType => ({
    type: StatisticActionsList.RESPONSE_COUNT_REGISTRATION_STATISTIC,
    data
});

export const errorCountRegistrationStatisticAction = (error: string): StatisticActionsType => ({
    type: StatisticActionsList.ERROR_COUNT_REGISTRATION_STATISTIC,
    error
});

export const requestPopularEquipmentsStatisticAction = (): StatisticActionsType => ({
    type: StatisticActionsList.REQUEST_POPULAR_EQUIPMENTS_STATISTIC
});

export const responsePopularEquipmentsStatisticAction = (data: PopularEquipmentsStatisticDataItem[]): StatisticActionsType => ({
    type: StatisticActionsList.RESPONSE_POPULAR_EQUIPMENTS_STATISTIC,
    data
});

export const errorPopularEquipmentsStatisticAction = (error: string): StatisticActionsType => ({
    type: StatisticActionsList.ERROR_POPULAR_EQUIPMENTS_STATISTIC,
    error
});

export const requestPopularDetailsStatisticAction = (): StatisticActionsType => ({
    type: StatisticActionsList.REQUEST_POPULAR_DETAILS_STATISTIC
});

export const responsePopularDetailsStatisticAction = (data: PopularDetailsStatisticDataItem[]): StatisticActionsType => ({
    type: StatisticActionsList.RESPONSE_POPULAR_DETAILS_STATISTIC,
    data
});

export const errorPopularDetailsStatisticAction = (error: string): StatisticActionsType => ({
    type: StatisticActionsList.ERROR_POPULAR_DETAILS_STATISTIC,
    error
});
