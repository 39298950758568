import {
    CompaniesActionsList,
    ErrorCompaniesActionType,
    RequestGetCompaniesActionType,
    ResponseUpdateCompaniesActionType,
    RequestUpdateCompaniesActionType,
    ResponseGetCompaniesActionType,
    CompaniesItem,
    CompaniesItemData
} from '../types/companies';

export const errorCompaniesAction = (error: string): ErrorCompaniesActionType => ({
    type: CompaniesActionsList.ERROR_COMPANIES,
    error
});

export const requestGetCompaniesAction = (companyID: string | null): RequestGetCompaniesActionType => ({
    type: CompaniesActionsList.REQUEST_GET_COMPANIES,
    companyID
});

export const responseGetCompaniesAction = (companies: CompaniesItem[]): ResponseGetCompaniesActionType => ({
    type: CompaniesActionsList.RESPONSE_GET_COMPANIES,
    data: companies
});

export const requestUpdateCompaniesAction = (companyID: string, data: CompaniesItemData): RequestUpdateCompaniesActionType => ({
    type: CompaniesActionsList.REQUEST_UPDATE_COMPANIES,
    companyID,
    data
});

export const responseUpdateCompaniesAction = (companyID: string, data: CompaniesItemData): ResponseUpdateCompaniesActionType => ({
    type: CompaniesActionsList.RESPONSE_UPDATE_COMPANIES,
    companyID,
    data
});
