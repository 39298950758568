import {
    ErrorAuthActionType,
    AuthActionsList,
    AccountsType,
    RequestSignInActionType,
    ResponseSignInActionType,
    RequestSignOutActionType,
    ResponseSignOutActionType,
    RequestSignUpActionType,
    ResponseSignUpActionType,
    RequestSignUpActionData,
    SubscribeNewAuthActionType
} from '../types/auth';

export const errorAuthAction = (error: string): ErrorAuthActionType => ({
    type: AuthActionsList.ERROR_AUTH,
    error
});

export const requestSignInAction = (
    login: string | null,
    password: string | null,
    uid: string | null = null
): RequestSignInActionType => ({
    type: AuthActionsList.REQUEST_SIGNIN_AUTH,
    login,
    password,
    uid
});

export const responseSignInAction = (
    id: string | null,
    uid: string | null,
    email: string | null,
    account: AccountsType,
    company: string | null
): ResponseSignInActionType => ({
    type: AuthActionsList.RESPONSE_SIGNIN_AUTH,
    id,
    uid,
    email,
    account,
    company
});

export const requestSignOutAction = (): RequestSignOutActionType => ({
    type: AuthActionsList.REQUEST_SIGNOUT_AUTH
});

export const responseSignOutAction = (): ResponseSignOutActionType => ({
    type: AuthActionsList.RESPONSE_SIGNOUT_AUTH
});

export const requestSignUpAction = (data: RequestSignUpActionData): RequestSignUpActionType => ({
    type: AuthActionsList.REQUEST_SIGNUP_AUTH,
    data
});

export const responseSignUpAction = (
    id: string,
    uid: string,
    email: string,
    account: AccountsType,
    company: string
): ResponseSignUpActionType => ({
    type: AuthActionsList.RESPONSE_SIGNUP_AUTH,
    id,
    uid,
    email,
    account,
    company
});

export const subscribeNewAuthAction = (): SubscribeNewAuthActionType => ({
    type: AuthActionsList.SUBSCRIBE_NEW_AUTH
});
