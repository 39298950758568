import { CompaniesItem } from '../types/companies';
import { EquipmentDetailItem, EquipmentItem } from '../types/equipments';
import {
    OrdersItemData,
    OrdersActionsList,
    OrdersActionsType,
    OrdersItem
} from '../types/orders';

export const errorOrderAction = (error: string): OrdersActionsType => ({
    type: OrdersActionsList.ERROR_ORDERS,
    error
});

export const requestGetOrderAction = (companyID: string | null = null): OrdersActionsType => ({
    type: OrdersActionsList.REQUEST_GET_ORDERS,
    companyID
});

export const responseGetOrdersAction = (data: OrdersItem[]): OrdersActionsType => ({
    type: OrdersActionsList.RESPONSE_GET_ORDERS,
    data
});

export const requestCreateOrderAction = (data: OrdersItemData): OrdersActionsType => ({
    type: OrdersActionsList.REQUEST_CREATE_ORDERS,
    data
});

export const responseCreateOrdersAction = (data: OrdersItem): OrdersActionsType => ({
    type: OrdersActionsList.RESPONSE_CREATE_ORDERS,
    data
});

export const viewingOrdersAction = (orderID: string | null = null): OrdersActionsType => ({
    type: OrdersActionsList.VIEWING_ORDERS,
    orderID
});

export const setAdditionalDataOrdersAction = (
    companies: CompaniesItem[],
    equipments: EquipmentItem[],
    details: EquipmentDetailItem[]
): OrdersActionsType => ({
    type: OrdersActionsList.SET_ADDITIONAL_DATA_ORDERS,
    companies,
    equipments,
    details
});

export const subscribeOrdersAction = (): OrdersActionsType => ({
    type: OrdersActionsList.SUBSCRIBE_ORDERS
});
