import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Avatar,
    Typography
} from 'antd';
import moment from 'moment';

import './style.scss';
import {
    changeChatAction
} from '../../../store/actions/chat';
import { AppState } from '../../../store';

const { Text } = Typography;

const ListChats:React.FC = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state: AppState) => state.auth);
    const chats = useSelector((state: AppState) => state.chat);
    const { data, users, currentChat } = chats;

    const selectChat = (chatID: string) => {
        dispatch(changeChatAction(chatID));
    };

    if (data) {
        data.sort((a, b) => {
            if (a.messages && a.messages.length && b.messages && b.messages.length) {
                return a.messages[a.messages.length - 1].createdAt < b.messages[b.messages.length - 1].createdAt ? 1 : -1;
            }
            return 0;
        });
    }

    return (
        <>
            {data && data.map((chat) => {
                if (chat.messages?.length) {
                    const lastMessage = chat.messages[chat.messages.length - 1];
                    const userMessage = users?.filter((item) => item.id === lastMessage.userID);
                    let displayName = '- Удалён -';
                    let displayFullName = '- Удалён -';
                    if (userMessage?.length) {
                        displayFullName = userMessage[0].fio;
                        const tmp = userMessage[0].fio.split(' ');
                        displayName = `${tmp[0]} ${tmp[1][0]}.`;
                        if (tmp[2] !== undefined) {
                            displayName = `${displayName} ${tmp[2][0]}.`;
                        }
                    }

                    return (
                        <button
                            key={chat.id}
                            type="button"
                            className={`conversation ${(currentChat && currentChat === chat.id) && 'active'}`}
                            onClick={() => selectChat(chat.id)}
                        >
                            <Avatar size="large" className="conversation__image">Chat</Avatar>
                            <span className="info">
                                <span className="header">
                                    <span className="header__name">{chat.companyName ? chat.companyName : '- Компания удалена -'}</span>
                                    <span className="header__lasttime">{moment(lastMessage.createdAt * 1000).format('DD.MM.YY HH:mm')}</span>
                                </span>
                                <span className="body">
                                    <span className="message">
                                        <span className="message__name" title={displayFullName}>
                                            {displayName}
                                            :&nbsp;
                                        </span>
                                        <Text ellipsis style={{ maxWidth: 190 }}>{lastMessage.text.length ? lastMessage.text : 'Файл'}</Text>
                                    </span>
                                    {(lastMessage.status === 'sended' && lastMessage.userID !== auth.id) && <span className="body__new-badge" />}
                                </span>
                            </span>
                        </button>
                    );
                }

                return (
                    <button
                        key={chat.id}
                        type="button"
                        className={`conversation ${(currentChat && currentChat === chat.id) && 'active'}`}
                        onClick={() => selectChat(chat.id)}
                    >
                        <Avatar size="large" className="conversation__image">Chat</Avatar>
                        <span className="info">
                            <span className="header">
                                <span className="header__name">{chat.companyName ? chat.companyName : '- Компания удалена -'}</span>
                            </span>
                            <span className="body">
                                <span className="body__last-message empty">
                                    Пустой чат
                                </span>
                            </span>
                        </span>
                    </button>
                );
            })}
        </>
    );
};

export default ListChats;
