import { CompaniesItemShort } from '../types/companies';
import {
    OffersActionsList,
    OffersActionsType,
    OffersItem,
    OffersItemData
} from '../types/offers';

export const errorOffersAction = (error: string): OffersActionsType => ({
    type: OffersActionsList.ERROR_OFFERS,
    error
});

export const creatingOffersAction = (): OffersActionsType => ({
    type: OffersActionsList.CREATING_OFFERS
});

export const requestGetOffersAction = (): OffersActionsType => ({
    type: OffersActionsList.REQUEST_GET_OFFERS
});

export const responseGetOffersAction = (data: OffersItem[]): OffersActionsType => ({
    type: OffersActionsList.RESPONSE_GET_OFFERS,
    data
});

export const requestAddOffersAction = (data: OffersItemData): OffersActionsType => ({
    type: OffersActionsList.REQUEST_ADD_OFFERS,
    data
});

export const responseAddOffersAction = (): OffersActionsType => ({
    type: OffersActionsList.RESPONSE_ADD_OFFERS
});

export const requestUpdateOffersAction = (data: OffersItem): OffersActionsType => ({
    type: OffersActionsList.REQUEST_UPDATE_OFFERS,
    data
});

export const responseUpdateOffersAction = (data: OffersItem): OffersActionsType => ({
    type: OffersActionsList.RESPONSE_UPDATE_OFFERS,
    data
});

export const requestDeleteOffersAction = (offerID: string): OffersActionsType => ({
    type: OffersActionsList.REQUEST_DELETE_OFFERS,
    offerID
});

export const responseDeleteOffersAction = (offerID: string): OffersActionsType => ({
    type: OffersActionsList.RESPONSE_DELETE_OFFERS,
    offerID
});

export const setAdditionalDataOffersAction = (companies: CompaniesItemShort[]): OffersActionsType => ({
    type: OffersActionsList.SET_ADDITIONAL_DATA_OFFERS,
    companies
});

export const subscribeOffersAction = (): OffersActionsType => ({
    type: OffersActionsList.SUBSCRIBE_OFFERS
});
