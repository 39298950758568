/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Avatar,
    Input,
    Button,
    Typography,
    DatePicker,
    Upload
} from 'antd';
import Icon, {
    CheckOutlined,
    DeleteOutlined,
    FileJpgOutlined,
    FileOutlined,
    FilePdfOutlined,
    FileWordOutlined,
    LoadingOutlined,
    MessageOutlined,
    PaperClipOutlined,
    PlusCircleOutlined,
    PrinterOutlined,
    ReloadOutlined,
    SendOutlined,
    StopOutlined
} from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload/interface';
import moment, { Moment } from 'moment';

import './style.scss';
import {
    changeChatAction,
    requestGetChatHistoryAction,
    requestGetChatsAction,
    requestSendMessageChatAction,
    addingEngineerChatAction,
    requestGetPeriodChatAction,
    requestResetPeriodChatAction,
    requestGetHistoryCalendarChatAction
} from '../../../store/actions/chat';
import { AppState } from '../../../store';
import { AttachemntItem, ChatItem } from '../../../store/types/chat';
import Preloader from '../../../components/Preloader';
import generateID from '../../../utils/generateID';
import firebase, { storage } from '../../../utils/firebase';
import notification from '../../../utils/notification';
import AddEngineer from '../AddEngineer';

const { TextArea } = Input;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const SendedSvg = () => (
    <svg viewBox="0 0 16 15" fill="currentColor" width="16" height="15">
        <path fill="currentColor" d="M10.91 3.316l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" />
    </svg>
);

const ReadedSvg = () => (
    <svg viewBox="0 0 16 15" fill="currentColor" width="16" height="15">
        <path fill="currentColor" d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" />
    </svg>
);

const SendedIcon = (props: any) => <Icon component={SendedSvg} {...props} />;

const ReadedIcon = (props: any) => <Icon component={ReadedSvg} {...props} />;

const isMobile = window.matchMedia('(max-width: 991px)').matches;

const OneChat:React.FC = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state: AppState) => state.auth);
    const chats = useSelector((state: AppState) => state.chat);
    const [messageText, changeMessage] = useState<string>('');
    const [uploadFiles, changeUploadFiles] = useState<RcFile[]>([]);

    const {
        data,
        users,
        currentChat,
        period,
        monthsHistory
    } = chats;
    const historyRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (auth.account === 'client') {
            const chatID = `chat-${auth.company}`;
            dispatch(changeChatAction(chatID));
        }
    }, []);

    const sendMessage = () => {
        if (messageText !== '' || uploadFiles.length) {
            const id = generateID();
            const time = firebase.firestore.Timestamp.now();

            dispatch(requestSendMessageChatAction({
                id,
                chatID: currentChat as string,
                userID: auth.id as string,
                text: messageText,
                readed: false,
                status: 'sending',
                createdAt: time.seconds,
                updatedAt: time.seconds,
                originalDoc: null,
                attachments: uploadFiles,
                hasAttachments: !!uploadFiles.length
            }));
            changeMessage('');
            changeUploadFiles([]);
        }
    };

    const onScrollHistory = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (e.currentTarget.scrollTop === 0 && period === null) {
            const chatID = currentChat as string;
            const chat = data?.filter((item) => item.id === chatID)[0] as ChatItem;
            const { messages } = chat;
            if (messages && messages[0].originalDoc) {
                dispatch(requestGetChatHistoryAction(chatID, messages[0].originalDoc));
            }
        }
    };

    const printHistory = () => {
        const chat = data?.filter((item) => item.id === currentChat)[0] as ChatItem;
        const prtContent = chat.messages ? chat.messages.map((message) => {
            const user = users?.filter((item) => item.id === message.userID);
            const date = moment(message.createdAt * 1000).format('DD.MM.YYYY HH:mm');
            let fio = '- Удалённый пользователь';
            if (user?.length) fio = user[0].fio;

            if (message.attachments.length) {
                const tmpAttachments = message.attachments as AttachemntItem[];
                const attachments = tmpAttachments.map((item) => item.name);

                if (message.text.length) {
                    return (`
                    <p>(${date}) ${fio}: ${message.text} (Прикреплённые файлы: ${attachments.join(', ')})</p>
                `);
                }
                return (`
                    <p>(${date}) ${fio}: (Прикреплённые файлы: ${attachments.join(', ')})</p>
                `);
            }

            return (`
                <p>(${date}) ${fio}: ${message.text}</p>
            `);
        }) : [''];

        const WinPrint = window.open('', '', 'width=800,height=640,toolbar=0,scrollbars=1,status=0');
        if (WinPrint) {
            WinPrint.document.write(prtContent.join(''));
            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
        }
    };

    const downloadFile = (url: string) => {
        const fileRef = storage.ref(url);
        fileRef.getDownloadURL().then((urlData) => {
            window.open(urlData);
        });
    };

    if (currentChat === null) {
        return (
            <div className="chat__empty">
                <MessageOutlined />
                <Text>Выберите чат</Text>
            </div>
        );
    }

    if (data?.length === 0) {
        return <Preloader />;
    }

    const chat = data?.filter((item) => item.id === currentChat)[0] as ChatItem;
    const { loading, messages } = chat;

    if ((messages?.length === 0 || messages?.length === 1) && loading) {
        return <Preloader />;
    }

    return (
        <>
            {auth.account === 'admin' || auth.account === 'manager' ? (
                <div className="header">
                    <div className="header__calendar">
                        <RangePicker
                            value={period ? [moment(period[0]), moment(period[1])] : undefined}
                            className="header__calendar"
                            format="DD.MM.YYYY"
                            locale={{
                                lang: {
                                    locale: 'ru_RU',
                                    placeholder: 'Выбрать дату',
                                    rangePlaceholder: ['Начальная дата', 'Конечная дата'],
                                    today: 'Сегодня',
                                    now: 'Сейчас',
                                    backToToday: 'Вернуться назад',
                                    ok: 'ОК',
                                    clear: 'Очистить',
                                    month: 'Месяц',
                                    year: 'Год',
                                    timeSelect: 'Выбрать время',
                                    dateSelect: 'Выбрать день',
                                    monthSelect: 'Выбрать месяц',
                                    yearSelect: 'Выбрать год',
                                    decadeSelect: 'Выбрать десятилетие',
                                    yearFormat: 'YYYY',
                                    dateFormat: 'DD.MM.YYYY',
                                    dayFormat: 'D',
                                    dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
                                    monthFormat: 'MMMM',
                                    monthBeforeYear: true,
                                    previousMonth: 'Предыдущий месяц (PageUp)',
                                    nextMonth: 'Следующий месяц (PageDown)',
                                    previousYear: 'Предыдущий год (Control + left)',
                                    nextYear: 'Следующий год (Control + right)',
                                    previousDecade: 'Предыдущее десятилетие',
                                    nextDecade: 'Следующее лесятилетие',
                                    previousCentury: 'Предыдущий век',
                                    nextCentury: 'Следующий век'
                                },
                                timePickerLocale: {
                                    placeholder: 'Выбрать время'
                                },
                                dateFormat: 'DD.MM.YYYY',
                                dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
                                weekFormat: 'wo YYYY',
                                monthFormat: 'MM YYYY'
                            }}
                            dateRender={(currentDate) => {
                                let hasDayMessages = false;
                                const currentMonthHistory = monthsHistory.filter((item) => item.month === currentDate.format('YYYY-MM'));

                                if (currentMonthHistory.length) {
                                    currentMonthHistory[0].data.forEach((item) => {
                                        if (currentDate.format('YYYY-MM-DD') === item.date.format('YYYY-MM-DD')) {
                                            hasDayMessages = item.hasMessages;
                                        }
                                    });
                                }

                                return (
                                    <div className={`ant-picker-cell-inner ${hasDayMessages ? 'hasMessages' : ''}`}>
                                        {currentDate.date()}
                                    </div>
                                );
                            }}
                            onOpenChange={(open) => {
                                if (open) {
                                    dispatch(requestGetHistoryCalendarChatAction(currentChat, moment()));
                                }
                            }}
                            onPanelChange={(values) => {
                                if (values) {
                                    if (values[0]) {
                                        const monthHasLoaded = monthsHistory.filter((item) => item.month === values[0]?.format('YYYY-MM'));
                                        if (!monthHasLoaded.length) {
                                            dispatch(requestGetHistoryCalendarChatAction(currentChat, values[0]));
                                        }
                                    }
                                    if (values[1]) {
                                        const monthHasLoaded = monthsHistory.filter((item) => item.month === values[1]?.format('YYYY-MM'));
                                        if (!monthHasLoaded.length) {
                                            dispatch(requestGetHistoryCalendarChatAction(currentChat, values[1]));
                                        }
                                    }
                                }
                            }}
                            onChange={(dates) => {
                                if (dates && dates[0] !== undefined && dates[0] && dates[1] !== undefined && dates[1]) {
                                    dispatch(requestGetPeriodChatAction(currentChat, [+dates[0].format('x'), +dates[1].format('x')]));
                                }
                            }}
                        />
                        <Button icon={<ReloadOutlined />} onClick={() => dispatch(requestResetPeriodChatAction(currentChat))} />
                    </div>
                    <div className="header__actions">
                        <Button icon={<PrinterOutlined />} onClick={() => printHistory()} />
                        <Button
                            icon={<PlusCircleOutlined />}
                            onClick={() => dispatch(addingEngineerChatAction())}
                        >
                            {!isMobile && 'Добавить инженера'}
                        </Button>
                    </div>
                </div>
            ) : null}
            <div className="history" id="history" ref={historyRef} onScroll={onScrollHistory}>
                {messages?.map((messageItem, i) => {
                    const whoSender = messageItem.userID === auth.id ? 'me' : 'member';
                    const user = users?.filter((item) => item.id === messageItem.userID);
                    let displayName = '- Аккаунт удалён -';
                    let displayFullName = '- Аккаунт удалён -';
                    let userType = '';
                    const time = moment(messageItem.createdAt * 1000);
                    let statusIcon;
                    const attachments = messageItem.attachments as AttachemntItem[];

                    switch (messageItem.status) {
                        case 'sending':
                        case 'sended':
                            statusIcon = <SendedIcon />;
                            break;
                        case 'read':
                            statusIcon = <ReadedIcon />;
                            break;
                        case 'error':
                            statusIcon = <StopOutlined />;
                            break;
                        default:
                            statusIcon = null;
                            break;
                    }

                    if (user?.length) {
                        displayFullName = user[0].fio;
                        const tmp = user[0].fio.split(' ');
                        displayName = `${tmp[1]}`;
                        if (tmp[2] !== undefined) {
                            displayName = `${displayName} ${tmp[2]}`;
                        }

                        switch (user[0].type) {
                            case 'admin':
                                userType = 'Админ';
                                break;
                            case 'client':
                                userType = 'Клиент';
                                break;
                            case 'engineer':
                                userType = 'Инженер';
                                break;
                            case 'manager':
                                userType = 'Менеджер';
                                break;
                            default:
                                userType = '';
                                break;
                        }
                    }

                    const prevMessage = i !== 0 ? moment(messages[i - 1].createdAt * 1000) : moment(messages[i].createdAt * 1000);

                    return (
                        <div key={messageItem.id}>
                            {time.format('DD.MM.YYYY') !== prevMessage.format('DD.MM.YYYY') || i === 0 ? <div className="markdate"><span>{time.format('DD.MM.YYYY')}</span></div> : null}
                            <div className={`message ${whoSender}`}>
                                {whoSender === 'member' && <Avatar className="message__avatar">{userType[0]}</Avatar>}
                                <div className="body">
                                    {whoSender === 'member' && (
                                        <span className="body__name">
                                            <span title={displayFullName}>{displayName}</span>
                                            <span className="role">{userType}</span>
                                        </span>
                                    )}
                                    <span className="body__text">{messageItem.text}</span>
                                    {attachments.length ? (
                                        <span className="body__attachments">
                                            {attachments.map((attach) => {
                                                let icon = <FileOutlined />;
                                                if (attach.name.indexOf('pdf') !== -1) icon = <FilePdfOutlined />;
                                                if (attach.name.indexOf('jpg') !== -1 || attach.name.indexOf('jpeg') !== -1) icon = <FileJpgOutlined />;
                                                if (attach.name.indexOf('doc') !== -1 || attach.name.indexOf('docx') !== -1) icon = <FileWordOutlined />;

                                                return (
                                                    <button
                                                        key={attach.uid}
                                                        type="button"
                                                        className="attach"
                                                        title={attach.name}
                                                        onClick={() => downloadFile(attach.url)}
                                                    >
                                                        {icon}
                                                    </button>
                                                );
                                            })}
                                        </span>
                                    ) : null}
                                    <span className="body__time" title={time.format('DD.MM.YYYY HH:mm')}>{time.format('HH:mm')}</span>
                                    {whoSender === 'me' && <span className={`body__status ${messageItem.status}`}>{statusIcon}</span>}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="sendarea">
                <div className="sendarea__main">
                    <TextArea
                        placeholder="Написать сообщение..."
                        bordered={false}
                        autoSize={false}
                        value={messageText}
                        onChange={(e) => changeMessage(e.target.value)}
                        onKeyPress={(e) => {
                            if (!e.shiftKey && e.key === 'Enter') {
                                e.preventDefault();
                                sendMessage();
                            }
                        }}
                    />
                    <div className="sendarea__actions">
                        <Upload
                            name="attachments"
                            multiple
                            showUploadList={false}
                            fileList={uploadFiles}
                            beforeUpload={(file, fileList) => {
                                if (file.type === 'application/msword' || file.type === 'application/pdf' || file.type === 'image/jpeg') {
                                    changeUploadFiles([...uploadFiles, ...fileList]);
                                } else {
                                    notification('warning', 'Неверный формат файла', 'Для загрузки доступны только файлы: doc, docx, pdf, jpg.');
                                }
                                return false;
                            }}
                        >
                            <button type="button" className="attach" title="Прикрепить файл">
                                <PaperClipOutlined />
                            </button>
                        </Upload>
                        <button type="button" className="send" title="Отправить" onClick={() => sendMessage()}>
                            <SendOutlined />
                        </button>
                    </div>
                </div>
                {uploadFiles.length ? (
                    <div className="sendarea__attachments">
                        {uploadFiles.map((file) => {
                            let icon = <FileOutlined />;
                            if (file.name.indexOf('pdf') !== -1) icon = <FilePdfOutlined />;
                            if (file.name.indexOf('jpg') !== -1 || file.name.indexOf('jpeg') !== -1) icon = <FileJpgOutlined />;
                            if (file.name.indexOf('doc') !== -1 || file.name.indexOf('docx') !== -1) icon = <FileWordOutlined />;

                            return (
                                <div key={file.uid} className="attach">
                                    {icon}
                                    <div className="actions">
                                        <button
                                            type="button"
                                            className="actions__remove"
                                            onClick={() => changeUploadFiles(uploadFiles.filter((f) => file.uid !== f.uid))}
                                        >
                                            <DeleteOutlined />
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </div>
            <AddEngineer />
        </>
    );
};

export default OneChat;
