import { put, takeEvery } from 'redux-saga/effects';

import firebase, { db } from '../../utils/firebase';
import {
    addDatesAction,
    errorDatesAction,
    responseGetDatesAction,
    setAdditionalDataDatesAction,
    deleteDatesAction,
    responseAddDatesAction,
    responseUpdateDatesAction,
    responseDeleteDatesAction,
    updateDatesAction
} from '../actions/dates';
import {
    DatesActionsList,
    DatesItem,
    RequestAddDatesActionType,
    RequestGetDatesActionType,
    RequestDeleteDatesActionType,
    RequestUpdateDatesActionType
} from '../types/dates';
import { UsersItemShort } from '../types/users';
import { CompaniesItemShort } from '../types/companies';
import generateID from '../../utils/generateID';
import notification from '../../utils/notification';

function* getDatesWorker(effect: RequestGetDatesActionType) {
    try {
        const companies = db.collection('companies');
        const users = db.collection('users');
        const companiesSnapshot: firebase.firestore.QuerySnapshot = yield companies.get();
        const usersSnapshot: firebase.firestore.QuerySnapshot = yield users.get();

        let usersData: UsersItemShort[] = [];
        let companiesData: CompaniesItemShort[] = [];

        usersSnapshot.forEach((doc) => {
            const docData = doc.data();
            usersData = [...usersData, {
                id: doc.id,
                fio: docData.fio
            } as UsersItemShort];
        });
        companiesSnapshot.forEach((doc) => {
            const docData = doc.data();
            companiesData = [...companiesData, {
                id: doc.id,
                shortName: docData.shortName,
                fullName: docData.fullName
            } as CompaniesItemShort];
        });

        yield put(setAdditionalDataDatesAction(companiesData, usersData));
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorDatesAction('Не удалось загрузить данные'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные');
        console.log(error);
    }

    try {
        let dates;

        if (effect.companyID) {
            dates = db.collection('dates').where('companyID', '==', effect.companyID);
        } else {
            dates = db.collection('dates');
        }

        const datesSnapshot: firebase.firestore.QuerySnapshot = yield dates.get();

        if (!datesSnapshot.empty) {
            let data: DatesItem[] = [];
            datesSnapshot.forEach((doc) => {
                const docData = doc.data();
                const datesData: DatesItem = {
                    id: doc.id,
                    companyID: docData.companyID,
                    finished: docData.finished,
                    from: docData.from.seconds,
                    to: docData.to.seconds,
                    userID: docData.userID,
                    createdAt: docData.createdAt,
                    updatedAt: docData.updatedAt
                };

                data = [...data, datesData];
            });

            yield put(responseGetDatesAction(data));
        } else {
            yield put(responseGetDatesAction([]));
        }
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorDatesAction('Не удалось загрузить данные'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные');
        console.log(error);
    }
}

function* addDatesWorker(effect: RequestAddDatesActionType) {
    const id = generateID();

    try {
        yield db.collection('dates').doc(id).set({
            ...effect.data,
            from: firebase.firestore.Timestamp.fromMillis(effect.data.from * 1000),
            to: firebase.firestore.Timestamp.fromMillis(effect.data.to * 1000),
            createdAt: effect.data.createdAt !== undefined && firebase.firestore.Timestamp.fromMillis(effect.data.createdAt * 1000),
            updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.updatedAt * 1000)
        });
        yield put(responseAddDatesAction());
        yield put(addDatesAction({
            ...effect.data,
            id
        }));
        notification('success', 'Успех', 'Данные успешно добавлены');
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorDatesAction('Не удалось создать данные'));
        notification('error', 'Ошибка', 'Не удалось создать данные');
        console.log(error);
    }
}

function* updateDatesWorker(effect: RequestUpdateDatesActionType) {
    try {
        yield db.collection('dates').doc(effect.dateID).update({
            ...effect.data,
            from: firebase.firestore.Timestamp.fromMillis(effect.data.from * 1000),
            to: firebase.firestore.Timestamp.fromMillis(effect.data.to * 1000),
            updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.updatedAt * 1000)
        });
        yield put(responseUpdateDatesAction());
        yield put(updateDatesAction({
            ...effect.data,
            id: effect.dateID
        }));
        notification('success', 'Успех', 'Данные успешно обновлены');
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorDatesAction('Не удалось обновить данные'));
        notification('error', 'Ошибка', 'Не удалось обновить данные');
        console.log(error);
    }
}

function* deleteDatesWorker(effect: RequestDeleteDatesActionType) {
    try {
        yield db.collection('dates').doc(effect.dateID).delete();
        yield put(responseDeleteDatesAction());
        yield put(deleteDatesAction(effect.dateID));
        notification('success', 'Успех', 'Данные успешно удалены');
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorDatesAction('Не удалось удалить данные'));
        notification('error', 'Ошибка', 'Не удалось удалить данные');
        console.log(error);
    }
}

export function* datesWatcher() {
    yield takeEvery(DatesActionsList.REQUEST_GET_DATES, getDatesWorker);
    yield takeEvery(DatesActionsList.REQUEST_ADD_DATES, addDatesWorker);
    yield takeEvery(DatesActionsList.REQUEST_UPDATE_DATES, updateDatesWorker);
    yield takeEvery(DatesActionsList.REQUEST_DELETE_DATES, deleteDatesWorker);
}
