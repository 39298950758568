/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Layout,
    Breadcrumb,
    Typography,
    Button,
    Badge,
    Popover
} from 'antd';
import {
    DesktopOutlined,
    FileAddOutlined,
    HomeOutlined,
    PieChartOutlined,
    PoweroffOutlined,
    QuestionCircleOutlined,
    BellOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';

import logo from '../../assets/logo-menu.svg';
import './style.scss';
import { requestSignOutAction } from '../../store/actions/auth';
import MainMenu from '../../components/MainMenu';
import AddOffer from '../../pages/Offers/Add';
import routes from '../../router';
import { creatingOffersAction } from '../../store/actions/offers';
import { AppState } from '../../store';
import generateNotification from '../../utils/generateNotification';
import { removeNotifyAction } from '../../store/actions/notifications';

const { Title } = Typography;
const {
    Header, Content, Sider
} = Layout;
const companyName = 'NSS Company';

const isMobile = window.matchMedia('(max-width: 991px)').matches;

const WrapperPage = <P extends Record<string, unknown>>(Component: React.ComponentType<P>) => (props: P) : JSX.Element => {
    const [collapsed, onCollapse] = useState(isMobile);
    const [pageName, changePagename] = useState(companyName);
    const [pageNameClass, changePageNameClass] = useState('');
    const [notificationsContent, changeNotificationsContent] = useState<React.ReactNode | null>(null);
    const [prevPopoverVisible, changePrevPopoverVisible] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const auth = useSelector((state: AppState) => state.auth);
    const notifications = useSelector((state: AppState) => state.notifications);
    const { account } = auth;

    useEffect(() => {
        const currentPage = routes.filter((item) => item.path === location.pathname)[0];
        changePageNameClass(currentPage.path.replace('/', ''));

        if (currentPage.nameInPage !== undefined) {
            document.title = `${currentPage.nameInPage} | NSS CRM`;
            changePagename(currentPage.nameInPage);
        } else {
            document.title = companyName;
            changePagename(companyName);
        }
    }, [location]);

    useEffect(() => {
        if (!notifications.loading) {
            changeNotificationsContent((
                <div style={{ overflow: 'auto', maxHeight: 300 }}>
                    {notifications.data.map((notify) => (
                        <p key={`notify-${notify.date}`}>{generateNotification(notify)}</p>
                    ))}
                </div>
            ));
        } else {
            changeNotificationsContent((
                <div>
                    <p>Нет оповещений</p>
                </div>
            ));
        }
    }, [notifications]);

    return (
        <>
            <Layout>
                <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                    <div className="logo" style={{ backgroundImage: `url('${logo}')` }} />
                    <MainMenu />
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background">
                        <Title level={3} className="app__main-title">{pageName}</Title>
                        <div className="header__right">
                            <Badge count={notifications.data.length} className="header__notify">
                                <Popover
                                    placement="bottomRight"
                                    title="Оповещения"
                                    content={notificationsContent}
                                    trigger="click"
                                    onVisibleChange={(visible) => {
                                        changePrevPopoverVisible(visible);

                                        if (prevPopoverVisible === true) {
                                            dispatch(removeNotifyAction());
                                        }
                                    }}
                                >
                                    <button type="button"><BellOutlined /></button>
                                </Popover>
                            </Badge>
                            {account === 'client' && (
                                <>
                                    <Button type="ghost" icon={<QuestionCircleOutlined />} onClick={() => window.open(`${process.env.PUBLIC_URL}/client_instruction.pdf`)}>Инструкция</Button>
                                    <Button type="primary" icon={<FileAddOutlined />} onClick={() => dispatch(creatingOffersAction())}>{!isMobile && 'Запросить КП'}</Button>
                                </>
                            )}
                            <Button type="primary" icon={<PoweroffOutlined />} onClick={() => dispatch(requestSignOutAction())} danger />
                        </div>
                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                        <Breadcrumb style={{ margin: '16px 0' }}>
                            <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
                            <Breadcrumb.Item>{pageName}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className={`site-layout-background page__wrapper ${pageNameClass}`}>
                            <Component {...props as P} />
                        </div>
                    </Content>
                </Layout>
            </Layout>
            {account === 'client' && <AddOffer />}
        </>
    );
};

export default WrapperPage;
