import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Table,
    Skeleton,
    Empty
} from 'antd';
import {
    SaveOutlined,
    DeleteOutlined,
    EditOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { RequiredMark } from 'antd/lib/form/Form';
import firebase from '../../utils/firebase';

import './style.scss';
import WrapperPage from '../../containers/WrapperPage';
import { AppState } from '../../store';
import { OffersItem } from '../../store/types/offers';
import {
    requestDeleteOffersAction,
    requestGetOffersAction,
    requestUpdateOffersAction
} from '../../store/actions/offers';
import Preloader from '../../components/Preloader';

const { Column } = Table;
const { TextArea } = Input;

const OffersPage:React.FC = () => {
    const [formEdit] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>(true);
    const [editing, setEdit] = useState<string | null>(null);
    const auth = useSelector((state: AppState) => state.auth);
    const offers = useSelector((state: AppState) => state.offers);
    const dispatch = useDispatch();
    const {
        loading,
        data,
        companies,
        editing: editingID
    } = offers;
    const { account } = auth;

    const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    useEffect(() => {
        dispatch(requestGetOffersAction());
    }, []);

    const onDelete = (id: string) => {
        dispatch(requestDeleteOffersAction(id));
    };

    const onEdit = (values: any) => {
        const time = firebase.firestore.Timestamp.now();

        if (editing) {
            dispatch(requestUpdateOffersAction({
                id: editing,
                companyID: '',
                text: values.text,
                updatedAt: time.seconds
            }));
        }

        formEdit.resetFields();
        setEdit(null);
    };

    if (data === null) {
        return <Preloader />;
    }

    return (
        <div className="offers page">
            <Row gutter={15}>
                <Col span={24}>
                    <Form
                        form={formEdit}
                        layout="vertical"
                        onValuesChange={onRequiredTypeChange}
                        requiredMark={requiredMark}
                        onFinish={onEdit}
                    >
                        <Table
                            dataSource={data}
                            pagination={false}
                            rowKey={((record) => `offers_${record.id}`)}
                            locale={{
                                emptyText: <Empty description="Нет данных" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }}
                        >
                            <Column
                                title="Компания"
                                dataIndex="companyID"
                                key="companyID"
                                width="30%"
                                render={(value, record: OffersItem) => {
                                    const company = companies?.filter((item) => item.id === record.companyID);

                                    if (editing && record.id === editing && company?.length) {
                                        return (
                                            <>
                                                <Form.Item name="companyID" style={{ display: 'none' }}>
                                                    <Input disabled readOnly hidden />
                                                </Form.Item>
                                                {company?.length ? company[0].shortName : '- Компания удалена -'}
                                            </>
                                        );
                                    }

                                    return company?.length ? company[0].shortName : '- Компания удалена -';
                                }}
                            />
                            <Column
                                title="Текст КП"
                                dataIndex="text"
                                key="text"
                                width="55%"
                                render={(value, record: OffersItem) => {
                                    if (record.id === editingID && loading) return <Skeleton active paragraph={{ rows: 4 }} title={false} />;

                                    if (editing && record.id === editing) {
                                        return (
                                            <Form.Item name="text" required>
                                                <TextArea placeholder="Текст КП" autoSize={{ minRows: 5, maxRows: 5 }} />
                                            </Form.Item>
                                        );
                                    }

                                    return record.text.length ? record.text : '- Пусто -';
                                }}
                            />
                            <Column
                                title="Дата создания"
                                dataIndex="createdAt"
                                key="text"
                                render={(value, record: OffersItem) => {
                                    const timestamp = record.createdAt as any;
                                    return record.createdAt !== undefined ? moment(timestamp.seconds * 1000).format('DD.MM.YYYY') : '-';
                                }}
                                sorter={(a, b) => {
                                    const timestampA = a.createdAt as any;
                                    const timestampB = b.createdAt as any;
                                    return timestampA.seconds - timestampB.seconds;
                                }}
                            />
                            {['admin', 'manager'].includes(account || '') ? (
                                <Column
                                    key="actions"
                                    render={(value, record: OffersItem) => (
                                        <>
                                            <Button
                                                type="primary"
                                                icon={editing && record.id === editing ? <SaveOutlined /> : <EditOutlined />}
                                                loading={record.id === editingID && loading}
                                                onClick={() => {
                                                    if (record.id === editing) {
                                                        formEdit.submit();
                                                    } else {
                                                        setEdit(record.id);
                                                        formEdit.setFieldsValue({
                                                            companyID: record.companyID,
                                                            text: record.text
                                                        });
                                                    }
                                                }}
                                            />
                                            {account !== 'manager' ? (
                                                <Button
                                                    type="primary"
                                                    icon={editing && record.id === editing ? <CloseOutlined /> : <DeleteOutlined />}
                                                    danger
                                                    loading={record.id === editingID && loading}
                                                    onClick={() => {
                                                        if (record.id === editing) {
                                                            setEdit(null);
                                                            formEdit.resetFields();
                                                        } else {
                                                            onDelete(record.id);
                                                        }
                                                    }}
                                                />
                                            ) : null}
                                        </>
                                    )}
                                />
                            ) : null}
                        </Table>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default WrapperPage(OffersPage);
