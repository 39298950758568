/* eslint-disable new-cap */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dropdown,
    Menu,
    Typography
} from 'antd';
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
    PieChart, Pie, Tooltip, ResponsiveContainer
} from 'recharts';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import Preloader from '../../../components/Preloader';
import { AppState } from '../../../store';
import { requestPopularEquipmentsStatisticAction } from '../../../store/actions/statistic';

const { Title } = Typography;

const PopularEquipments:React.FC = () => {
    const dispatch = useDispatch();
    const statistic = useSelector((state: AppState) => state.statistic);
    const { popularEquipments } = statistic;
    const { data } = popularEquipments;

    useEffect(() => {
        dispatch(requestPopularEquipmentsStatisticAction());
    }, []);

    const exportExcel = () => {
        let columns: any = [['Название оборудования', 'Кол-во оборудования']];

        if (data) {
            data.forEach((item) => {
                columns = [...columns, [item.name, item.value]];
            });
        }

        const worksheet = XLSX.utils.aoa_to_sheet(columns);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Популярное оборудование');

        XLSX.writeFile(workbook, 'Популярное_оборудование.xlsx');
    };

    const exportPdf = () => {
        const statisticBlock = document.querySelector('#PopularEquipments') as HTMLElement;

        if (statisticBlock) {
            html2canvas(statisticBlock).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'pt', 'a4', false);
                pdf.addImage(imgData, 'PNG', 0, 0, 600, 0);
                pdf.save('Популярное_оборудование.pdf');
            });
        }
    };

    if (data === null) {
        return <Preloader />;
    }

    const actions = (
        <Menu>
            <Menu.Item key="pdf" onClick={() => exportPdf()}>
                <FilePdfOutlined />
                &nbsp;
                PDF
            </Menu.Item>
            <Menu.Item key="excel" onClick={() => exportExcel()}>
                <FileExcelOutlined />
                &nbsp;
                EXCEL
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <div className="dashboard__header">
                <Title level={4}>Популярное оборудование</Title>
                <Dropdown overlay={actions}>
                    <Button icon={<DownloadOutlined />} />
                </Dropdown>
            </div>
            <ResponsiveContainer width="100%" height={300}>
                <PieChart width={400} height={400}>
                    <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        paddingAngle={5}
                        fill="#a2ce70"
                        label
                    />
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </>
    );
};
export default PopularEquipments;
