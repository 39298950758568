import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Divider,
    Modal
} from 'antd';
import { ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { RequiredMark } from 'antd/lib/form/Form';

import { requestGetCompaniesAction, requestUpdateCompaniesAction } from '../../store/actions/companies';
import WrapperPage from '../../containers/WrapperPage';
import { AppState } from '../../store';
import Preloader from '../../components/Preloader';

const CompanyPage:React.FC = () => {
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>(true);
    const auth = useSelector((state: AppState) => state.auth);
    const companies = useSelector((state: AppState) => state.companies);
    const dispatch = useDispatch();
    const { loading, data } = companies;

    const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    useEffect(() => {
        dispatch(requestGetCompaniesAction(auth.company));

        if (localStorage.getItem('manualReaded') && localStorage.getItem('manualReaded') === 'false') {
            Modal.confirm({
                title: 'Добро пожаловать!',
                icon: <ExclamationCircleOutlined />,
                content: 'Мы подготовили инструкцию по работе в системе, чтобы Вы могли быстрей в ней освоиться. Нажмите «Ок», чтобы прочитать.',
                okText: 'Ок',
                cancelText: 'Больше не показывать',
                onOk: () => {
                    window.open(`${process.env.PUBLIC_URL}/client_instruction.pdf`);
                },
                onCancel: () => {
                    localStorage.setItem('manualReaded', 'true');
                }
            });
        }
    }, []);

    const onFinish = (values: any) => {
        if (auth.company) {
            dispatch(requestUpdateCompaniesAction(auth.company, values));
        }
    };

    if (data === null) {
        return <Preloader />;
    }

    return (
        <div className="page company">
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    shortName: companies.data ? companies.data[0].shortName : '',
                    fullName: companies.data ? companies.data[0].fullName : '',
                    inn: companies.data ? companies.data[0].inn : '',
                    kpp: companies.data ? companies.data[0].kpp : '',
                    ogrn: companies.data ? companies.data[0].ogrn : '',
                    bankName: companies.data ? companies.data[0].bankName : '',
                    bankBIK: companies.data ? companies.data[0].bankBIK : '',
                    сorrespondentNumber: companies.data ? companies.data[0].сorrespondentNumber : '',
                    paymentNumber: companies.data ? companies.data[0].paymentNumber : ''
                }}
                onValuesChange={onRequiredTypeChange}
                requiredMark={requiredMark}
                onFinish={onFinish}
            >
                <Row gutter={15}>
                    <Divider>Основная информация</Divider>
                    <Col span={24} lg={12}>
                        <Form.Item label="Краткое назание" name="shortName" tooltip="Краткое название организации" required>
                            <Input placeholder="Краткое назание" name="shortName" />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={12}>
                        <Form.Item label="Полное название" name="fullName" tooltip="Полное название организации">
                            <Input placeholder="Полное название" name="fullName" />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <Form.Item label="ИНН" name="inn" tooltip="ИНН организации" required>
                            <Input placeholder="ИНН" name="inn" />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <Form.Item label="КПП" name="kpp" tooltip="КПП организации">
                            <Input placeholder="КПП" name="kpp" />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <Form.Item label="ОГРН" name="ogrn" tooltip="ОГРН организации">
                            <Input placeholder="ОГРН" name="ogrn" />
                        </Form.Item>
                    </Col>
                    <Divider>Банковские реквизиты</Divider>
                    <Col span={24} lg={6}>
                        <Form.Item label="Наименование банка" name="bankName" tooltip="Наименование банка">
                            <Input placeholder="Наименование банка" name="bankName" />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={6}>
                        <Form.Item label="БИК" name="bankBIK" tooltip="БИК">
                            <Input placeholder="БИК" name="bankBIK" />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={6}>
                        <Form.Item label="К/С" name="сorrespondentNumber" tooltip="Корреспондентский счёт">
                            <Input placeholder="К/С" name="сorrespondentNumber" />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={6}>
                        <Form.Item label="Р/С" name="paymentNumber" tooltip="Расчётный счёт">
                            <Input placeholder="Р/С" name="paymentNumber" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Row justify="center">
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    icon={<SaveOutlined />}
                                    loading={loading}
                                >
                                    Сохранить
                                </Button>
                            </Form.Item>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default WrapperPage(CompanyPage);
