import React from 'react';
import { useSelector } from 'react-redux';
import {
    Route,
    Redirect,
    RouteProps
} from 'react-router-dom';
import { AppState } from '../../store';
import { AccountsType } from '../../store/types/auth';

interface PrivateRouteProps extends RouteProps {
    permisions: AccountsType[] | null
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
    const auth = useSelector((state: AppState) => state.auth);
    const { path, permisions } = props;

    if (!auth.isAuth) {
        return <Route {...props} component={() => <Redirect to={{ pathname: '/login' }} />} />;
    }
    if ((auth.isAuth && path === '/login') || (auth.isAuth && path === '/registration')) {
        return <Route {...props} component={() => <Redirect to={{ pathname: '/' }} />} />;
    }
    if (permisions && !permisions.includes(auth.account as AccountsType)) {
        return <Route {...props} component={() => <Redirect to={{ pathname: '/403' }} />} />;
    }
    if (auth.isAuth && path === '/') {
        switch (auth.account) {
            case 'admin':
            case 'manager': {
                return <Route {...props} component={() => <Redirect to={{ pathname: '/offers' }} />} />;
            }
            case 'engineer': return <Route {...props} component={() => <Redirect to={{ pathname: '/dates' }} />} />;
            case 'client': return <Route {...props} component={() => <Redirect to={{ pathname: '/company' }} />} />;
            default: return <Route {...props} component={() => <Redirect to={{ pathname: '/404' }} />} />;
        }
    }
    return <Route {...props} />;
};

export default PrivateRoute;
