import { AccountsType } from './auth';
import { CompaniesItem, CompaniesItemData } from './companies';

export interface UsersItemData {
    company: string | null,
    fio: string,
    position: string,
    phone: string,
    email: string,
    uid: string,
    type: AccountsType,
    createdAt?: number,
    updatedAt: number
}

export interface UsersCreateData {
    type: AccountsType,
    email: string,
    password: string,
    fio: string,
    position: string,
    phone: string,
    inn?: string,
    shortName?: string
    createdAt?: number,
    updatedAt: number,
}

export interface UsersUpdateData {
    fio: string,
    position: string,
    phone: string,
    updatedAt: number
}

export interface UsersItem extends UsersItemData {
    id: string
}

export interface UsersItemShort {
    id: string,
    fio: string
}

export interface UsersStateType {
    loading: boolean,
    data: UsersItem[] | null,
    companies: CompaniesItem[] | null,
    creating: boolean,
    viewing: { userID: string | null, companyID: string | null } | null,
    editing: { userID: string | null, companyID: string | null } | null,
    error: string | null
}

export enum UsersActionsList {
    USERS_REQUEST_GET = 'USERS_REQUEST_GET',
    USERS_RESPONSE_GET = 'USERS_RESPONSE_GET',
    USERS_ERROR = 'USERS_ERROR',
    USERS_CREATING = 'USERS_CREATING',
    USERS_VIEWING = 'USERS_VIEWING',
    USERS_EDITING = 'USERS_EDITING',
    USERS_REQUEST_CREATE = 'USERS_REQUEST_CREATE',
    USERS_RESPONSE_CREATE = 'USERS_RESPONSE_CREATE',
    USERS_REQUEST_UPDATE = 'USERS_REQUEST_UPDATE',
    USERS_RESPONSE_UPDATE = 'USERS_RESPONSE_UPDATE',
    USERS_REQUEST_DELETE = 'USERS_REQUEST_DELETE',
    USERS_RESPONSE_DELETE = 'USERS_RESPONSE_DELETE',
    USERS_SET_ADDITIONAL_DATA = 'USERS_SET_ADDITIONAL_DATA',
    RESPONSE_SIGNOUT_AUTH = 'RESPONSE_SIGNOUT_AUTH'
}

export interface RequestGetUsersAction {
    type: UsersActionsList.USERS_REQUEST_GET,
    userID: string | null,
    userType: string | null
}

export interface ResponseGetUsersAction {
    type: UsersActionsList.USERS_RESPONSE_GET,
    data: UsersItem[]
}

export interface ErrorUsersAction {
    type: UsersActionsList.USERS_ERROR,
    error: string
}

export interface CreatingUsersAction {
    type: UsersActionsList.USERS_CREATING
}

export interface EditingUsersAction {
    type: UsersActionsList.USERS_EDITING,
    userID: string | null,
    companyID: string | null
}

export interface ViewingUsersAction {
    type: UsersActionsList.USERS_VIEWING,
    userID: string | null,
    companyID: string | null
}

export interface RequestCreateUsersAction {
    type: UsersActionsList.USERS_REQUEST_CREATE,
    data: UsersCreateData
}

export interface ResponseCreateUsersAction {
    type: UsersActionsList.USERS_RESPONSE_CREATE,
    data: UsersItem
}

export interface RequestUpdateUsersAction {
    type: UsersActionsList.USERS_REQUEST_UPDATE,
    userID: string,
    companyID: string | null,
    data: {
        user: UsersUpdateData,
        company: CompaniesItemData | null
    }
}

export interface ResponseUpdateUsersAction {
    type: UsersActionsList.USERS_RESPONSE_UPDATE,
    userID: string,
    companyID: string | null,
    data: {
        user: UsersUpdateData,
        company: CompaniesItemData | null
    }
}

export interface RequestDeleteUsersAction {
    type: UsersActionsList.USERS_REQUEST_DELETE,
    id: string
}

export interface ResponseDeleteUsersAction {
    type: UsersActionsList.USERS_RESPONSE_DELETE,
    id: string
}

export interface SetAdditionalDataOffersActionType {
    type: UsersActionsList.USERS_SET_ADDITIONAL_DATA,
    companies: CompaniesItem[]
}

export interface ResponseSignOutActionType {
    type: UsersActionsList.RESPONSE_SIGNOUT_AUTH
}

export type UsersActionsType =
RequestGetUsersAction
| ResponseGetUsersAction
| ErrorUsersAction
| CreatingUsersAction
| ViewingUsersAction
| EditingUsersAction
| RequestCreateUsersAction
| ResponseCreateUsersAction
| RequestUpdateUsersAction
| ResponseUpdateUsersAction
| RequestDeleteUsersAction
| ResponseDeleteUsersAction
| SetAdditionalDataOffersActionType
| ResponseSignOutActionType;
