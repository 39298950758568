import { put, takeEvery } from 'redux-saga/effects';

import firebase, { db } from '../../utils/firebase';
import {
    errorNotifyAction,
    requestAdditionalDataNotifyAction,
    responseAdditionalDataNotifyAction
} from '../actions/notifications';
import {
    NotificationsActionsList
} from '../types/notifications';
import notification from '../../utils/notification';
import { CompaniesItemShort } from '../types/companies';

function* getNotificationsWorker() {
    yield put(requestAdditionalDataNotifyAction());
    try {
        const companies = db.collection('companies');
        const companiesSnapshot: firebase.firestore.QuerySnapshot = yield companies.get();

        let companiesData: CompaniesItemShort[] = [];

        companiesSnapshot.forEach((doc) => {
            const docData = doc.data();
            companiesData = [...companiesData, {
                id: doc.id,
                shortName: docData.shortName,
                fullName: docData.fullName
            } as CompaniesItemShort];
        });

        yield put(responseAdditionalDataNotifyAction(companiesData));
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorNotifyAction('Не удалось загрузить данные'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные');
        console.log(error);
    }
}

export function* notificationsWatcher() {
    yield takeEvery(NotificationsActionsList.GET_NOTIFY, getNotificationsWorker);
}
