import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
    Row,
    Col,
    Form,
    Input,
    Button
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import logo from '../../assets/logo.svg';

import { requestSignInAction } from '../../store/actions/auth';
import { AppState } from '../../store';

const LoginPage:React.FC = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const auth = useSelector((state: AppState) => state.auth);
    const { isAuth, loading } = auth;

    const onFinish = (values: any) => {
        dispatch(requestSignInAction(values.login, values.password, null));
    };

    if (isAuth) {
        return <Redirect to="/" />;
    }

    return (
        <section className="auth login page">
            <Row justify="space-around" align="middle">
                <Col span={25} lg={5}>
                    <Form
                        form={form}
                        className="login-form"
                        onFinish={onFinish}
                    >
                        <Form.Item>
                            <img src={logo} alt="NSS" className="auth__logo" />
                        </Form.Item>
                        <Form.Item
                            name="login"
                            rules={[{ required: true, message: 'Введите email или ИНН' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email/ИНН" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Введите пароль' }]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Пароль"
                            />
                        </Form.Item>
                        {/* <Form.Item>
                            <a className="login-form-forgot" href="">
                                Забыли пароль?
                            </a>
                        </Form.Item> */}
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                loading={loading}
                            >
                                Войти
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                className="login-form-button"
                                loading={loading}
                            >
                                <Link to="/register">
                                    Зарегистрироваться
                                </Link>
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <p style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                textAlign: 'center'
            }}
            >
                Возникли проблемы? Напишите нам&nbsp;
                <a href="mailto:support@zipnss.ru">support@zipnss.ru</a>
            </p>
        </section>
    );
};

export default LoginPage;
