import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Button,
    Typography,
    Space,
    Tabs
} from 'antd';

import { AppState } from '../../../store';
import { viewingUsersAction } from '../../../store/actions/users';
import { CompaniesItem } from '../../../store/types/companies';
import { UsersItem } from '../../../store/types/users';

const { Text } = Typography;
const { TabPane } = Tabs;

const View:React.FC = () => {
    const dispatch = useDispatch();
    const users = useSelector((state: AppState) => state.users);
    const auth = useSelector((state: AppState) => state.auth);
    const {
        data,
        companies,
        viewing
    } = users;
    const [user, changeUser] = useState<UsersItem | null>(null);
    const [company, changeCompany] = useState<CompaniesItem | null>(null);

    useEffect(() => {
        if (viewing) {
            if (viewing.userID) {
                if (data) {
                    const dataUser = data.filter((item) => item.id === viewing.userID);
                    if (dataUser.length) {
                        changeUser(dataUser[0]);
                    } else {
                        changeUser(null);
                    }
                } else {
                    changeUser(null);
                }
            }
            if (viewing.companyID) {
                if (companies) {
                    const dataCompany = companies.filter((item) => item.id === viewing.companyID);
                    if (dataCompany.length) {
                        changeCompany(dataCompany[0]);
                    } else {
                        changeCompany(null);
                    }
                } else {
                    changeCompany(null);
                }
            }
        } else {
            changeUser(null);
            changeCompany(null);
        }
    }, [viewing]);

    const onClose = () => {
        dispatch(viewingUsersAction());
    };

    return (
        <Modal
            title="Просмотр профиля"
            centered
            visible={viewing !== null}
            onCancel={onClose}
            footer={[
                <Button key="back" type="primary" onClick={onClose}>
                    Закрыть
                </Button>
            ]}
        >
            <Tabs type="card">
                {user && (
                    <TabPane tab="Пользователь" key="1">
                        <Space direction="vertical">
                            <div>
                                <Text strong>Тип аккаунта:&nbsp;</Text>
                                <Text>{user.type}</Text>
                            </div>
                            <div>
                                <Text strong>Email:&nbsp;</Text>
                                <Text>{user.email}</Text>
                            </div>
                            <div>
                                <Text strong>Ф.И.О.:&nbsp;</Text>
                                <Text>{user.fio}</Text>
                            </div>
                            <div>
                                <Text strong>Должность:&nbsp;</Text>
                                <Text>{user.position}</Text>
                            </div>
                            <div>
                                <Text strong>Телефон:&nbsp;</Text>
                                <Text>{user.phone}</Text>
                            </div>
                        </Space>
                    </TabPane>
                )}
                {company && (
                    <TabPane tab="Компания" key="2">
                        <Space direction="vertical">
                            <div>
                                <Text strong>Краткое название:&nbsp;</Text>
                                <Text>{company.shortName}</Text>
                            </div>
                            <div>
                                <Text strong>Полное название:&nbsp;</Text>
                                <Text>{company.fullName}</Text>
                            </div>
                            <div>
                                <Text strong>ИНН:&nbsp;</Text>
                                <Text>{company.inn}</Text>
                            </div>
                            <div>
                                <Text strong>КПП:&nbsp;</Text>
                                <Text>{company.kpp}</Text>
                            </div>
                            <div>
                                <Text strong>ОГРН:&nbsp;</Text>
                                <Text>{company.ogrn}</Text>
                            </div>
                            <div>
                                <Text strong>Банк:&nbsp;</Text>
                                <Text>{company.bankName}</Text>
                            </div>
                            <div>
                                <Text strong>БИК:&nbsp;</Text>
                                <Text>{company.bankBIK}</Text>
                            </div>
                            <div>
                                <Text strong>К/C:&nbsp;</Text>
                                <Text>{company.сorrespondentNumber}</Text>
                            </div>
                            <div>
                                <Text strong> Р/C:&nbsp;</Text>
                                <Text>{company.paymentNumber}</Text>
                            </div>
                        </Space>
                    </TabPane>
                )}
            </Tabs>
        </Modal>
    );
};

export default View;
