export interface EquipmentItemData {
    companyID: string,
    mark: string,
    model: string,
    serial: string,
    year: string,
    count: number,
    build: string,
    details: EquipmentDetailItem[],
    createdAt?: number,
    updatedAt: number
}

export interface EquipmentDetailItemData {
    companyID: string,
    name: string,
    serial: string,
    count: number,
    build: string,
    createdAt?: number,
    updatedAt: number
}

export interface EquipmentItem extends EquipmentItemData {
    id: string
}

export interface EquipmentDetailItem extends EquipmentDetailItemData {
    id: string
}

export interface EquipmentsStateType {
    loading: boolean,
    data: EquipmentItem[] | null,
    deleteFromServer: {
        equipmentsIDs: string[],
        detailsIDs: string[]
    },
    createToServer: {
        equipmentsIDs: string[],
        detailsIDs: string[]
    },
    resetForm: boolean,
    error: string | null
}

export interface EquipmentFieldValue {
    [name: string]: string | number
}

export enum EquipmentsActionsList {
    REQUEST_GET_EQUIPMENTS = 'REQUEST_GET_EQUIPMENTS',
    RESPONSE_GET_EQUIPMENTS = 'RESPONSE_GET_EQUIPMENTS',
    REQUEST_SAVE_EQUIPMENTS = 'REQUEST_SAVE_EQUIPMENTS',
    RESPONSE_SAVE_EQUIPMENTS = 'RESPONSE_SAVE_EQUIPMENTS',
    ERROR_EQUIPMENTS = 'ERROR_EQUIPMENTS',
    ADD_EQUIPMENTS = 'ADD_EQUIPMENTS',
    UPDATE_EQUIPMENTS = 'UPDATE_EQUIPMENTS',
    DELETE_EQUIPMENTS = 'DELETE_EQUIPMENTS',
    ADD_EQUIPMENTS_DETAIL = 'ADD_EQUIPMENTS_DETAIL',
    UPDATE_EQUIPMENTS_DETAIL = 'UPDATE_EQUIPMENTS_DETAIL',
    DELETE_EQUIPMENTS_DETAIL = 'DELETE_EQUIPMENTS_DETAIL',
    SET_EQUIPMENTS_TEMPLATE = 'SET_EQUIPMENTS_TEMPLATE',
    RESET_FORM_EQUIPMENTS = 'RESET_FORM_EQUIPMENTS',
    RESPONSE_SIGNOUT_AUTH = 'RESPONSE_SIGNOUT_AUTH'
}

export interface ErrorEquipmentsActionType {
    type: EquipmentsActionsList.ERROR_EQUIPMENTS,
    error: string
}

export interface RequestGetEquipmentsActionType {
    type: EquipmentsActionsList.REQUEST_GET_EQUIPMENTS,
    companyID: string | null,
    fromCache: boolean
}

export interface ResponseGetEquipmentsActionType {
    type: EquipmentsActionsList.RESPONSE_GET_EQUIPMENTS,
    data: EquipmentItem[]
}

export interface RequestSaveEquipmentsActionType {
    type: EquipmentsActionsList.REQUEST_SAVE_EQUIPMENTS,
    creating: {
        equipments: EquipmentItem[],
        details: EquipmentDetailItem[]
    },
    deleting: {
        equipments: string[],
        details: string[]
    }
}

export interface ResponseSaveEquipmentsActionType {
    type: EquipmentsActionsList.RESPONSE_SAVE_EQUIPMENTS
}

export interface AddEquipmentsActionType {
    type: EquipmentsActionsList.ADD_EQUIPMENTS,
    companyID: string | null
}

export interface UpdateEquipmentsActionType {
    type: EquipmentsActionsList.UPDATE_EQUIPMENTS,
    equipmentID: string,
    value: EquipmentFieldValue
}

export interface DeleteEquipmentsActionType {
    type: EquipmentsActionsList.DELETE_EQUIPMENTS,
    equipment: EquipmentItem
}

export interface AddEquipmentsDetailActionType {
    type: EquipmentsActionsList.ADD_EQUIPMENTS_DETAIL,
    equipmentID: string
}

export interface UpdateEquipmentsDetailActionType {
    type: EquipmentsActionsList.UPDATE_EQUIPMENTS_DETAIL,
    detailID: string,
    value: EquipmentFieldValue
}

export interface DeleteEquipmentsDetailActionType {
    type: EquipmentsActionsList.DELETE_EQUIPMENTS_DETAIL,
    equipmentID: string,
    detailID: string
}

export interface SetEquipmentsTemplateActionType {
    type: EquipmentsActionsList.SET_EQUIPMENTS_TEMPLATE,
    template: EquipmentItem[]
}

export interface ResetFormEquipmentActionType {
    type: EquipmentsActionsList.RESET_FORM_EQUIPMENTS,
    reset: boolean
}

export interface ResponseSignOutActionType {
    type: EquipmentsActionsList.RESPONSE_SIGNOUT_AUTH
}

export type EquipmentsActionsType =
ErrorEquipmentsActionType
| RequestGetEquipmentsActionType
| ResponseGetEquipmentsActionType
| RequestSaveEquipmentsActionType
| ResponseSaveEquipmentsActionType
| AddEquipmentsActionType
| UpdateEquipmentsActionType
| DeleteEquipmentsActionType
| AddEquipmentsDetailActionType
| UpdateEquipmentsDetailActionType
| DeleteEquipmentsDetailActionType
| SetEquipmentsTemplateActionType
| ResetFormEquipmentActionType
| ResponseSignOutActionType;
