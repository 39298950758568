import { put, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import moment from 'moment';

import firebase, { db } from '../../utils/firebase';
import {
    errorOffersAction,
    responseAddOffersAction,
    responseDeleteOffersAction,
    responseGetOffersAction,
    responseUpdateOffersAction,
    setAdditionalDataOffersAction
} from '../actions/offers';
import {
    OffersActionsList,
    OffersItem,
    RequestAddOffersActionType,
    RequestDeleteOffersActionType,
    RequestUpdateOffersActionType
} from '../types/offers';
import generateID from '../../utils/generateID';
import notification from '../../utils/notification';
import { CompaniesItemShort } from '../types/companies';
import store from '..';
import { addNotifyAction } from '../actions/notifications';
import axios from 'axios';

function subscribeOffers(ref: firebase.firestore.Query) {
    return eventChannel((emmiter: any) => {
        ref.onSnapshot((data) => {
            if (!data.empty) {
                data.docChanges().forEach((offer) => {
                    const dataOffer = offer.doc.data();
                    if ((+moment().format('X') - dataOffer.createdAt.seconds) <= 10) {
                        store.dispatch(addNotifyAction({
                            companyID: dataOffer.companyID,
                            companyName: '',
                            date: dataOffer.createdAt.seconds,
                            type: 'offer'
                        }));
                    }
                });
            }
        });

        return () => ref;
    });
}

function* getOffersWorker() {
    try {
        const companies = db.collection('companies');
        const companiesSnapshot: firebase.firestore.QuerySnapshot = yield companies.get();

        let companiesData: CompaniesItemShort[] = [];

        companiesSnapshot.forEach((doc) => {
            const docData = doc.data();
            companiesData = [...companiesData, {
                id: doc.id,
                shortName: docData.shortName,
                fullName: docData.fullName
            } as CompaniesItemShort];
        });

        yield put(setAdditionalDataOffersAction(companiesData));
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorOffersAction('Не удалось загрузить данные'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные');
        console.log(error);
    }

    try {
        const offers = db.collection('offers');

        const offersSnapshot: firebase.firestore.QuerySnapshot = yield offers.get();

        if (!offersSnapshot.empty) {
            let data: OffersItem[] = [];
            offersSnapshot.forEach((doc) => {
                const docData = doc.data();
                const offersData: OffersItem = {
                    id: doc.id,
                    companyID: docData.companyID,
                    text: docData.text,
                    createdAt: docData.createdAt,
                    updatedAt: docData.updatedAt
                };

                data = [...data, offersData];
            });

            yield put(responseGetOffersAction(data));
        } else {
            yield put(responseGetOffersAction([]));
        }
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorOffersAction('Не удалось загрузить данные'));
        notification('error', 'Ошибка', 'Не удалось загрузить данные');
        console.log(error);
    }
}

function* addOffersWorker(effect: RequestAddOffersActionType) {
    const id = generateID();

    try {
        yield db.collection('offers').doc(id).set({
            ...effect.data,
            createdAt: effect.data.createdAt !== undefined && firebase.firestore.Timestamp.fromMillis(effect.data.createdAt * 1000),
            updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.updatedAt * 1000)
        });
        yield put(responseAddOffersAction());

        if (effect.data.createdAt !== undefined) {
            const formData = new FormData();
            formData.append('date', moment(effect.data.createdAt * 1000).format('DD.MM.YYYY HH:mm'));
            formData.append('text', effect.data.text);
            yield axios.post('https://email.zipnss.ru/mail.php', formData);
        }

        notification('success', 'Успех', 'Данные успешно отправлены');
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorOffersAction('Не удалось создать данные'));
        notification('error', 'Ошибка', 'Не удалось создать данные');
        console.log(error);
    }
}

function* updateOffersWorker(effect: RequestUpdateOffersActionType) {
    try {
        yield db.collection('offers').doc(effect.data.id).update({
            ...effect.data,
            text: effect.data.text,
            updatedAt: firebase.firestore.Timestamp.fromMillis(effect.data.updatedAt * 1000)
        });
        yield put(responseUpdateOffersAction({
            ...effect.data,
            id: effect.data.id
        }));
        notification('success', 'Успех', 'Данные успешно обновлены');
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorOffersAction('Не удалось обновить данные'));
        notification('error', 'Ошибка', 'Не удалось обновить данные');
        console.log(error);
    }
}

function* deleteOffersWorker(effect: RequestDeleteOffersActionType) {
    try {
        yield db.collection('offers').doc(effect.offerID).delete();
        yield put(responseDeleteOffersAction(effect.offerID));
        notification('success', 'Успех', 'Данные успешно удалены');
    } catch (e: any) {
        const error: firebase.FirebaseError = e;
        yield put(errorOffersAction('Не удалось удалить данные'));
        notification('error', 'Ошибка', 'Не удалось удалить данные');
        console.log(error);
    }
}

function* subscribeOffersWorker() {
    const offersRef = db.collection('offers');
    yield subscribeOffers(offersRef);
}

export function* offersWatcher() {
    yield takeEvery(OffersActionsList.REQUEST_GET_OFFERS, getOffersWorker);
    yield takeEvery(OffersActionsList.REQUEST_ADD_OFFERS, addOffersWorker);
    yield takeEvery(OffersActionsList.REQUEST_UPDATE_OFFERS, updateOffersWorker);
    yield takeEvery(OffersActionsList.SUBSCRIBE_OFFERS, subscribeOffersWorker);
}
