import { Moment } from 'moment';
import firebase from '../../utils/firebase';
import {
    ChatItem,
    MessageItem,
    ChatActionsList,
    ChatActionsType,
    MonthHistoryItem
} from '../types/chat';
import { UsersItem } from '../types/users';

export const errorChatAction = (error: string): ChatActionsType => ({
    type: ChatActionsList.ERROR_CHAT,
    error
});

export const requestGetChatsAction = (userID: string): ChatActionsType => ({
    type: ChatActionsList.REQUEST_GET_CHATS,
    userID
});

export const responseGetChatsAction = (data: ChatItem[], users: UsersItem[]): ChatActionsType => ({
    type: ChatActionsList.RESPONSE_GET_CHATS,
    data,
    users
});

export const changeChatAction = (chatID: string): ChatActionsType => ({
    type: ChatActionsList.CHANGE_CHAT,
    chatID
});

export const requestGetChatHistoryAction = (chatID: string, lastDoc: firebase.firestore.DocumentData): ChatActionsType => ({
    type: ChatActionsList.REQUEST_GET_CHAT_HISTORY,
    chatID,
    lastDoc
});

export const responseGetChatHistoryAction = (chatID: string, data: MessageItem[]): ChatActionsType => ({
    type: ChatActionsList.RESPONSE_GET_CHAT_HISTORY,
    chatID,
    data
});

export const requestGetPeriodChatAction = (chatID: string, period: [number, number]): ChatActionsType => ({
    type: ChatActionsList.REQUEST_GET_PERIOD_CHAT,
    chatID,
    period
});

export const responseGetPeriodChatAction = (chatID: string, data: MessageItem[]): ChatActionsType => ({
    type: ChatActionsList.RESPONSE_GET_PERIOD_CHAT,
    chatID,
    data
});

export const requestResetPeriodChatAction = (chatID: string): ChatActionsType => ({
    type: ChatActionsList.REQUEST_RESET_PERIOD_CHAT,
    chatID
});

export const responseResetPeriodChatAction = (chatID: string, data: MessageItem[]): ChatActionsType => ({
    type: ChatActionsList.RESPONSE_RESET_PERIOD_CHAT,
    chatID,
    data
});

export const requestSendMessageChatAction = (data: MessageItem): ChatActionsType => ({
    type: ChatActionsList.REQUEST_SEND_MESSAGE_CHAT,
    data
});

export const responseSendMessageChatAction = (data: MessageItem): ChatActionsType => ({
    type: ChatActionsList.RESPONSE_SEND_MESSAGE_CHAT,
    data
});

export const requestAddEngineerChatAction = (chatID: string, engineerID: string): ChatActionsType => ({
    type: ChatActionsList.REQUEST_ADD_ENGINEER_CHAT,
    chatID,
    engineerID
});

export const responseAddEngineerChatAction = (chatID: string, engineerID: string): ChatActionsType => ({
    type: ChatActionsList.RESPONSE_ADD_ENGINEER_CHAT,
    chatID,
    engineerID
});

export const addMessagesChatAction = (chatID: string, data: MessageItem[]): ChatActionsType => ({
    type: ChatActionsList.ADD_MESSAGES_CHAT,
    chatID,
    data
});

export const addingEngineerChatAction = (): ChatActionsType => ({
    type: ChatActionsList.ADDING_ENGINEER_CHAT
});

export const requestGetHistoryCalendarChatAction = (chatID: string, date: Moment): ChatActionsType => ({
    type: ChatActionsList.REQUEST_GET_CALENDAR_HISTORY_CHAT,
    chatID,
    date
});

export const responseGetHistoryCalendarChatAction = (data: MonthHistoryItem): ChatActionsType => ({
    type: ChatActionsList.RESPONSE_GET_CALENDAR_HISTORY_CHAT,
    data
});
