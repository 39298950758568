import { CompaniesItemShort } from './companies';

export interface OffersItemData {
    text: string,
    companyID: string,
    createdAt?: number,
    updatedAt: number
}

export interface OffersItem extends OffersItemData {
    id: string
}

export interface OffersStateType {
    loading: boolean,
    data: OffersItem[] | null,
    creating: boolean,
    companies: CompaniesItemShort[] | null,
    editing: string | null,
    error: string | null
}

export enum OffersActionsList {
    ERROR_OFFERS = 'ERROR_OFFERS',
    CREATING_OFFERS = 'CREATING_OFFERS',
    REQUEST_GET_OFFERS = 'REQUEST_GET_OFFERS',
    RESPONSE_GET_OFFERS = 'RESPONSE_GET_OFFERS',
    REQUEST_ADD_OFFERS = 'REQUEST_ADD_OFFERS',
    RESPONSE_ADD_OFFERS = 'RESPONSE_ADD_OFFERS',
    REQUEST_UPDATE_OFFERS = 'REQUEST_UPDATE_OFFERS',
    RESPONSE_UPDATE_OFFERS = 'RESPONSE_UPDATE_OFFERS',
    REQUEST_DELETE_OFFERS = 'REQUEST_DELETE_OFFERS',
    RESPONSE_DELETE_OFFERS = 'RESPONSE_DELETE_OFFERS',
    SET_ADDITIONAL_DATA_OFFERS = 'SET_ADDITIONAL_DATA_OFFERS',
    SUBSCRIBE_OFFERS = 'SUBSCRIBE_OFFERS',
    RESPONSE_SIGNOUT_AUTH = 'RESPONSE_SIGNOUT_AUTH'
}

export interface ErrorDatesActionType {
    type: OffersActionsList.ERROR_OFFERS,
    error: string
}

export interface CreatingOffersActionType {
    type: OffersActionsList.CREATING_OFFERS
}

export interface RequestGetOffersActionType {
    type: OffersActionsList.REQUEST_GET_OFFERS
}

export interface ResponseGetOffersActionType {
    type: OffersActionsList.RESPONSE_GET_OFFERS,
    data: OffersItem[]
}

export interface RequestAddOffersActionType {
    type: OffersActionsList.REQUEST_ADD_OFFERS,
    data: OffersItemData
}

export interface ResponseAddOffersActionType {
    type: OffersActionsList.RESPONSE_ADD_OFFERS
}

export interface RequestUpdateOffersActionType {
    type: OffersActionsList.REQUEST_UPDATE_OFFERS,
    data: OffersItem
}

export interface ResponseUpdateOffersActionType {
    type: OffersActionsList.RESPONSE_UPDATE_OFFERS,
    data: OffersItem
}

export interface RequestDeleteOffersActionType {
    type: OffersActionsList.REQUEST_DELETE_OFFERS,
    offerID: string
}

export interface ResponseDeleteDatesActionType {
    type: OffersActionsList.RESPONSE_DELETE_OFFERS,
    offerID: string
}

export interface SetAdditionalDataOffersActionType {
    type: OffersActionsList.SET_ADDITIONAL_DATA_OFFERS,
    companies: CompaniesItemShort[]
}

export interface SubscribeOffersActionType {
    type: OffersActionsList.SUBSCRIBE_OFFERS
}

export interface ResponseSignOutActionType {
    type: OffersActionsList.RESPONSE_SIGNOUT_AUTH
}

export type OffersActionsType =
ErrorDatesActionType
| CreatingOffersActionType
| SetAdditionalDataOffersActionType
| RequestGetOffersActionType
| ResponseGetOffersActionType
| RequestAddOffersActionType
| ResponseAddOffersActionType
| RequestUpdateOffersActionType
| ResponseUpdateOffersActionType
| RequestDeleteOffersActionType
| ResponseDeleteDatesActionType
| SubscribeOffersActionType
| ResponseSignOutActionType;
