import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Form,
    Button,
    Row,
    Col,
    Divider,
    Select,
    DatePicker,
    Switch,
    Table,
    Skeleton,
    Empty
} from 'antd';
import {
    CheckCircleTwoTone,
    SaveOutlined,
    CloseCircleTwoTone,
    DeleteOutlined,
    EditOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { RequiredMark } from 'antd/lib/form/Form';
import moment from 'moment';
import 'moment/locale/ru';
import firebase from '../../utils/firebase';

import './style.scss';
import WrapperPage from '../../containers/WrapperPage';
import { AppState } from '../../store';
import { DatesItem } from '../../store/types/dates';
import {
    requestDeleteDatesAction,
    requestGetDatesAction,
    requestUpdateDatesAction
} from '../../store/actions/dates';
import AddDate from './add';
import Preloader from '../../components/Preloader';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Column } = Table;

const DatesPage:React.FC = () => {
    const [formEdit] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>(true);
    const [editing, setEdit] = useState<string | null>(null);
    const auth = useSelector((state: AppState) => state.auth);
    const dates = useSelector((state: AppState) => state.dates);
    const dispatch = useDispatch();
    const {
        loading,
        data,
        companies,
        users,
        editing: editingID
    } = dates;
    const { account } = auth;

    const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    };

    useEffect(() => {
        dispatch(requestGetDatesAction(auth.company));
    }, []);

    const onDelete = (id: string) => {
        dispatch(requestDeleteDatesAction(id));
    };

    const onEdit = (values: any) => {
        const time = firebase.firestore.Timestamp.now();

        if (editing) {
            dispatch(requestUpdateDatesAction(editing, {
                companyID: values.companyID,
                userID: values.userID,
                from: +moment(values.fromTo[0]).format('X'),
                to: +moment(values.fromTo[1]).format('X'),
                finished: values.finished,
                updatedAt: time.seconds
            }));
        }

        formEdit.resetFields();
        setEdit(null);
    };

    if (data === null) {
        return <Preloader />;
    }

    return (
        <div className="dates page">
            {['admin', 'manager'].includes(account || '') ? (
                <AddDate />
            ) : null}
            <Row gutter={15}>
                <Divider>Сроки выезда</Divider>
                <Col span={24}>
                    <Form
                        form={formEdit}
                        layout="vertical"
                        onValuesChange={onRequiredTypeChange}
                        requiredMark={requiredMark}
                        onFinish={onEdit}
                    >
                        <Table
                            dataSource={data}
                            pagination={false}
                            rowKey={((record) => `dates_${record.id}`)}
                            locale={{
                                emptyText: <Empty description="Нет данных" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            }}
                        >
                            <Column
                                title="Компания"
                                dataIndex="companyID"
                                key="companyID"
                                width="25%"
                                render={(value, record: DatesItem) => {
                                    if (record.id === editingID && loading) return <Skeleton active paragraph={false} />;

                                    const company = companies?.filter((item) => item.id === record.companyID);

                                    if (editing && record.id === editing && company?.length) {
                                        return (
                                            <Form.Item name="companyID" required>
                                                <Select
                                                    showSearch
                                                    placeholder="Выберите компанию"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option !== undefined && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    loading={companies === null}
                                                    disabled={account === 'engineer'}
                                                >
                                                    {companies ? companies.map((item) => <Option value={item.id} key={item.id}>{item.shortName}</Option>) : null}
                                                </Select>
                                            </Form.Item>
                                        );
                                    }

                                    return company?.length ? company[0].shortName : '- Компания удалена -';
                                }}
                            />
                            <Column
                                title="Инженер"
                                dataIndex="userID"
                                key="userID"
                                width="25%"
                                render={(value, record: DatesItem) => {
                                    if (record.id === editingID && loading) return <Skeleton active paragraph={false} />;

                                    const user = users?.filter((item) => item.id === record.userID);

                                    if (editing && record.id === editing && user?.length) {
                                        return (
                                            <Form.Item name="userID" required>
                                                <Select
                                                    showSearch
                                                    placeholder="Выберите инженера"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option !== undefined && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    loading={users === null}
                                                    disabled={account === 'engineer'}
                                                >
                                                    {users ? users.map((item) => <Option value={item.id} key={item.id}>{item.fio}</Option>) : null}
                                                </Select>
                                            </Form.Item>
                                        );
                                    }

                                    return user?.length ? user[0].fio : '- Пользователь удален -';
                                }}
                            />
                            <Column
                                title="Дата выезда"
                                dataIndex="fromTo"
                                key="fromTo"
                                width="25%"
                                render={(value, record: DatesItem) => {
                                    if (record.id === editingID && loading) return <Skeleton active paragraph={false} />;
                                    if (editing && record.id === editing) {
                                        return (
                                            <Form.Item name="fromTo" required>
                                                <RangePicker
                                                    placeholder={['Начало', 'Конец']}
                                                    format="DD.MM.YYYY"
                                                    disabled={account === 'engineer'}
                                                    locale={{
                                                        lang: {
                                                            locale: 'ru_RU',
                                                            placeholder: 'Выбрать дату',
                                                            rangePlaceholder: ['Начальная дата', 'Конечная дата'],
                                                            today: 'Сегодня',
                                                            now: 'Сейчас',
                                                            backToToday: 'Вернуться назад',
                                                            ok: 'ОК',
                                                            clear: 'Очистить',
                                                            month: 'Месяц',
                                                            year: 'Год',
                                                            timeSelect: 'Выбрать время',
                                                            dateSelect: 'Выбрать день',
                                                            monthSelect: 'Выбрать месяц',
                                                            yearSelect: 'Выбрать год',
                                                            decadeSelect: 'Выбрать десятилетие',
                                                            yearFormat: 'YYYY',
                                                            dateFormat: 'DD.MM.YYYY',
                                                            dayFormat: 'D',
                                                            dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
                                                            monthFormat: 'MMMM',
                                                            monthBeforeYear: true,
                                                            previousMonth: 'Предыдущий месяц (PageUp)',
                                                            nextMonth: 'Следующий месяц (PageDown)',
                                                            previousYear: 'Предыдущий год (Control + left)',
                                                            nextYear: 'Следующий год (Control + right)',
                                                            previousDecade: 'Предыдущее десятилетие',
                                                            nextDecade: 'Следующее лесятилетие',
                                                            previousCentury: 'Предыдущий век',
                                                            nextCentury: 'Следующий век'
                                                        },
                                                        timePickerLocale: {
                                                            placeholder: 'Выбрать время'
                                                        },
                                                        dateFormat: 'DD.MM.YYYY',
                                                        dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
                                                        weekFormat: 'wo YYYY',
                                                        monthFormat: 'MM YYYY'
                                                    }}
                                                />
                                            </Form.Item>
                                        );
                                    }

                                    return `${moment(record.from * 1000).format('DD.MM.YYYY')} - ${moment(record.to * 1000).format('DD.MM.YYYY')}`;
                                }}
                            />
                            <Column
                                title="Готовность"
                                dataIndex="finished"
                                key="finished"
                                width="10%"
                                render={(value, record: DatesItem) => {
                                    if (record.id === editingID && loading) return <Skeleton active paragraph={false} />;

                                    if (editing && record.id === editing) {
                                        return (
                                            <Form.Item name="finished" valuePropName="checked" required>
                                                <Switch />
                                            </Form.Item>
                                        );
                                    }

                                    return (
                                        <div style={{ textAlign: 'center', fontSize: '1.5rem' }}>
                                            {record.finished ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#ff4d4f" />}
                                        </div>
                                    );
                                }}
                            />
                            {['admin', 'manager', 'engineer'].includes(account || '') ? (
                                <Column
                                    key="actions"
                                    render={(value, record: DatesItem) => (
                                        <>
                                            <Button
                                                type="primary"
                                                icon={editing && record.id === editing ? <SaveOutlined /> : <EditOutlined />}
                                                loading={record.id === editingID && loading}
                                                onClick={() => {
                                                    if (record.id === editing) {
                                                        formEdit.submit();
                                                    } else {
                                                        setEdit(record.id);
                                                        formEdit.setFieldsValue({
                                                            companyID: record.companyID,
                                                            finished: record.finished,
                                                            fromTo: [moment(record.from * 1000), moment(record.to * 1000)],
                                                            userID: record.userID
                                                        });
                                                    }
                                                }}
                                            />
                                            {account !== 'engineer' ? (
                                                <Button
                                                    type="primary"
                                                    icon={editing && record.id === editing ? <CloseOutlined /> : <DeleteOutlined />}
                                                    danger
                                                    loading={record.id === editingID && loading}
                                                    onClick={() => {
                                                        if (record.id === editing) {
                                                            setEdit(null);
                                                            formEdit.resetFields();
                                                        } else {
                                                            onDelete(record.id);
                                                        }
                                                    }}
                                                />
                                            ) : null}
                                        </>
                                    )}
                                />
                            ) : null}
                        </Table>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default WrapperPage(DatesPage);
