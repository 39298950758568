import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './style.scss';

interface PreloaderProps {
    fullscreen?: boolean
}

const defaultProps: PreloaderProps = {
    fullscreen: false
};

const Preloader = ({ fullscreen }: PreloaderProps): JSX.Element => (
    <div className={`preloader ${fullscreen ? 'fullscreen' : ''}`}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: fullscreen ? 48 : 32 }} spin />} />
    </div>
);

Preloader.defaultProps = defaultProps;

export default Preloader;
