import { CompaniesItemShort } from '../types/companies';
import {
    NotificationsActionsList,
    NotificationsActionsType,
    NotificationItem
} from '../types/notifications';

export const errorNotifyAction = (error: string): NotificationsActionsType => ({
    type: NotificationsActionsList.ERROR_NOTIFY,
    error
});

export const addNotifyAction = (data: NotificationItem): NotificationsActionsType => ({
    type: NotificationsActionsList.ADD_NOTIFY,
    data
});

export const removeNotifyAction = (): NotificationsActionsType => ({
    type: NotificationsActionsList.REMOVE_NOTIFY
});

export const getNotifyAction = (): NotificationsActionsType => ({
    type: NotificationsActionsList.GET_NOTIFY
});

export const requestAdditionalDataNotifyAction = (): NotificationsActionsType => ({
    type: NotificationsActionsList.REQUEST_ADDITIONAL_DATA_NOTIFY
});

export const responseAdditionalDataNotifyAction = (data: CompaniesItemShort[]): NotificationsActionsType => ({
    type: NotificationsActionsList.RESPONSE_ADDITIONAL_DATA_NOTIFY,
    data
});
