import { combineReducers } from 'redux';

import authReducer from './auth';
import companiesReducer from './companises';
import equipmentsReducer from './equipments';
import datesReducer from './dates';
import offersReducer from './offers';
import usersReducer from './users';
import chatReducer from './chat';
import statisticReducer from './statistic';
import ordersReducer from './orders';
import notificationsReducer from './notifications';

const rootReducer = combineReducers({
    auth: authReducer,
    companies: companiesReducer,
    equipments: equipmentsReducer,
    dates: datesReducer,
    offers: offersReducer,
    users: usersReducer,
    chat: chatReducer,
    statistic: statisticReducer,
    orders: ordersReducer,
    notifications: notificationsReducer
});

export type RootReducerType = typeof rootReducer;
export type RootStateType = ReturnType<RootReducerType>;

export default rootReducer;
