import {
    UsersActionsList,
    UsersActionsType,
    UsersItem,
    UsersItemData,
    UsersStateType
} from '../types/users';

export const initialState: UsersStateType = {
    loading: false,
    data: null,
    companies: null,
    creating: false,
    editing: null,
    viewing: null,
    error: null
};

const usersReducer = (state: UsersStateType = initialState, action: UsersActionsType) : UsersStateType => {
    switch (action.type) {
        case UsersActionsList.USERS_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error
            };
        }
        case UsersActionsList.USERS_CREATING: {
            return {
                ...state,
                creating: !state.creating
            };
        }
        case UsersActionsList.USERS_EDITING: {
            return {
                ...state,
                editing: action.userID !== null || action.companyID !== null ? {
                    userID: action.userID,
                    companyID: action.companyID
                } : null
            };
        }
        case UsersActionsList.USERS_VIEWING: {
            return {
                ...state,
                viewing: action.userID !== null || action.companyID !== null ? {
                    userID: action.userID,
                    companyID: action.companyID
                } : null
            };
        }
        case UsersActionsList.USERS_REQUEST_CREATE:
        case UsersActionsList.USERS_REQUEST_GET:
        case UsersActionsList.USERS_REQUEST_UPDATE:
        case UsersActionsList.USERS_REQUEST_DELETE: {
            return {
                ...state,
                loading: true
            };
        }
        case UsersActionsList.USERS_RESPONSE_CREATE: {
            return {
                ...state,
                loading: false,
                creating: false,
                data: state.data && [...state.data, action.data]
            };
        }
        case UsersActionsList.USERS_RESPONSE_UPDATE: {
            const updatedCompanies = action.data.company ? state.companies?.map((company) => {
                if (company.id === action.companyID) {
                    return {
                        ...company,
                        ...action.data.company
                    };
                }
                return company;
            }) : state.companies;

            return {
                ...state,
                loading: false,
                editing: null,
                data: state.data && state.data.map((user) => {
                    if (user.id === action.userID) {
                        return {
                            ...user,
                            ...action.data.user
                        };
                    }
                    return user;
                }),
                companies: (updatedCompanies && updatedCompanies !== undefined) ? updatedCompanies : state.companies
            };
        }
        case UsersActionsList.USERS_RESPONSE_DELETE: {
            return {
                ...state,
                loading: false,
                data: state.data && state.data.filter((item) => item.id !== action.id)
            };
        }
        case UsersActionsList.USERS_RESPONSE_GET: {
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        case UsersActionsList.USERS_SET_ADDITIONAL_DATA: {
            return {
                ...state,
                companies: action.companies
            };
        }
        case UsersActionsList.RESPONSE_SIGNOUT_AUTH: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default usersReducer;
