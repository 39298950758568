import { put, takeEvery } from 'redux-saga/effects';

import firebase, { db } from '../../utils/firebase';
import notification from '../../utils/notification';
import {
    errorEquipmentsAction,
    responseGetEquipmentsAction,
    responseSaveEquipmentsAction
} from '../actions/equipments';
import {
    EquipmentsActionsList,
    EquipmentItem,
    EquipmentDetailItem,
    RequestGetEquipmentsActionType,
    RequestSaveEquipmentsActionType
} from '../types/equipments';

function* getEquipmentsWorker(effect: RequestGetEquipmentsActionType) {
    if (effect.fromCache) {
        const cacheData = localStorage.getItem('equipmentsData');

        if (cacheData) {
            yield put(responseGetEquipmentsAction(JSON.parse(cacheData)));
            return;
        }
    }
    if (effect.companyID) {
        localStorage.removeItem('equipmentsData');
        try {
            const equimpments = db.collection('equipments').where('companyID', '==', effect.companyID);
            const equimpmentsSnapshot: firebase.firestore.QuerySnapshot = yield equimpments.get();
            if (!equimpmentsSnapshot.empty) {
                let detailsIDs: any[] = [];
                let data: EquipmentItem[] = [];
                equimpmentsSnapshot.forEach((doc) => {
                    const docData = doc.data();
                    const equipmentData: EquipmentItem = {
                        id: doc.id,
                        companyID: docData.companyID,
                        mark: docData.mark,
                        model: docData.model,
                        serial: docData.serial,
                        year: docData.year,
                        count: docData.count,
                        build: docData.build !== undefined ? docData.build : '',
                        details: docData.details,
                        createdAt: docData.createdAt,
                        updatedAt: docData.updatedAt
                    };

                    data = [...data, equipmentData];

                    if (equipmentData.details.length) {
                        detailsIDs = [...detailsIDs, ...equipmentData.details];
                    }
                });

                if (detailsIDs.length) {
                    const details = db.collection('details').where('__name__', 'in', detailsIDs);
                    const detailsSnapshot: firebase.firestore.QuerySnapshot = yield details.get();

                    let dataDetails: EquipmentDetailItem[] = [];

                    detailsSnapshot.forEach((doc) => {
                        const docData = doc.data();
                        const detailData: EquipmentDetailItem = {
                            id: doc.id,
                            name: docData.name,
                            serial: docData.serial,
                            count: docData.count,
                            build: docData.build !== undefined ? docData.build : '',
                            companyID: docData.companyID,
                            createdAt: docData.createdAt,
                            updatedAt: docData.updatedAt
                        };

                        dataDetails = [...dataDetails, detailData];
                    });

                    data = data.map((eq: any) => ({
                        ...eq,
                        details: dataDetails.filter((det: EquipmentDetailItem) => eq.details.includes(det.id))
                    }));
                }

                yield put(responseGetEquipmentsAction(data));
            } else {
                yield put(responseGetEquipmentsAction([]));
            }
        } catch (e: any) {
            const error: firebase.FirebaseError = e;
            yield put(errorEquipmentsAction('Не удалось загрузить данные'));
            notification('error', 'Ошибка', 'Не удалось загрузить данные');
            console.log(error);
        }
    }
}

function* saveEquipmentsWorker(effect: RequestSaveEquipmentsActionType) {
    const { creating, deleting } = effect;
    const batch = db.batch();

    if (creating.equipments.length) {
        creating.equipments.forEach((equipment) => {
            const newEquipment = db.collection('equipments').doc(equipment.id);
            batch.set(newEquipment, {
                companyID: equipment.companyID,
                mark: equipment.mark,
                model: equipment.model,
                serial: equipment.serial,
                year: equipment.year,
                count: equipment.count,
                build: equipment.build,
                details: [], // equipment.details.map((det) => det.id), // добавление деталей к оборудованию
                createdAt: equipment.createdAt !== undefined && firebase.firestore.Timestamp.fromMillis(equipment.createdAt * 1000),
                updatedAt: firebase.firestore.Timestamp.fromMillis(equipment.updatedAt * 1000)
            });
        });
    }
    if (creating.details.length) {
        creating.details.forEach((detail) => {
            const newDetail = db.collection('details').doc(detail.id);
            batch.set(newDetail, {
                name: detail.name,
                serial: detail.serial,
                count: detail.count,
                build: detail.build,
                companyID: detail.companyID,
                createdAt: detail.createdAt !== undefined && firebase.firestore.Timestamp.fromMillis(detail.createdAt * 1000),
                updatedAt: firebase.firestore.Timestamp.fromMillis(detail.updatedAt * 1000)
            });
        });
    }
    // Удаление деталей
    // if (deleting.equipments.length) {
    //     deleting.equipments.forEach((equipmentID) => {
    //         const eqRef = db.collection('equipments').doc(equipmentID);
    //         batch.delete(eqRef);
    //     });
    // }
    // if (deleting.details.length) {
    //     deleting.details.forEach((detailID) => {
    //         const detRef = db.collection('details').doc(detailID);
    //         batch.delete(detRef);
    //     });
    // }

    yield batch.commit();

    try {
        yield put(responseSaveEquipmentsAction());
        // notification('success', 'Успех', 'Оборудование успешно обновлено');
    } catch (e) {
        yield put(errorEquipmentsAction('Не удалось обновить оборудование'));
        notification('error', 'Ошибка', 'Не удалось обновить оборудование');
        console.error(e);
    }
}

export function* equipmentsWatcher() {
    yield takeEvery(EquipmentsActionsList.REQUEST_GET_EQUIPMENTS, getEquipmentsWorker);
    yield takeEvery(EquipmentsActionsList.REQUEST_SAVE_EQUIPMENTS, saveEquipmentsWorker);
}
