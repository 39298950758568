import {
    StatisticActionsList,
    StatisticActionsType,
    StatisticStateType
} from '../types/statistic';

export const initialState: StatisticStateType = {
    countUsers: {
        data: null,
        loading: false
    },
    countSignIn: {
        data: null,
        loading: false
    },
    countRegistration: {
        data: null,
        loading: false
    },
    popularEquipments: {
        data: null,
        loading: false
    },
    popularDetails: {
        data: null,
        loading: false
    },
    error: null
};

const statisticReducer = (state: StatisticStateType = initialState, action: StatisticActionsType) : StatisticStateType => {
    switch (action.type) {
        case StatisticActionsList.REQUEST_COUNT_USERS_STATISTIC: {
            return {
                ...state,
                countUsers: {
                    ...state.countUsers,
                    loading: true
                }
            };
        }
        case StatisticActionsList.RESPONSE_COUNT_USERS_STATISTIC: {
            return {
                ...state,
                countUsers: {
                    ...state.countUsers,
                    loading: false,
                    data: action.data
                }
            };
        }
        case StatisticActionsList.ERROR_COUNT_USERS_STATISTIC: {
            return {
                ...state,
                countUsers: {
                    ...state.countUsers,
                    loading: false
                },
                error: action.error
            };
        }
        case StatisticActionsList.REQUEST_COUNT_SIGN_IN_STATISTIC: {
            return {
                ...state,
                countSignIn: {
                    ...state.countSignIn,
                    loading: true
                }
            };
        }
        case StatisticActionsList.RESPONSE_COUNT_SIGN_IN_STATISTIC: {
            return {
                ...state,
                countSignIn: {
                    ...state.countSignIn,
                    loading: false,
                    data: action.data
                }
            };
        }
        case StatisticActionsList.ERROR_COUNT_SIGN_IN_STATISTIC: {
            return {
                ...state,
                countSignIn: {
                    ...state.countSignIn,
                    loading: false
                },
                error: action.error
            };
        }
        case StatisticActionsList.REQUEST_COUNT_REGISTRATION_STATISTIC: {
            return {
                ...state,
                countRegistration: {
                    ...state.countRegistration,
                    loading: true
                }
            };
        }
        case StatisticActionsList.RESPONSE_COUNT_REGISTRATION_STATISTIC: {
            return {
                ...state,
                countRegistration: {
                    ...state.countRegistration,
                    loading: false,
                    data: action.data
                }
            };
        }
        case StatisticActionsList.ERROR_COUNT_REGISTRATION_STATISTIC: {
            return {
                ...state,
                countRegistration: {
                    ...state.countRegistration,
                    loading: false
                },
                error: action.error
            };
        }
        case StatisticActionsList.REQUEST_POPULAR_EQUIPMENTS_STATISTIC: {
            return {
                ...state,
                popularEquipments: {
                    ...state.popularEquipments,
                    loading: true
                }
            };
        }
        case StatisticActionsList.RESPONSE_POPULAR_EQUIPMENTS_STATISTIC: {
            return {
                ...state,
                popularEquipments: {
                    ...state.popularEquipments,
                    loading: false,
                    data: action.data
                }
            };
        }
        case StatisticActionsList.ERROR_POPULAR_EQUIPMENTS_STATISTIC: {
            return {
                ...state,
                popularEquipments: {
                    ...state.popularEquipments,
                    loading: false
                },
                error: action.error
            };
        }
        case StatisticActionsList.REQUEST_POPULAR_DETAILS_STATISTIC: {
            return {
                ...state,
                popularDetails: {
                    ...state.popularDetails,
                    loading: true
                }
            };
        }
        case StatisticActionsList.RESPONSE_POPULAR_DETAILS_STATISTIC: {
            return {
                ...state,
                popularDetails: {
                    ...state.popularDetails,
                    loading: false,
                    data: action.data
                }
            };
        }
        case StatisticActionsList.ERROR_POPULAR_DETAILS_STATISTIC: {
            return {
                ...state,
                popularDetails: {
                    ...state.popularDetails,
                    loading: false
                },
                error: action.error
            };
        }
        case StatisticActionsList.RESPONSE_SIGNOUT_AUTH: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default statisticReducer;
