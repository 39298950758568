import {
    DatesActionsList,
    DatesActionsType,
    DatesItem,
    DatesItemData
} from '../types/dates';
import { UsersItemShort } from '../types/users';
import { CompaniesItemShort } from '../types/companies';

export const errorDatesAction = (error: string): DatesActionsType => ({
    type: DatesActionsList.ERROR_DATES,
    error
});

export const requestGetDatesAction = (companyID: string | null = null): DatesActionsType => ({
    type: DatesActionsList.REQUEST_GET_DATES,
    companyID
});

export const responseGetDatesAction = (dates: DatesItem[]): DatesActionsType => ({
    type: DatesActionsList.RESPONSE_GET_DATES,
    data: dates
});

export const requestAddDatesAction = (data: DatesItemData): DatesActionsType => ({
    type: DatesActionsList.REQUEST_ADD_DATES,
    data
});

export const responseAddDatesAction = (): DatesActionsType => ({
    type: DatesActionsList.RESPONSE_ADD_DATES
});

export const requestDeleteDatesAction = (dateID: string): DatesActionsType => ({
    type: DatesActionsList.REQUEST_DELETE_DATES,
    dateID
});

export const responseDeleteDatesAction = (): DatesActionsType => ({
    type: DatesActionsList.RESPONSE_DELETE_DATES
});

export const requestUpdateDatesAction = (dateID: string, data: DatesItemData): DatesActionsType => ({
    type: DatesActionsList.REQUEST_UPDATE_DATES,
    dateID,
    data
});

export const responseUpdateDatesAction = (): DatesActionsType => ({
    type: DatesActionsList.RESPONSE_UPDATE_DATES
});

export const setAdditionalDataDatesAction = (companies: CompaniesItemShort[], users: UsersItemShort[]): DatesActionsType => ({
    type: DatesActionsList.SET_ADDITIONAL_DATA_DATES,
    companies,
    users
});

export const addDatesAction = (data: DatesItem): DatesActionsType => ({
    type: DatesActionsList.ADD_DATES,
    data
});

export const updateDatesAction = (data: DatesItem): DatesActionsType => ({
    type: DatesActionsList.UPDATE_DATES,
    data
});

export const deleteDatesAction = (dateID: string): DatesActionsType => ({
    type: DatesActionsList.DELETE_DATES,
    dateID
});
