/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import {
    Typography,
    DatePicker,
    Dropdown,
    Button,
    Menu
} from 'antd';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import {
    DownloadOutlined, FileExcelOutlined, FilePdfOutlined
} from '@ant-design/icons';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import Preloader from '../../../components/Preloader';
import { AppState } from '../../../store';

import { requestCountSignInStatisticAction } from '../../../store/actions/statistic';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const CountSignIn:React.FC = () => {
    const dispatch = useDispatch();
    const statistic = useSelector((state: AppState) => state.statistic);
    const [date, changeDate] = useState<[Moment, Moment]>([moment().startOf('month'), moment().endOf('month')]);
    const { countSignIn } = statistic;
    const { data } = countSignIn;

    useEffect(() => {
        dispatch(requestCountSignInStatisticAction([date[0], date[1]]));
    }, []);

    const exportExcel = () => {
        let columns: any = [['Дата', 'Кол-во входов']];

        if (data) {
            data.forEach((item) => {
                columns = [...columns, [item.date.format('DD.MM.YYYY'), item.value]];
            });
        }

        const worksheet = XLSX.utils.aoa_to_sheet(columns);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Повторные входы в систему');

        XLSX.writeFile(workbook, `Статистика_повторных_входов_в_CRM_${date[0].format('DD-MM-YYYY')}-${date[1].format('DD-MM-YYYY')}.xlsx`);
    };

    const exportPdf = () => {
        const statisticBlock = document.querySelector('#CountSignIn') as HTMLElement;

        if (statisticBlock) {
            html2canvas(statisticBlock).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'pt', 'a4', false);
                pdf.addImage(imgData, 'PNG', 0, 0, 600, 0);
                pdf.save(`Статистика_повторных_входов_в_CRM_${date[0].format('DD-MM-YYYY')}-${date[1].format('DD-MM-YYYY')}.pdf`);
            });
        }
    };

    if (data === null) {
        return <Preloader />;
    }

    const actions = (
        <Menu>
            <Menu.Item key="pdf" onClick={() => exportPdf()}>
                <FilePdfOutlined />
                &nbsp;
                PDF
            </Menu.Item>
            <Menu.Item key="excel" onClick={() => exportExcel()}>
                <FileExcelOutlined />
                &nbsp;
                EXCEL
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Title level={4}>Повторные входы в систему</Title>
            <div className="dashboard__header">
                <RangePicker
                    format="DD.MM.YYYY"
                    value={date}
                    locale={{
                        lang: {
                            locale: 'ru_RU',
                            placeholder: 'Выбрать дату',
                            rangePlaceholder: ['Начальная дата', 'Конечная дата'],
                            today: 'Сегодня',
                            now: 'Сейчас',
                            backToToday: 'Вернуться назад',
                            ok: 'ОК',
                            clear: 'Очистить',
                            month: 'Месяц',
                            year: 'Год',
                            timeSelect: 'Выбрать время',
                            dateSelect: 'Выбрать день',
                            monthSelect: 'Выбрать месяц',
                            yearSelect: 'Выбрать год',
                            decadeSelect: 'Выбрать десятилетие',
                            yearFormat: 'YYYY',
                            dateFormat: 'DD.MM.YYYY',
                            dayFormat: 'D',
                            dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
                            monthFormat: 'MMMM',
                            monthBeforeYear: true,
                            previousMonth: 'Предыдущий месяц (PageUp)',
                            nextMonth: 'Следующий месяц (PageDown)',
                            previousYear: 'Предыдущий год (Control + left)',
                            nextYear: 'Следующий год (Control + right)',
                            previousDecade: 'Предыдущее десятилетие',
                            nextDecade: 'Следующее лесятилетие',
                            previousCentury: 'Предыдущий век',
                            nextCentury: 'Следующий век'
                        },
                        timePickerLocale: {
                            placeholder: 'Выбрать время'
                        },
                        dateFormat: 'DD.MM.YYYY',
                        dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
                        weekFormat: 'wo YYYY',
                        monthFormat: 'MM YYYY'
                    }}
                    onChange={(values) => {
                        if (values && values[0] && values[1]) {
                            changeDate([values[0], values[1]]);
                        }
                    }}
                    onOpenChange={(open) => {
                        if (!open) {
                            dispatch(requestCountSignInStatisticAction([date[0], date[1]]));
                        }
                    }}
                />
                <Dropdown overlay={actions}>
                    <Button icon={<DownloadOutlined />} />
                </Dropdown>
            </div>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="value" name="Входы" stroke="#69bfdc" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </>
    );
};
export default CountSignIn;
