/* eslint-disable react/jsx-one-expression-per-line */
import moment from 'moment';
import { NotificationItem, NotificationsType } from '../store/types/notifications';

const getTextTypeNotify = (type: NotificationsType): string => {
    switch (type) {
        case 'chat':
            return 'Новое сообщение';
        case 'offer':
            return 'Новое КП';
        case 'order':
            return 'Новый заказ';
        case 'registration':
            return 'Новая регистрация';
        default:
            return '';
    }
};

const generateNotification = (notify: NotificationItem): string => {
    const typeText = getTextTypeNotify(notify.type);
    const date = moment(+notify.date * 1000).format('HH:mm DD.MM.YYYY');

    return `${typeText} - ${notify.companyName} - ${date}`;
};

export default generateNotification;
