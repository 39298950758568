import moment from 'moment';
import {
    ChatActionsList,
    ChatActionsType,
    ChatItem,
    ChatStateType
} from '../types/chat';

export const initialState: ChatStateType = {
    loading: false,
    data: null,
    currentChat: null,
    addingEngineer: false,
    monthsHistory: [],
    period: null,
    users: null,
    error: null
};

const companiesReducer = (state: ChatStateType = initialState, action: ChatActionsType) : ChatStateType => {
    switch (action.type) {
        case ChatActionsList.ERROR_CHAT: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case ChatActionsList.REQUEST_GET_CHATS: {
            return {
                ...state,
                loading: true
            };
        }
        case ChatActionsList.RESPONSE_GET_CHATS: {
            return {
                ...state,
                loading: false,
                data: action.data,
                users: action.users
            };
        }
        case ChatActionsList.CHANGE_CHAT: {
            setTimeout(() => {
                const historyElement = document.getElementById('history');
                if (historyElement) {
                    historyElement.scrollTop = historyElement.scrollHeight;
                }
            }, 1);
            return {
                ...state,
                period: null,
                currentChat: action.chatID,
                monthsHistory: []
            };
        }
        case ChatActionsList.REQUEST_SEND_MESSAGE_CHAT: {
            return {
                ...state,
                data: state.data && state.data.map((chat) => {
                    if (chat.id === action.data.chatID) {
                        return {
                            ...chat,
                            messages: chat.messages ? [...chat.messages, action.data] : [action.data]
                        };
                    }
                    return chat;
                })
            };
        }
        case ChatActionsList.ADD_MESSAGES_CHAT: {
            return {
                ...state,
                data: state.data && state.data.map((chat) => {
                    if (chat.id === action.chatID) {
                        if (chat.messages) {
                            if (action.data.length > 1) {
                                return {
                                    ...chat,
                                    messages: [...chat.messages, ...action.data.reverse()]
                                };
                            }
                            if (chat.messages.filter((item) => item.id === action.data[0].id).length) {
                                return {
                                    ...chat,
                                    messages: chat.messages.map((message) => {
                                        if (message.id === action.data[0].id) {
                                            return {
                                                ...action.data[0],
                                                ...{
                                                    ...message,
                                                    status: 'sended',
                                                    originalDoc: action.data[0].originalDoc,
                                                    hasAttachments: action.data[0].hasAttachments,
                                                    attachments: action.data[0].attachments
                                                }
                                            };
                                        }
                                        return message;
                                    })
                                };
                            }
                            return {
                                ...chat,
                                messages: [...chat.messages, {
                                    ...action.data[0],
                                    status: 'sended'
                                }]
                            };
                        }
                        return chat;
                    }
                    return chat;
                })
            };
        }
        case ChatActionsList.RESPONSE_GET_CHAT_HISTORY: {
            return {
                ...state,
                data: state.data && state.data.map((chat) => {
                    if (chat.id === action.chatID) {
                        return {
                            ...chat,
                            messages: chat.messages && [...action.data.reverse(), ...chat.messages]
                        };
                    }
                    return chat;
                })
            };
        }
        case ChatActionsList.REQUEST_GET_PERIOD_CHAT: {
            return {
                ...state,
                period: action.period
            };
        }
        case ChatActionsList.RESPONSE_GET_PERIOD_CHAT: {
            return {
                ...state,
                data: state.data && state.data.map((chat) => {
                    if (chat.id === action.chatID) {
                        return {
                            ...chat,
                            messages: chat.messages && [...action.data.reverse()]
                        };
                    }
                    return chat;
                })
            };
        }
        case ChatActionsList.RESPONSE_RESET_PERIOD_CHAT: {
            return {
                ...state,
                period: null,
                data: state.data && state.data.map((chat) => {
                    if (chat.id === action.chatID) {
                        return {
                            ...chat,
                            messages: chat.messages && [...action.data.reverse()]
                        };
                    }
                    return chat;
                })
            };
        }
        case ChatActionsList.REQUEST_ADD_ENGINEER_CHAT: {
            return {
                ...state,
                loading: true
            };
        }
        case ChatActionsList.RESPONSE_ADD_ENGINEER_CHAT: {
            return {
                ...state,
                loading: false,
                addingEngineer: false,
                data: state.data && state.data.map((chat) => {
                    if (chat.id === action.chatID) {
                        return {
                            ...chat,
                            members: [...chat.members, action.engineerID]
                        };
                    }
                    return chat;
                })
            };
        }
        case ChatActionsList.ADDING_ENGINEER_CHAT: {
            return {
                ...state,
                addingEngineer: !state.addingEngineer
            };
        }
        case ChatActionsList.RESPONSE_GET_CALENDAR_HISTORY_CHAT: {
            return {
                ...state,
                monthsHistory: [...state.monthsHistory, action.data]
            };
        }
        case ChatActionsList.RESPONSE_SIGNOUT_AUTH: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default companiesReducer;
