import {
    DatesActionsList,
    DatesActionsType,
    DatesItem,
    DatesItemData,
    DatesStateType
} from '../types/dates';

export const initialState: DatesStateType = {
    loading: false,
    data: null,
    companies: null,
    users: null,
    editing: null,
    error: null
};

const companiesReducer = (state: DatesStateType = initialState, action: DatesActionsType) : DatesStateType => {
    switch (action.type) {
        case DatesActionsList.ERROR_DATES: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case DatesActionsList.SET_ADDITIONAL_DATA_DATES: {
            return {
                ...state,
                companies: action.companies,
                users: action.users
            };
        }
        case DatesActionsList.REQUEST_GET_DATES:
        case DatesActionsList.REQUEST_ADD_DATES:
        case DatesActionsList.REQUEST_DELETE_DATES: {
            return {
                ...state,
                loading: true
            };
        }
        case DatesActionsList.REQUEST_UPDATE_DATES: {
            return {
                ...state,
                loading: true,
                editing: action.dateID
            };
        }
        case DatesActionsList.RESPONSE_GET_DATES: {
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        case DatesActionsList.RESPONSE_ADD_DATES:
        case DatesActionsList.RESPONSE_DELETE_DATES: {
            return {
                ...state,
                loading: false
            };
        }
        case DatesActionsList.RESPONSE_UPDATE_DATES: {
            return {
                ...state,
                loading: false,
                editing: null
            };
        }
        case DatesActionsList.ADD_DATES: {
            return {
                ...state,
                data: state.data && [...state.data, action.data]
            };
        }
        case DatesActionsList.UPDATE_DATES: {
            return {
                ...state,
                data: state.data && state.data.map((date) => {
                    if (date.id === action.data.id) {
                        return {
                            ...date,
                            ...action.data
                        };
                    }
                    return date;
                })
            };
        }
        case DatesActionsList.DELETE_DATES: {
            return {
                ...state,
                data: state.data && state.data.filter((date) => date.id !== action.dateID)
            };
        }
        case DatesActionsList.RESPONSE_SIGNOUT_AUTH: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default companiesReducer;
