import { CompaniesItemShort } from './companies';

export type NotificationsType = 'chat' | 'order' | 'offer' | 'registration';

export interface NotificationItem {
    type: NotificationsType,
    companyID: string,
    companyName: string,
    date: number
}

export interface NotificationsStateType {
    data: NotificationItem[],
    companies: CompaniesItemShort[] | null,
    loading: boolean,
    error: string | null
}

export enum NotificationsActionsList {
    GET_NOTIFY = 'GET_NOTIFY',
    ADD_NOTIFY = 'ADD_NOTIFY',
    REMOVE_NOTIFY = 'REMOVE_NOTIFY',
    ERROR_NOTIFY = 'ERROR_NOTIFY',
    REQUEST_ADDITIONAL_DATA_NOTIFY = 'REQUEST_ADDITIONAL_DATA_NOTIFY',
    RESPONSE_ADDITIONAL_DATA_NOTIFY = 'RESPONSE_ADDITIONAL_DATA_NOTIFY'
}

export interface ErrorNotificationsActionType {
    type: NotificationsActionsList.ERROR_NOTIFY,
    error: string
}

export interface RequestAdditionalDataNotifyActionType {
    type: NotificationsActionsList.REQUEST_ADDITIONAL_DATA_NOTIFY
}

export interface ResponseAdditionalDataNotifyActionType {
    type: NotificationsActionsList.RESPONSE_ADDITIONAL_DATA_NOTIFY,
    data: CompaniesItemShort[]
}

export interface GetNotificationsActionType {
    type: NotificationsActionsList.GET_NOTIFY
}

export interface AddNotificationsActionType {
    type: NotificationsActionsList.ADD_NOTIFY,
    data: NotificationItem
}

export interface RemoveNotificationsActionType {
    type: NotificationsActionsList.REMOVE_NOTIFY
}

export type NotificationsActionsType =
ErrorNotificationsActionType
| RequestAdditionalDataNotifyActionType
| ResponseAdditionalDataNotifyActionType
| GetNotificationsActionType
| AddNotificationsActionType
| RemoveNotificationsActionType;
