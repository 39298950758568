import {
    CompaniesActionsList,
    CompaniesActionsType,
    CompaniesItem,
    CompaniesItemData,
    CompaniesStateType
} from '../types/companies';

export const initialState: CompaniesStateType = {
    loading: false,
    data: null,
    error: null
};

const companiesReducer = (state: CompaniesStateType = initialState, action: CompaniesActionsType) : CompaniesStateType => {
    switch (action.type) {
        case CompaniesActionsList.ERROR_COMPANIES: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }
        case CompaniesActionsList.REQUEST_GET_COMPANIES:
        case CompaniesActionsList.REQUEST_UPDATE_COMPANIES: {
            return {
                ...state,
                loading: true
            };
        }
        case CompaniesActionsList.RESPONSE_GET_COMPANIES: {
            return {
                ...state,
                loading: false,
                data: action.data
            };
        }
        case CompaniesActionsList.RESPONSE_UPDATE_COMPANIES: {
            return {
                ...state,
                loading: false,
                data: state.data?.map((company) => {
                    if (company.id === action.companyID) {
                        return { ...company, ...action.data };
                    }
                    return company;
                }) as CompaniesItem[]
            };
        }
        case CompaniesActionsList.RESPONSE_SIGNOUT_AUTH: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
};

export default companiesReducer;
