import { Moment } from 'moment';
import { RcFile } from 'antd/lib/upload/interface';
import { UsersItem } from './users';
import firebase from '../../utils/firebase';

export type MessageStatusType = 'sending' | 'sended' | 'read' | 'error';

export interface MessageItemData {
    text: string,
    chatID: string,
    userID: string,
    readed: boolean,
    status: MessageStatusType,
    attachments: AttachemntItem[] | RcFile[],
    hasAttachments: boolean,
    createdAt: number,
    updatedAt: number,
    originalDoc: firebase.firestore.DocumentData | null
}

export interface MessageItem extends MessageItemData {
    id: string
}

export interface ChatItem {
    id: string,
    companyID: string,
    companyName: string | null,
    members: string[],
    loading: boolean,
    messages: MessageItem[] | null,
    createdAt: number
}

export interface AttachemntItem {
    uid: string,
    name: string,
    size: number,
    url: string
}

export interface ChatStateType {
    loading: boolean,
    data: ChatItem[] | null,
    currentChat: string | null,
    addingEngineer: boolean,
    monthsHistory: MonthHistoryItem[],
    period: [number, number] | null,
    users: UsersItem[] | null,
    error: string | null
}

export enum ChatActionsList {
    REQUEST_GET_CHATS = 'REQUEST_GET_CHATS',
    RESPONSE_GET_CHATS = 'RESPONSE_GET_CHATS',
    CHANGE_CHAT = 'CHANGE_CHAT',
    REQUEST_GET_CHAT_HISTORY = 'REQUEST_GET_CHAT_HISTORY',
    RESPONSE_GET_CHAT_HISTORY = 'RESPONSE_GET_CHAT_HISTORY',
    REQUEST_GET_PERIOD_CHAT = 'REQUEST_GET_PERIOD_CHAT',
    RESPONSE_GET_PERIOD_CHAT = 'RESPONSE_GET_PERIOD_CHAT',
    REQUEST_RESET_PERIOD_CHAT = 'REQUEST_RESET_PERIOD_CHAT',
    RESPONSE_RESET_PERIOD_CHAT = 'RESPONSE_RESET_PERIOD_CHAT',
    REQUEST_SEND_MESSAGE_CHAT = 'REQUEST_SEND_MESSAGE_CHAT',
    RESPONSE_SEND_MESSAGE_CHAT = 'RESPONSE_SEND_MESSAGE_CHAT',
    REQUEST_ADD_ENGINEER_CHAT = 'REQUEST_ADD_ENGINEER_CHAT',
    RESPONSE_ADD_ENGINEER_CHAT = 'RESPONSE_ADD_ENGINEER_CHAT',
    ADD_MESSAGES_CHAT = 'ADD_MESSAGES_CHAT',
    ADDING_ENGINEER_CHAT = 'ADDING_ENGINEER_CHAT',
    REQUEST_GET_CALENDAR_HISTORY_CHAT = 'REQUEST_GET_CALENDAR_HISTORY_CHAT',
    RESPONSE_GET_CALENDAR_HISTORY_CHAT = 'RESPONSE_GET_CALENDAR_HISTORY_CHAT',
    ERROR_CHAT = 'ERROR_CHAT',
    RESPONSE_SIGNOUT_AUTH = 'RESPONSE_SIGNOUT_AUTH'
}

export interface ErrorChatActionType {
    type: ChatActionsList.ERROR_CHAT,
    error: string
}

export interface RequestGetChatsActionType {
    type: ChatActionsList.REQUEST_GET_CHATS,
    userID: string
}

export interface ResponseGetChatsActionType {
    type: ChatActionsList.RESPONSE_GET_CHATS,
    data: ChatItem[],
    users: UsersItem[]
}

export interface ChangeChatActionType {
    type: ChatActionsList.CHANGE_CHAT,
    chatID: string
}

export interface RequestSendMessageChatActionType {
    type: ChatActionsList.REQUEST_SEND_MESSAGE_CHAT,
    data: MessageItem
}

export interface ResponseSendMessageChatActionType {
    type: ChatActionsList.RESPONSE_SEND_MESSAGE_CHAT,
    data: MessageItem
}

export interface AddMessagesChatActionType {
    type: ChatActionsList.ADD_MESSAGES_CHAT,
    chatID: string,
    data: MessageItem[]
}

export interface ResponseSignOutActionType {
    type: ChatActionsList.RESPONSE_SIGNOUT_AUTH
}

export interface RequestGetChatHistoryActionType {
    type: ChatActionsList.REQUEST_GET_CHAT_HISTORY,
    chatID: string,
    lastDoc: firebase.firestore.DocumentData
}

export interface ResponseGetChatHistoryActionType {
    type: ChatActionsList.RESPONSE_GET_CHAT_HISTORY,
    chatID: string,
    data: MessageItem[]
}

export interface RequestGetPeriodChatActionType {
    type: ChatActionsList.REQUEST_GET_PERIOD_CHAT,
    chatID: string,
    period: [number, number]
}

export interface ResponseGetPeriodChatActionType {
    type: ChatActionsList.RESPONSE_GET_PERIOD_CHAT,
    chatID: string,
    data: MessageItem[]
}

export interface RequestResetPeriodChatActionType {
    type: ChatActionsList.REQUEST_RESET_PERIOD_CHAT,
    chatID: string
}

export interface ResponseResetPeriodChatActionType {
    type: ChatActionsList.RESPONSE_RESET_PERIOD_CHAT,
    chatID: string,
    data: MessageItem[]
}

export interface RequestAddEngineerChatActionType {
    type: ChatActionsList.REQUEST_ADD_ENGINEER_CHAT,
    chatID: string,
    engineerID: string
}

export interface ResponseAddEngineerChatActionType {
    type: ChatActionsList.RESPONSE_ADD_ENGINEER_CHAT,
    chatID: string,
    engineerID: string
}

export interface AddingEngineerChatActionType {
    type: ChatActionsList.ADDING_ENGINEER_CHAT
}

export interface MonthMessagesItem {
    date: Moment,
    hasMessages: boolean
}

export interface MonthHistoryItem {
    month: string,
    data: MonthMessagesItem[]
}

export interface RequestGetHistoryCalendarChatActionType {
    type: ChatActionsList.REQUEST_GET_CALENDAR_HISTORY_CHAT,
    chatID: string,
    date: Moment
}

export interface ResponseGetHistoryCalendarChatActionType {
    type: ChatActionsList.RESPONSE_GET_CALENDAR_HISTORY_CHAT,
    data: MonthHistoryItem
}

export type ChatActionsType =
ErrorChatActionType
| RequestGetChatsActionType
| ResponseGetChatsActionType
| ChangeChatActionType
| RequestGetChatHistoryActionType
| ResponseGetChatHistoryActionType
| RequestGetPeriodChatActionType
| ResponseGetPeriodChatActionType
| RequestResetPeriodChatActionType
| ResponseResetPeriodChatActionType
| RequestSendMessageChatActionType
| ResponseSendMessageChatActionType
| RequestAddEngineerChatActionType
| ResponseAddEngineerChatActionType
| AddMessagesChatActionType
| AddingEngineerChatActionType
| RequestGetHistoryCalendarChatActionType
| ResponseGetHistoryCalendarChatActionType
| ResponseSignOutActionType;
